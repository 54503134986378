import { Observable } from 'rxjs';
import { CalendarSelection, SwitchLabelPosition } from '@infragistics/igniteui-angular';

import { CalendarDisableMode } from '../../../calendar-picker';
import { SwitchSize } from '../../../switch';

export interface FilterConfig {
  readonly search?: SearchConfig;
  readonly dropdowns?: SelectFilterConfig[];
  readonly switches?: SwitchConfig[];
}

export interface BaseFilterChange {
  readonly search?: string;
}

export type FilterChange<T = unknown> = BaseFilterChange & {
  [Property in keyof T]: T[Property];
};

export interface SearchConfig {
  readonly placeholder?: string;
  readonly value?: string;
  readonly width?: string;
  readonly clearable?: boolean;
  readonly isLoading?: boolean;
}

export interface SwitchConfig {
  readonly name: string;
  readonly value?: boolean;
  readonly label?: string;
  readonly labelPosition?: SwitchLabelPosition;
  readonly size?: SwitchSize;
  readonly clearable?: boolean;
}

export interface ComboFilterConfig {
  readonly multiple?: boolean;
  readonly valueKey?: string;
  readonly titleKey?: string;
  readonly displayKey?: string;
  readonly subtitleKey?: string;
  readonly photoKey?: string;
  readonly pipeKey?: string;
  readonly filterable?: boolean;
  readonly showAvatar?: boolean;
  readonly localSearch?: boolean;
  readonly itemHeight?: number;
  readonly returnStrategy?: 'key' | 'object';
  readonly multipleSelectionStrategy?: 'node' | 'children';
  readonly displayStrategy?: 'value' | 'path';
  readonly clearable?: boolean;
}

export interface SelectFilterConfig<T = unknown> extends ComboFilterConfig {
  readonly selectType: SelectType;
  readonly name: string;
  readonly placeholder: string;
  readonly value?: T;
  readonly options?: T[] | Observable<T[]>;
  readonly specialDates?: Date[];
  readonly hideOutsideDays?: boolean;
  readonly calendarDisableMode?: CalendarDisableMode;
  readonly calendarSelectionType?: CalendarSelection;
  readonly calendarViewDate?: Date;
  readonly disabled?: boolean;
  readonly onlyEmitOnConfirmation?: boolean;
  readonly width?: string;
  readonly displayPipe?: 'titlecase' | 'uppercase';
  readonly canSelectSingleDate?: boolean;
  readonly displayMonthsCount?: number;
  readonly hide?: boolean;
}

export interface SelectChange<T = string> {
  name: string;
  value: T;
}

export enum SelectType {
  Combo = 'combo',
  Select = 'select',
  DatePicker = 'datePicker',
  DateRange = 'dateRange',
  CalendarRange = 'calendarRange',
  DropdownTree = 'dropdownTree',
}
