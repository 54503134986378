import { BehaviorSubject, Observable, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { BaseHttpService } from '@supy/common';
import { APP_CONFIG } from '@supy/core';

import { AuthConfig } from '../../config';
import { AuthUser, LoginRequest, RefreshTokenRequest, RefreshTokenResponse, SendOtpRequest } from '../../core';
import { AuthStorageService } from './auth-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseHttpService {
  readonly #user$ = new BehaviorSubject<AuthUser | null>(null);

  constructor(
    protected readonly httpClient: HttpClient,
    protected readonly authStorage: AuthStorageService,
    @Inject(APP_CONFIG) protected readonly config: AuthConfig,
  ) {
    super(`${config.apiUrlBff}${config.apiUrlPrefix}/auth`);

    this.restoreUserData();
  }

  get user$(): Observable<AuthUser | null> {
    return this.#user$.asObservable();
  }

  get user(): AuthUser | null {
    return this.#user$.getValue();
  }

  get authUser(): AuthUser | null {
    return this.authStorage.getAuthUser();
  }

  logIn(body: LoginRequest): Observable<AuthUser> {
    return this.post<LoginRequest, AuthUser>(body, 'login').pipe(tap(data => this.setUserData(data)));
  }

  sendOtp(body: SendOtpRequest): Observable<void> {
    return this.post<SendOtpRequest, void>(body, 'otp');
  }

  logOut(): void {
    this.resetUserData();
  }

  refreshToken(refreshToken: string): Observable<RefreshTokenResponse> {
    return this.post<RefreshTokenRequest, RefreshTokenResponse>({ refreshToken }, 'refresh-token').pipe(
      tap(data => {
        const user = this.#user$.getValue();

        this.setUserData({
          ...user,
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
        } as AuthUser);
      }),
    );
  }

  setUserData(data: AuthUser | null): void {
    const user = data ? new AuthUser(data) : null;

    if (user) {
      this.authStorage.setAuthUser(user);
    } else {
      this.authStorage.removeAuthUser();
    }

    this.#user$.next(user);
  }

  private restoreUserData(): void {
    const data = this.authStorage.getAuthUser();
    const user = data ? new AuthUser(data) : null;

    this.#user$.next(user);
  }

  private resetUserData(): void {
    this.authStorage.clear();
    this.#user$.next(null);
  }
}
