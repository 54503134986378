import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from '../button';
import { CheckboxModule } from '../checkbox';
import { EmptyComponent } from '../empty';
import { IconModule } from '../icon';
import { InputModule } from '../input';
import { TextareaModule } from '../textarea';
import { DropdownTreeComponent } from './components';

@NgModule({
  declarations: [DropdownTreeComponent],
  imports: [CommonModule, InputModule, TextareaModule, IconModule, ButtonModule, CheckboxModule, EmptyComponent],
  exports: [DropdownTreeComponent],
})
export class DropdownTreeModule {}
