import { CommonConfig } from '@supy/common';

export enum MixpanelPortal {
  Admin = 'Admin Portal',
  Retailer = 'Retailer Portal',
  Supplier = 'Supplier Portal',
  OrderEmail = 'Order Email',
}

export interface MixpanelConfig extends CommonConfig {
  mixpanel: {
    token: string;
    portal: MixpanelPortal;
    debug?: boolean;
  };
}
