<supy-button
  *ngIf="calculatedInitialValue; else placeHolder"
  (buttonClick)="openToggle.emit()"
  [name]="name"
  color="secondary"
  class="supy-filter-tile__select"
  [class.supy-filter-tile--no-padding]="noPaddings"
  [class.supy-filter-tile--transparent]="transparent"
  [class.supy-filter-tile--open]="isOpen"
  [disabled]="disabled"
  variant="flat"
  width="full"
  height="full"
>
  <div class="supy-filter-tile__content">
    <span #textElement class="supy-filter-tile__text"><ng-content> </ng-content></span>
    <div class="supy-filter-tile__icons">
      <supy-icon name="arrow-circle-down" [color]="disabled ? 'gray' : 'primary'"></supy-icon>
      <supy-icon *ngIf="clearable && !disabled" (click)="clear.emit()" name="close-circle" color="error"></supy-icon>
    </div>
  </div>
</supy-button>
<ng-template #placeHolder>
  <supy-button
    (buttonClick)="openToggle.emit()"
    [name]="name"
    color="tertiary"
    class="supy-filter-tile__select"
    [class.supy-filter-tile--no-padding]="noPaddings"
    [class.supy-filter-tile--transparent]="transparent"
    [class.supy-filter-tile--open]="isOpen"
    [disabled]="disabled"
    variant="flat"
    width="full"
    height="full"
  >
    <span #textElement>{{ placeholder }}</span>
    <supy-icon name="arrow-circle-down" color="primary"></supy-icon>
  </supy-button>
</ng-template>
