import * as mixpanel from 'mixpanel-browser';
import { Dict, RequestOptions } from 'mixpanel-browser';
import { filter, map, takeUntil } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, NavigationError, Router, RoutesRecognized } from '@angular/router';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';

import { AuthService, LoginError, LoginSuccess, LoginType, Logout } from '@supy/auth';
import { Destroyable, UserWithBranches } from '@supy/common';
import { APP_CONFIG } from '@supy/core';
import { CurrentUserState, GetCurrentUserSuccess } from '@supy/users';

import { MixpanelConfig, MixpanelPortal } from '../config';
import {
  AddProduct,
  ApplyOrderLogFilter,
  ChannelVisit,
  ConfirmOrder,
  CreateDraftOrder,
  CreatePreferredChannel,
  DiscardDraftOrder,
  EditProduct,
  ExpandOrder,
  Login,
  MatchmakingEvent,
  MixpanelEvent,
  PageVisit,
  PageVisitStart,
  ReceiveOrder,
  RejectOrder,
  RequestNewSupplier,
  SendMessage,
  SubmitOrder,
  SupplierItemSearch,
  TrackAddProduct,
  TrackApplyOrderLogFilter,
  TrackChannelVisit,
  TrackConfirmOrder,
  TrackCreateDraftOrder,
  TrackCreatePreferredChannel,
  TrackDiscardDraftOrder,
  TrackEditProduct,
  TrackExpandOrder,
  TrackMatchmaking,
  TrackMessageSend,
  TrackReceiveOrder,
  TrackRejectOrder,
  TrackRequestNewSupplier,
  TrackSubmitOrder,
  TrackSupplierItemSearch,
  TrackUserCreation,
  UserCreation,
} from '../core';
import { getPageName } from '../helpers';

@Injectable({ providedIn: 'root' })
export class MixpanelService extends Destroyable {
  #inited: boolean;

  private previousPageName: string;

  constructor(
    @Inject(APP_CONFIG) protected readonly config: MixpanelConfig,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly store: Store,
    private readonly actions: Actions,
  ) {
    super();
    this.actions
      .pipe(ofActionDispatched(GetCurrentUserSuccess), takeUntil(this.destroyed$))
      .subscribe(() => this.updateUser());

    this.actions
      .pipe(ofActionDispatched(LoginSuccess), takeUntil(this.destroyed$))
      .subscribe(({ loginType }) => this.trackLogin(loginType));

    this.actions
      .pipe(ofActionDispatched(LoginError), takeUntil(this.destroyed$))
      .subscribe(({ loginType }) => this.trackLogin(loginType, false));

    this.actions.pipe(ofActionDispatched(Logout), takeUntil(this.destroyed$)).subscribe(() => this.logout());
  }

  init() {
    if (this.#inited) {
      return;
    }

    const {
      mixpanel: { token, debug, portal },
    } = this.config;

    if (token) {
      mixpanel.init(token, { debug, ignore_dnt: true });

      this.#inited = true;

      this.definePortal(portal);
    }
  }

  trackRouterEvents() {
    // TODO refactor after upgrade to Angular v14: https://app.shortcut.com/supyio/story/2329
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => getPageName(this.router)),
      )
      .subscribe((pageName: string) => {
        if (this.previousPageName === pageName) {
          // avoid event duplications
          return;
        }

        this.previousPageName = pageName;

        if (pageName) {
          this.trackPageVisitStart(pageName);
          this.pageVisitStartTimer();
        }
      });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationError),
        map(() => getPageName(this.router)),
      )
      .subscribe((pageName: string) => {
        if (pageName) {
          this.trackPageVisitStart(pageName, false);
          this.trackPageVisit(pageName, false);
        }
      });

    this.router.events
      .pipe(
        filter(event => event instanceof RoutesRecognized),
        map((event: RoutesRecognized) => getPageName(this.router, event.state.root)),
      )
      .subscribe(pageName => {
        if (this.previousPageName === pageName) {
          // avoid event duplications
          return;
        }

        if (this.previousPageName) {
          this.trackPageVisit(this.previousPageName);
          this.previousPageName = null;
        }
      });
  }

  pageVisitStartTimer() {
    mixpanel.time_event(PageVisit.eventName);
  }

  trackPageVisitStart(pageName: string, success = true) {
    const user = this.store.selectSnapshot(CurrentUserState.getCurrentUser);

    const pageVisit: PageVisitStart = {
      restaurantName: user?.retailers?.map(({ name }) => name) ?? [],
      pageName,
      success,
    };

    this.trackEvent(new PageVisitStart(pageVisit));
  }

  trackPageVisit(pageName: string, success = true) {
    const user = this.store.selectSnapshot(CurrentUserState.getCurrentUser);

    const pageVisit: PageVisit = {
      restaurantName: user?.retailers?.map(({ name }) => name) ?? [],
      pageName,
      success,
    };

    this.trackEvent(new PageVisit(pageVisit));
  }

  trackMessageSend(messageInfo: TrackMessageSend) {
    return this.trackEvent(new SendMessage(messageInfo));
  }

  trackChannelVisit(channelVisitInfo: TrackChannelVisit) {
    return this.trackEvent(new ChannelVisit(channelVisitInfo));
  }

  trackUserCreation(user: TrackUserCreation) {
    return this.trackEvent(new UserCreation(user));
  }

  trackAddProduct(product: TrackAddProduct) {
    const {
      mixpanel: { portal },
    } = this.config;
    let selfCompletion = true;

    if (portal === 'Admin Portal') {
      selfCompletion = false;
    }

    return this.trackEvent(new AddProduct({ ...product, selfCompletion }));
  }

  trackEditProduct(product: TrackEditProduct) {
    return this.trackEvent(new EditProduct(product));
  }

  trackCreateDraftOrder(order: TrackCreateDraftOrder) {
    return this.trackEvent(new CreateDraftOrder(order));
  }

  trackSubmitOrder(order: TrackSubmitOrder) {
    return this.trackEvent(new SubmitOrder(order));
  }

  trackConfirmOrder(order: TrackConfirmOrder) {
    return this.trackEvent(new ConfirmOrder(order), { send_immediately: true });
  }

  trackReceiveOrder(order: TrackReceiveOrder) {
    return this.trackEvent(new ReceiveOrder(order));
  }

  trackRejectOrder(order: TrackRejectOrder) {
    return this.trackEvent(new RejectOrder(order), { send_immediately: true });
  }

  trackDiscardDraftOrder(order: TrackDiscardDraftOrder) {
    return this.trackEvent(new DiscardDraftOrder(order));
  }

  trackApplyOrderLogFilter(event: TrackApplyOrderLogFilter) {
    return this.trackEvent(new ApplyOrderLogFilter(event));
  }

  trackLogin(loginMethod: LoginType, success = true) {
    this.updateUser();
    this.trackEvent(new Login({ loginMethod, success }));
  }

  trackCreatePreferredChannel(event: TrackCreatePreferredChannel) {
    this.trackEvent(new CreatePreferredChannel(event));
  }

  trackRequestNewSupplier(event: TrackRequestNewSupplier) {
    this.trackEvent(new RequestNewSupplier(event));
  }

  trackExpandOrder(event: TrackExpandOrder) {
    this.trackEvent(new ExpandOrder(event));
  }

  trackSupplierItemSearch(event: TrackSupplierItemSearch) {
    this.trackEvent(new SupplierItemSearch(event));
  }

  trackMatchmaking(event: TrackMatchmaking) {
    this.trackEvent(new MatchmakingEvent(event));
  }

  trackEvent(event: MixpanelEvent, options?: RequestOptions) {
    const { eventName, ...rest } = event;

    this.track(eventName, rest, options);
  }

  track(eventName: string, properties: Dict = {}, options?: RequestOptions): MixpanelService {
    if (!this.#inited) {
      return;
    }

    mixpanel.track(eventName, properties, options);
  }

  definePortal(portal: MixpanelPortal): MixpanelService {
    if (!this.#inited) {
      return;
    }

    mixpanel.register({
      portal,
    });
  }

  setUserId(userId: string): MixpanelService {
    if (!this.#inited) {
      return;
    }

    mixpanel.identify(userId);
  }

  setUserInfo(userInfo: Dict): MixpanelService {
    if (!this.#inited) {
      return this;
    }

    // mixpanel.people.set(userInfo);
  }

  logout(): void {
    if (!this.#inited) {
      return;
    }

    mixpanel.reset();
    // we define portal again as `mixpanel.reset()` erases all data;
    this.definePortal(this.config.mixpanel.portal);
  }

  private updateUser() {
    if (!this.#inited) {
      return;
    }

    const user: UserWithBranches = this.store.selectSnapshot(CurrentUserState.getCurrentUser);

    const userName = user && `${user.firstName} ${user.lastName}`;

    if (user) {
      this.setUserId(user.id);

      const userData: Dict = {
        $firstname: user.firstName,
        $lastname: user.lastName,
        $name: userName,
        $phonenumber: user.phone,
        $email: user.email,
        photo: Boolean(user.photoUrl),
      };

      this.setUserInfo(userData);
    }
  }
}
