import { takeUntil, timer } from 'rxjs';
import { transition, trigger, useAnimation } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, ValidatorFn } from '@angular/forms';
import {
  AutoPositionStrategy,
  fadeIn,
  fadeOut,
  HorizontalAlignment,
  IgxToggleDirective,
  OverlaySettings,
  swingInTopBck,
  swingOutTopBck,
  VerticalAlignment,
} from '@infragistics/igniteui-angular';

import { Destroyable } from '@supy/common';

import { ButtonColor, ButtonComponent } from '../../../button';
import { IconColor, IconSize, IconType } from '../../../icon';
import { InputComponent } from '../../../input';

@Component({
  selector: 'supy-editable-options',
  templateUrl: './editable-options.component.html',
  styleUrls: ['./editable-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
      transition('void => *', [
        useAnimation(fadeIn, {
          params: {
            duration: '.35s',
            easing: 'ease-out',
          },
        }),
      ]),
      transition('* => void', [
        useAnimation(fadeOut, {
          params: {
            duration: '.35s',
            easing: 'ease-in',
          },
        }),
      ]),
    ]),
  ],
})
export class EditableOptionsComponent extends Destroyable {
  @ViewChild(IgxToggleDirective, { static: true }) private readonly igxToggle: IgxToggleDirective;
  @ViewChild('toggler', { static: true }) private readonly toggler: ButtonComponent;
  @ViewChild(InputComponent, { static: false }) private readonly input: InputComponent<string>;

  @Input() protected readonly buttonColor: ButtonColor = 'default-outline';
  @Input() protected readonly buttonSize: 'small' | 'medium' | 'large' = 'medium';
  @Input() protected readonly disabled: boolean;
  @Input() protected readonly emptyLabel: string = 'Click to Add';
  @Input() protected readonly emptyTitle: string = 'No options';
  @Input() protected readonly iconColor: IconColor;
  @Input() protected readonly iconSize: IconSize = 'small';
  @Input() protected readonly iconSuffix: IconType;
  @Input() protected readonly inputSuffix: IconType = 'plus-flat';
  @Input() protected readonly label: string = 'View All';
  @Input() protected readonly options: string[] = [];
  @Input() protected readonly placeholder: string = 'Add option';
  @Input() protected readonly showCount: boolean = true;
  @Input() protected set validators(validators: ValidatorFn[]) {
    this.inputValue.setValidators(validators);
  }

  @Output() protected readonly add = new EventEmitter<string>();
  @Output() protected readonly clicked = new EventEmitter<void>();
  @Output() protected readonly remove = new EventEmitter<string>();

  protected readonly inputValue = new FormControl<string | null>(null);

  private readonly positionSettings = {
    horizontalStartPoint: HorizontalAlignment.Left,
    verticalStartPoint: VerticalAlignment.Bottom,
  };

  private readonly overlaySettings: OverlaySettings = {
    closeOnEscape: true,
    closeOnOutsideClick: true,
    modal: true,
    positionStrategy: new AutoPositionStrategy({
      ...this.positionSettings,
      openAnimation: useAnimation(swingInTopBck, { params: { duration: '500ms' } }),
      closeAnimation: useAnimation(swingOutTopBck, { params: { duration: '250ms' } }),
    }),
  };

  toggle(): void {
    this.overlaySettings.target = this.toggler.buttonElement.nativeElement;

    if (this.igxToggle.collapsed) {
      this.igxToggle.open(this.overlaySettings);
      timer(0)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.input.focus();
        });
    } else {
      this.igxToggle.close();
    }
  }

  protected addOption(): void {
    const { valid, value } = this.inputValue;
    const trimmedValue = value?.trim();

    if (valid && trimmedValue && trimmedValue.length > 0 && !this.options.includes(trimmedValue)) {
      this.add.emit(trimmedValue);
      this.inputValue.reset();
    } else {
      this.inputValue.setErrors({ invalid: true });
    }
  }
}
