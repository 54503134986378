export function getTextWidth(): (text: string, font: string) => number {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (!context) {
    throw new Error('Canvas 2d context is not supported.');
  }

  return function (text: string, font: string): number {
    context.font = font;

    const metrics = context.measureText(text);

    return metrics.width;
  };
}

export function getDeepestElementWithText(rootElement: HTMLElement | null): HTMLElement | null {
  let deepestElement: HTMLElement | null = null;

  function traverse(element: Node | null) {
    if (!element) return null;

    if (element.nodeType === Node.TEXT_NODE && element.textContent?.trim() !== '') {
      deepestElement = (element.parentNode as HTMLElement) || null;

      return null;
    }

    for (const child of Array.from(element.childNodes)) {
      traverse(child);
    }
  }

  traverse(rootElement);

  return deepestElement;
}

export function transformToTitleCaseWithAcronyms(input: string, acronymsToIgnore: string[] = []) {
  const acronymPattern = acronymsToIgnore.join('|');
  const pattern = new RegExp(`(${acronymPattern}|[A-Z]?[a-z]+)|[A-Z]+|[0-9]+`, 'g');

  const words = input.match(pattern) || [];

  const titleCaseWords = words.map((word: string) => {
    const isAcronym = acronymsToIgnore?.includes(word);

    return isAcronym ? word : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  return titleCaseWords.join(' ');
}
