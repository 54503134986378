<igx-date-range-picker
  class="supy-date-range {{ variant }}"
  [class.selected]="!!datePicker.value"
  type="border"
  #datePicker
  (closing)="onClosingPicker($event)"
  [value]="value"
  (valueChange)="onValueChange($event)"
  [inputFormat]="inputFormat"
  [displayFormat]="displayFormat"
  [placeholder]="placeholder"
  [displayDensity]="displayDensity"
  [overlaySettings]="overlaySettings"
  [attr.data-name]="name"
  [hideOutsideDays]="hideOutsideDays"
  [disabled]="disabled"
  [displayMonthsCount]="displayMonthsCount"
>
  <ng-template igxPickerActions>
    <div class="supy-date-range__actions" *ngIf="predefinedRanges?.length">
      <ng-container *ngFor="let predefinedRange of predefinedRanges">
        <supy-button color="default" (buttonClick)="onSelectRange(predefinedRange)">
          <ng-container [ngSwitch]="predefinedRange.by" *ngIf="!predefinedRange.customLabel; else customLabel">
            <ng-container *ngSwitchCase="PredefinedRangeBy.Days"> Last {{ predefinedRange.range }} days </ng-container>
            <ng-container *ngSwitchCase="PredefinedRangeBy.Months">
              Last {{ predefinedRange.range > 1 ? predefinedRange.range : '' }} Month{{
                predefinedRange.range > 1 ? 's' : ''
              }}
            </ng-container>
            <ng-container *ngSwitchCase="PredefinedRangeBy.Weeks">
              Last {{ predefinedRange.range > 1 ? predefinedRange.range : '' }} Week{{
                predefinedRange.range > 1 ? 's' : ''
              }}
            </ng-container>
            <ng-container *ngSwitchCase="PredefinedRangeBy.Quarter">
              Last {{ predefinedRange.range > 1 ? predefinedRange.range : '' }} Quarter{{
                predefinedRange.range > 1 ? 's' : ''
              }}
            </ng-container>
            <ng-container *ngSwitchCase="PredefinedRangeBy.Years">
              Last {{ predefinedRange.range > 1 ? predefinedRange.range : '' }} Year{{
                predefinedRange.range > 1 ? 's' : ''
              }}
            </ng-container>
          </ng-container>

          <ng-template #customLabel> {{ predefinedRange.customLabel }} </ng-template>
        </supy-button>
      </ng-container>
      <supy-button (buttonClick)="onDone()" class="supy-date-range__actions-done" color="primary">Done</supy-button>
    </div>
  </ng-template>

  <label igxLabel>{{ label }}</label>
  <igx-picker-toggle igxSuffix>
    <supy-icon name="arrow-circle-down" size="small"></supy-icon>
  </igx-picker-toggle>
</igx-date-range-picker>
