export enum ToggleFeature {
  CentralKitchen = 'retailer.central-kitchen',
  CentralKitchenB2B = 'retailer.central-kitchen-b2b',
  AdminCmsAccess = 'admin.cms',
  Inventory = 'retailer.inventory',
  Integration = 'retailer.integration',
  IntegratedItemManagement = 'retailer.integrated-item-management',
  OrderReceiving = 'retailer.order-receiving',
  ReferralProgram = 'admin.referral-program',
  AccountingIntegration = 'retailer.accounting-integration',
  AutoGRN = 'retailer.auto-grn',
  Localize = 'retailer.localize',
  SalesType = 'retailer.sales-type',
  RecipeHistory = 'retailer.recipe-history',
}
