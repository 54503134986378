import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Meter } from '@opentelemetry/api-metrics';
import { OTLPMetricExporter } from '@opentelemetry/exporter-metrics-otlp-http/build/esnext';
import { Resource } from '@opentelemetry/resources';
import { ConsoleMetricExporter, MeterProvider, PeriodicExportingMetricReader } from '@opentelemetry/sdk-metrics-base';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';

import { DEFAULT_METER, SDK_OPTIONS } from '../common';
import { OpenTelemetryModuleOptions } from '../config';
import { IMetricService } from './base';

@Injectable()
export class MetricService implements IMetricService, OnDestroy {
  private readonly meterProvider: MeterProvider;

  constructor(@Inject(SDK_OPTIONS) options: OpenTelemetryModuleOptions) {
    const meterProvider = new MeterProvider({
      resource: new Resource({
        [SemanticResourceAttributes.SERVICE_NAME]: options.application.name,
        [SemanticResourceAttributes.SERVICE_VERSION]: options.application.version,
      }),
    });

    if (options.metrics?.debug) {
      const consoleExporter = new ConsoleMetricExporter();

      meterProvider.addMetricReader(
        new PeriodicExportingMetricReader({
          exporter: consoleExporter,
        }),
      );
    }

    const otlpExporter = new OTLPMetricExporter({
      url: options.metrics?.url,
    });

    meterProvider.addMetricReader(
      new PeriodicExportingMetricReader({
        exporter: otlpExporter,
      }),
    );

    this.meterProvider = meterProvider;
  }

  getMeter(name: string = DEFAULT_METER): Meter {
    return this.meterProvider.getMeter(name);
  }

  getMeterProvider(): MeterProvider {
    return this.meterProvider;
  }

  async ngOnDestroy(): Promise<void> {
    await this.meterProvider.forceFlush();
    await this.meterProvider.shutdown();
  }
}
