import { Observable, takeUntil } from 'rxjs';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { IgxDropDownComponent, ISelectionEventArgs } from '@infragistics/igniteui-angular';
import { IBaseEventArgs } from '@infragistics/igniteui-angular/lib/core/utils';

import { Destroyable, getDeepestElementWithText, getTextWidth } from '@supy/common';

import { IconType } from '../../../icon';
import { InputComponent } from '../../../input';

const DEFAULT_DEBOUNCE_TIME = 500;

@Component({
  selector: 'supy-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteComponent<T extends { id: string }>
  extends Destroyable
  implements OnInit, ControlValueAccessor
{
  @ViewChild(InputComponent, { static: true }) private readonly inputEl: InputComponent<string>;
  @ViewChild(IgxDropDownComponent, { static: true }) private readonly dropdownEl: IgxDropDownComponent;

  @Input() readonly id?: string;
  @Input() readonly placeholder?: string = 'Type to filter...';
  @Input() set options(value: T[]) {
    this.#options = value;
  }

  get options(): T[] {
    return this.#options;
  }

  @Input() set isLoading(value: boolean) {
    this.#isLoading = value;
  }

  get isLoading() {
    return this.#isLoading;
  }

  @Input() readonly fetchDone: Observable<boolean>;

  @Input() set value(value: T) {
    this.#value = value;
  }

  get value() {
    return this.#value;
  }

  @Input() set searchValue(value: string) {
    this.#searchValue = value;
  }

  get searchValue() {
    return this.#searchValue;
  }

  @Input() readonly prefix: IconType;
  @Input() readonly suffix: IconType;
  @Input() readonly clearable: boolean;
  @Input() readonly customOptionsTemplate?: TemplateRef<unknown>;
  @Input() readonly noMatchOption?: TemplateRef<unknown>;
  @Input() readonly minlength: number = 2;
  @Input() readonly debounceTime: number = DEFAULT_DEBOUNCE_TIME;
  @Input() readonly optionsFn: (search: string) => Observable<T[]>;
  @Input() readonly validatorFn: (search: string, options: T[]) => T;
  @Input() readonly displayValueFn: (value: T) => string;
  @Input() readonly canBeOpened: boolean = true;
  @Input() readonly nonTextWidth: number = 0;
  @Input() readonly focusOnInit: boolean = true;
  @Input() readonly withValidatorFn: boolean = true;

  @Output() readonly focusOut = new EventEmitter<FocusEvent>();
  @Output() readonly valueChange = new EventEmitter<T>();
  @Output() readonly searchValueChange = new EventEmitter<string>();
  @Output() readonly closed = new EventEmitter<IBaseEventArgs>();
  @Output() readonly cleared = new EventEmitter<void>();

  #searchValue: string;
  #value: T;
  #isLoading: boolean;
  #options: T[] | null = [] as T[];
  #width: string = null;

  onChange: (value: T) => void;

  onTouched: () => void;

  touched = false;

  disabled = false;

  protected readonly getTextWidth = getTextWidth();

  get searchAllowed(): boolean {
    return this.searchValue?.length >= this.minlength;
  }

  get inputValue() {
    return this.value ? this.displayValueFn(this.value) : this.searchValue;
  }

  get width(): string {
    return this.#width;
  }

  constructor(
    private readonly cdr: ChangeDetectorRef,
    @Optional()
    @Inject(NgControl)
    private readonly control?: NgControl,
  ) {
    super();

    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngOnInit() {
    if (this.fetchDone) {
      this.fetchDone.pipe(takeUntil(this.destroyed$)).subscribe(() => {
        if (this.withValidatorFn) {
          this.onValueChange(this.validatorFn(this.searchValue, this.#options) ?? null);
        }

        this.checkDropdownWidth();
      });
    }
  }

  onSearchValueChange(value: string): void {
    this.searchValue = value;
    this.onValueChange(null);

    if (!this.searchAllowed) {
      this.#options = [];

      return;
    }

    this.searchValueChange.emit(value);
  }

  onFocusIn(e: FocusEvent) {
    this.#options = [];

    if (this.value || !this.searchAllowed) {
      return;
    }

    this.searchValueChange.emit(this.searchValue);
  }

  onFocusOut(e: FocusEvent): void {
    this.#options = [];
    this.focusOut.emit(e);
    this.markAsTouched();
  }

  onValueChange(value: T): void {
    this.writeValue(value);
    this.valueChange.emit(value);
  }

  writeValue(value: T): void {
    this.value = value;
  }

  registerOnChange(onChange: (value: T) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.touched = true;
      this.onTouched?.();
      this.emitTouchStatusChanged();
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  focus(): void {
    this.inputEl?.focus();
  }

  checkDropdownWidth(): void {
    let longestOption = '';
    const inputWidth = this.inputEl.element.nativeElement.offsetWidth;
    const textElement = getDeepestElementWithText(
      (this.dropdownEl.children.first?.element as ElementRef<HTMLElement>)?.nativeElement ?? null,
    );

    this.#options.forEach(option => {
      const currentOption = this.displayValueFn(option);

      longestOption = currentOption.length > longestOption.length ? currentOption : longestOption;
    });

    if (!longestOption.length || !textElement) {
      this.#width = null;

      return;
    }

    const textElementFont = getComputedStyle(textElement).getPropertyValue('font');
    const longestOptionWidth = Math.ceil(this.getTextWidth(longestOption, textElementFont) + this.nonTextWidth);

    this.#width = `${longestOptionWidth >= inputWidth ? longestOptionWidth : inputWidth}px`;
  }

  protected onSelectionChange(eventArgs: ISelectionEventArgs): void {
    const value = eventArgs.newSelection.value as unknown as T;

    this.onValueChange(value);
  }

  onClear(): void {
    this.onValueChange(null);
    this.searchValue = null;
    this.touched = false;
    this.cleared.emit();
  }

  private emitTouchStatusChanged(): void {
    if (!this.control) {
      return;
    }

    const statusChanges = this.control.statusChanges as EventEmitter<string>;

    statusChanges.emit('TOUCHED');
  }
}
