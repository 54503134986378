import { ChangeDetectorRef, Directive, ElementRef, Inject, Input, Optional, Self } from '@angular/core';
import { FormControlName, NgModel } from '@angular/forms';
import {
  IgxAutocompleteDirective,
  IgxDropDownComponent,
  IgxInputGroupComponent,
  OverlaySettings,
} from '@infragistics/igniteui-angular';

import { InputComponent } from '../../input';

@Directive({
  selector: '[supyAutocomplete]',
  exportAs: 'supyAutocomplete',
})
export class AutocompleteDirective extends IgxAutocompleteDirective {
  @Input('supyAutocomplete')
  get target(): IgxDropDownComponent {
    return this._target as IgxDropDownComponent;
  }

  set target(v: IgxDropDownComponent) {
    this._target = v;
  }

  /* eslint-disable-next-line @angular-eslint/no-input-rename */
  @Input('supyAutocompleteSettings')
  readonly autocompleteSettings: OverlaySettings;

  @Input('supyAutocompleteDisabled')
  readonly disabled = false;

  get nativeElement(): HTMLInputElement {
    return (this.input?.inputGroup?.element.nativeElement as HTMLInputElement) || this.elementRef.nativeElement;
  }

  constructor(
    @Self() @Optional() @Inject(NgModel) protected ngModel: NgModel,
    @Self() @Optional() @Inject(FormControlName) protected formControl: FormControlName,
    @Optional() protected group: IgxInputGroupComponent,
    @Optional() protected input: InputComponent<unknown>,
    protected elementRef: ElementRef<HTMLInputElement>,
    protected cdr: ChangeDetectorRef,
  ) {
    super(ngModel, formControl, group, elementRef, cdr);
  }
}
