import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DisplayDensity, IgxButtonType } from '@infragistics/igniteui-angular';

export type ButtonColor =
  | 'default'
  | 'default-outline'
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'success'
  | 'clear'
  | 'primary-blend'
  | 'success-blend'
  | 'light'
  | 'error'
  | 'error-secondary'
  | 'error-tertiary'
  | 'warn'
  | 'warn-secondary'
  | 'warn-tertiary';
type ButtonWidth = 'full' | 'auto';
type ButtonHeight = 'full' | 'auto';
type ButtonDensity = 'small' | 'medium' | 'large';
type ButtonType = IgxButtonType | 'link';

const densityMap: Record<ButtonDensity, DisplayDensity> = {
  small: 'compact',
  medium: 'cosy',
  large: 'comfortable',
};

@Component({
  selector: 'supy-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit {
  @ViewChild('buttonElement', { static: true }) readonly buttonElement: ElementRef<HTMLButtonElement>;
  @Input() variant: ButtonType = 'raised';
  @Input() readonly type: HTMLButtonElement['type'] | 'icon' = 'button';
  @Input() readonly color: ButtonColor = 'default';
  @Input() @HostBinding('attr.name') readonly name: string;
  @Input() readonly disabled: boolean = false;
  @Input() readonly round: boolean = false;
  @Input() readonly width: ButtonWidth = 'auto';
  @Input() readonly height: ButtonHeight = 'auto';
  @Input() set density(value: ButtonDensity) {
    this.displayDensity = densityMap[value] ?? 'compact';
  }

  @Output() readonly buttonClick = new EventEmitter<PointerEvent>();

  displayDensity: DisplayDensity = 'comfortable';

  onClick(e: PointerEvent): void {
    this.buttonClick.emit(e);
  }

  ngOnInit(): void {
    if (this.variant === 'link') {
      this.variant = 'flat';
    }
  }

  getClasses(): string[] {
    const classes: string[] = [];

    if (this.color) {
      classes.push(`supy-button--${this.color}`);
    }

    if (this.width) {
      classes.push(`supy-button--width-${this.width}`);
    }

    if (this.height) {
      classes.push(`supy-button--height-${this.height}`);
    }

    if (this.variant) {
      classes.push(`supy-button--${this.variant}`);
    }

    if (this.round) {
      classes.push(`supy-button--round`);
    }

    return classes;
  }
}
