import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SupyCommonModule } from '@supy/common';
import {
  AvatarModule,
  BreadcrumbModule,
  ButtonModule,
  DropdownModule,
  HeaderModule,
  IconModule,
  ImageModule,
  LoadingBarModule,
  SnackbarModule,
} from '@supy/components';
import { SideNavModule } from '@supy/side-nav';

import { AsideLayoutComponent, LayoutComponent, MainLayoutComponent, SecondaryLayoutComponent } from './components';

const COMPONENTS = [LayoutComponent, SecondaryLayoutComponent, AsideLayoutComponent, MainLayoutComponent];

@NgModule({
  imports: [
    CommonModule,
    SupyCommonModule,
    HeaderModule,
    LoadingBarModule,
    ImageModule,
    ButtonModule,
    IconModule,
    AvatarModule,
    DropdownModule,
    SnackbarModule,
    RouterModule,
    BreadcrumbModule,
    SideNavModule,
  ],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class LayoutsModule {}
