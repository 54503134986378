import { HttpStatusCode } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { humanizeBytes, UploaderOptions, UploadFile, UploadInput, UploadOutput } from '@angular-ex/uploader';

import { mimeInverted, MimeType, UploadFileResponse } from '@supy/common';

import { UploadedFile } from '../../core';

@Component({
  selector: 'supy-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent implements OnChanges {
  @Input() readonly fileTypes: MimeType[];
  @Input() readonly maxFileSize?: number; // max size of the file in bytes
  @Input() readonly uploadUrl?: string;
  @Input() readonly token?: string;
  @Input() readonly maxUploads?: number;
  @Input() readonly singleImage: boolean = false;
  @Input() readonly responseUrlKey: string;
  @Input() imgUrl: string;
  @Input() readonly variant: 'block' | 'inline' = 'block';
  @Input() readonly disabled: boolean;

  @Output() readonly changeFiles = new EventEmitter<UploadedFile[]>();
  @Output() readonly uploadFail = new EventEmitter<UploadedFile>();
  @Output() readonly localFileUploaded = new EventEmitter<UploadFile[]>();

  readonly uploadInput = new EventEmitter<UploadInput>();
  readonly humanizeBytes = humanizeBytes;
  readonly mimeInverted = mimeInverted;

  options: UploaderOptions;
  files: UploadFile[] = [];
  uploadedFiles: UploadedFile[] = [];
  dragOver: boolean;
  rejectionReason: string;

  readonly statusHandlers: { [status in UploadOutput['type']]: (file?: UploadFile) => void } = {
    allAddedToQueue: (file: UploadFile) => this.startUpload(),
    addedToQueue: (file: UploadFile) => this.handleAddToQueue(file),
    uploading: (file: UploadFile) => this.handleUploading(file),
    cancelled: (file: UploadFile) => this.handleRemoved(file),
    removed: (file: UploadFile) => this.handleRemoved(file),
    dragOver: (file: UploadFile) => (this.dragOver = true),
    dragOut: (file: UploadFile) => (this.dragOver = false),
    drop: (file: UploadFile) => (this.dragOver = false),
    rejected: (file: UploadFile) => this.handleRejection(file),
    done: (file: UploadFile) => this.handleDone(file),
    start: (file: UploadFile) => null,
    removedAll: (file: UploadFile) => null,
  };

  get fileExts() {
    return this.fileTypes?.length ? Object.values(this.fileTypes).map(ext => this.mimeInverted[ext]) : null;
  }

  get mimeTypes() {
    return this.fileTypes?.length ? Object.values(this.fileTypes) : null;
  }

  get sizeInMB() {
    return Math.ceil(this.maxFileSize / Math.pow(1000, 2)).toFixed(0);
  }

  get hasSize(): boolean {
    return this.maxFileSize && !Number.isNaN(this.maxFileSize);
  }

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    this.options = {
      concurrency: 1,
      maxFileSize: this.maxFileSize,
      allowedContentTypes: this.mimeTypes,
      maxUploads: this.singleImage ? 1 : this.maxUploads,
    };
  }

  isUploadSucceed(file: UploadFile) {
    return [HttpStatusCode.Ok, HttpStatusCode.Created, HttpStatusCode.NoContent].includes(file.responseStatus);
  }

  isUploadFailed(file: UploadFile) {
    return [
      HttpStatusCode.BadRequest,
      HttpStatusCode.Unauthorized,
      HttpStatusCode.Forbidden,
      HttpStatusCode.NotFound,
      HttpStatusCode.PayloadTooLarge,
      HttpStatusCode.UnsupportedMediaType,
      HttpStatusCode.InternalServerError,
      HttpStatusCode.BadGateway,
    ].includes(file.responseStatus);
  }

  onUploadOutput(output: UploadOutput): void {
    this.statusHandlers[output.type]?.(output.file);
  }

  handleAddToQueue(file: UploadFile) {
    if (typeof file !== 'undefined') {
      this.files.unshift(file);
    }
  }

  handleUploading(outputFile: UploadFile) {
    if (typeof outputFile !== 'undefined') {
      this.scrollToBottom();

      const index = this.files.findIndex(file => typeof outputFile !== 'undefined' && file.id === outputFile.id);

      this.files[index] = outputFile;
    }
  }

  handleRemoved(outputFile: UploadFile) {
    this.files = this.files.filter((file: UploadFile) => file !== outputFile);
    this.uploadedFiles = this.uploadedFiles.filter((file: UploadedFile) => file.localId !== outputFile.id);
    this.changeFiles.emit(this.uploadedFiles);
  }

  handleDone(file: UploadFile) {
    if (!this.uploadUrl) {
      this.localFileUploaded.emit(this.files);

      return;
    }

    const uploadedFile = {
      file: file.nativeFile,
      localId: file.id,
      response: file.response as UploadFileResponse,
      status: file.responseStatus,
    };

    if (this.isUploadSucceed(file)) {
      this.uploadedFiles.unshift(uploadedFile);
      this.changeFiles.emit(this.uploadedFiles);
    }

    if (this.isUploadFailed(file)) {
      this.uploadFail.emit(uploadedFile);
    }
  }

  handleRejection(file: UploadFile) {
    if (typeof file === 'undefined') {
      return;
    }

    if (!this.fileTypes.includes(file.type as MimeType)) {
      this.rejectionReason = 'Please, use only supported file(s) types';
    }

    if (file.size > this.maxFileSize) {
      this.rejectionReason = `File(s) size exceeds ${this.sizeInMB}MB. Please try again`;
    }

    setTimeout(() => {
      this.rejectionReason = null;
      this.cdr.markForCheck();
    }, 3000);
  }

  startUpload(): void {
    if (this.uploadUrl) {
      this.uploadInput.emit({
        type: 'uploadAll',
        url: this.uploadUrl,
        method: 'POST',
        headers: { Authorization: 'Bearer ' + this.token },
        fieldName: 'attachment',
      });
    } else {
      this.statusHandlers['done']();
    }
  }

  removeFile(id?: string): void {
    if (id) {
      this.uploadInput.emit({ type: 'remove', id: id });
    } else {
      this.imgUrl = null;
      this.changeFiles.emit(null);
    }
  }

  scrollToBottom() {
    const htmlElements = Array.from(document.getElementsByClassName('supy-file-upload__item'));
    const element = htmlElements[htmlElements.length - 1];

    element.scrollIntoView();
  }
}
