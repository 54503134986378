<div class="supy-button-group">
  <supy-button
    *ngFor="let button of buttons"
    [color]="button.value === currentValue ? 'primary' : 'secondary'"
    [disabled]="isDisabled() || button.disabled"
    (buttonClick)="onButtonClick(button.value)"
  >
    <supy-icon *ngIf="button.icon" [name]="button.icon" size="small"></supy-icon>
    <span class="supy-button-group__label">{{ button.label }}</span>
  </supy-button>
</div>
