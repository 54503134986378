import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  signal,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { IconType } from '../../../icon';

interface ToggleButtonProps<T> {
  readonly value: T;
  readonly label: string;
  readonly selected?: boolean;
  readonly disabled?: boolean;
  readonly icon?: IconType;
}

export class ToggleButton<T = string> {
  readonly value: T;
  readonly label: string;
  readonly selected?: boolean;
  readonly disabled?: boolean;
  readonly icon?: IconType;

  constructor(args?: ToggleButtonProps<T>) {
    this.value = args.value;
    this.label = args.label;
    this.selected = args?.selected ?? false;
    this.disabled = args?.disabled ?? false;
    this.icon = args.icon;
  }
}
@Component({
  selector: 'supy-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ButtonGroupComponent),
    },
  ],
})
export class ButtonGroupComponent<T = string> implements ControlValueAccessor {
  @Input() readonly buttons?: ToggleButton<T>[];
  @Input() set value(item: T) {
    this.writeValue(item);
  }

  protected readonly isDisabled = signal<boolean>(false);

  @Output() readonly valueChange = new EventEmitter<T>();

  currentValue: T;

  onTouched: () => void;
  onChange: (value: T) => void;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  writeValue(value: T): void {
    if (value !== this.currentValue) {
      this.currentValue = value ?? this.buttons[0].value;
      this.onChange?.(value);
      this.valueChange.emit(value);
      this.cdr.markForCheck();
    }
  }

  registerOnChange(onChange: (value: T) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
  }

  onButtonClick(value: T): void {
    this.writeValue(value);
  }
}
