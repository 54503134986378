export interface CustomRole {
  readonly id: string;
  readonly name: string;
  readonly identifier: string;
  readonly description?: string;
  readonly permissions: string[];
  readonly scope: CustomRoleScope;
  readonly default?: boolean;
  readonly disabled?: boolean;
}

export interface CustomRoleScope {
  readonly type: CustomRoleScopeType;
  readonly reference?: string;
}

export enum CustomRoleScopeType {
  Admin = 'admin',
  Retailer = 'retailer',
  Branch = 'branch',
  Superadmin = 'superadmin',
}

export const RESTRICTED_SCOPES: Set<CustomRoleScopeType> = new Set([
  CustomRoleScopeType.Admin,
  CustomRoleScopeType.Superadmin,
]);
