import { ActivatedRouteSnapshot, Router } from '@angular/router';

export function getPageName(router: Router, root?: ActivatedRouteSnapshot) {
  let route: ActivatedRouteSnapshot = root ?? router.routerState.root.snapshot;
  let pageName = '';

  while (route.firstChild) {
    route = route.firstChild;
  }

  if (route.data.pageName) {
    pageName = route.data.pageName as string;
  }

  return pageName;
}
