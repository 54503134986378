import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxTooltipModule } from '@infragistics/igniteui-angular';

import { TooltipComponent } from './components';
import { TooltipDirective } from './directives';

@NgModule({
  declarations: [TooltipComponent, TooltipDirective],
  imports: [CommonModule, IgxTooltipModule],
  exports: [TooltipComponent, TooltipDirective],
})
export class TooltipModule {}
