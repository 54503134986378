<div class="supy-pagination">
  <span class="supy-pagination__button supy-pagination__navigation">
    <supy-button
      [disabled]="previousDisabled"
      (buttonClick)="onPrevious()"
      [color]="buttonColor"
      [variant]="buttonVariant"
      width="full"
      density="medium"
      name="left"
      ><supy-icon [name]="previousIconName"></supy-icon
    ></supy-button>
  </span>
  <span *ngIf="!hidePageNumbers" class="supy-pagination__button">
    <supy-button color="default-outline" width="full" density="medium" name="page-index">{{
      pageIndex + 1
    }}</supy-button>
  </span>
  <span>
    <supy-button
      [disabled]="nextDisabled"
      (buttonClick)="onNext()"
      [color]="buttonColor"
      [variant]="buttonVariant"
      width="full"
      density="medium"
      name="right"
      ><supy-icon [name]="nextIconName"></supy-icon
    ></supy-button>
  </span>
</div>
