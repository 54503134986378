import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Tracer, TracerProvider } from '@opentelemetry/api';
import { ZoneContextManager } from '@opentelemetry/context-zone-peer-dep';
import { CompositePropagator, W3CBaggagePropagator, W3CTraceContextPropagator } from '@opentelemetry/core';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http/build/esnext';
import { Resource } from '@opentelemetry/resources';
import { BatchSpanProcessor, ConsoleSpanExporter } from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { SEMRESATTRS_SERVICE_NAME, SEMRESATTRS_SERVICE_VERSION } from '@opentelemetry/semantic-conventions';
import {
  getClient,
  SentryPropagator,
  SentrySampler,
  setOpenTelemetryContextAsyncContextStrategy,
  setupEventContextTrace,
  wrapContextManagerClass,
} from '@sentry/opentelemetry';
import { Client } from '@sentry/types';

import { DEFAULT_TRACER, SDK_OPTIONS } from '../common';
import { OpenTelemetryModuleOptions } from '../config';
import { ITraceService } from './base';
import { SentryApiErrorSpanProcessor } from './processors';

@Injectable()
export class TraceService implements ITraceService, OnDestroy {
  private readonly tracerProvider: WebTracerProvider;

  constructor(@Inject(SDK_OPTIONS) options: OpenTelemetryModuleOptions) {
    const client = getClient() as Client;

    setupEventContextTrace(client);

    const tracerProvider = new WebTracerProvider({
      resource: new Resource({
        [SEMRESATTRS_SERVICE_NAME]: options.application.name,
        [SEMRESATTRS_SERVICE_VERSION]: options.application.version,
      }),
      sampler: new SentrySampler(client),
    });

    if (options.tracing?.debug) {
      const consoleExporter = new ConsoleSpanExporter();
      const consoleProcessor = new BatchSpanProcessor(consoleExporter);

      tracerProvider.addSpanProcessor(consoleProcessor);
    }

    const otlpExporter = new OTLPTraceExporter({
      url: options.tracing?.url,
    });

    const otlpProcessor = new BatchSpanProcessor(otlpExporter);

    const sentrySpanProcessor = new SentryApiErrorSpanProcessor();

    tracerProvider.addSpanProcessor(otlpProcessor);
    tracerProvider.addSpanProcessor(sentrySpanProcessor);

    const SentryContextManager = wrapContextManagerClass(ZoneContextManager);

    tracerProvider.register({
      contextManager: new SentryContextManager(),
      propagator: new CompositePropagator({
        propagators: [new W3CTraceContextPropagator(), new W3CBaggagePropagator(), new SentryPropagator()],
      }),
    });

    setOpenTelemetryContextAsyncContextStrategy();

    this.tracerProvider = tracerProvider;
  }

  getTracer(name: string = DEFAULT_TRACER): Tracer {
    return this.tracerProvider.getTracer(name);
  }

  getTracerProvider(): TracerProvider {
    return this.tracerProvider;
  }

  async ngOnDestroy(): Promise<void> {
    await this.tracerProvider.forceFlush();
    await this.tracerProvider.shutdown();
  }
}
