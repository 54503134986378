import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { DisplayDensity, IgxInputGroupComponent, IgxInputGroupType } from '@infragistics/igniteui-angular';

import { IconType } from '../../../icon';

type InputDensity = 'small' | 'medium' | 'large';

const densityMap: Record<InputDensity, DisplayDensity> = {
  small: 'compact',
  medium: 'cosy',
  large: 'comfortable',
};

@Component({
  selector: 'supy-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaComponent {
  @ViewChild(IgxInputGroupComponent, { static: true })
  readonly inputGroup: IgxInputGroupComponent;

  @Input() readonly inputType?: IgxInputGroupType = 'border';
  @Input() @HostBinding('attr.name') readonly name: string;
  @Input() readonly prefix: IconType;
  @Input() readonly suffix?: IconType;
  @Input() readonly id?: string = '';
  @Input() readonly rows?: number = 1;
  @Input() readonly cols?: number = 30;
  @Input() set density(value: InputDensity) {
    this.displayDensity = densityMap[value] ?? 'compact';
  }

  @Input() readonly autocomplete?: string;
  @Input() readonly placeholder?: string;
  @Input() readonly clearable?: boolean;
  @Input() value: string;

  @Output() readonly focusOut = new EventEmitter<FocusEvent>();
  @Output() readonly valueChange = new EventEmitter<string>();
  @Output() readonly submitValue = new EventEmitter<string>();
  @Output() readonly cleared = new EventEmitter<MouseEvent>();

  @ViewChild('textArea') readonly textArea: ElementRef<HTMLElement>;

  displayDensity: DisplayDensity = 'compact';

  onChange: (value: string) => void;

  onTouched: () => void;

  touched = false;

  @Input() disabled = false;
  @Input() readOnly = false;

  get nativeElement() {
    return this.textArea?.nativeElement;
  }

  constructor(
    private readonly cdr: ChangeDetectorRef,
    @Optional()
    @Inject(NgControl)
    private readonly control?: NgControl,
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  clearInput(event: MouseEvent): void {
    if (this.clearable) {
      this.value = null;
      this.onChange?.(this.value);
      this.cleared.emit(event);
    }
  }

  onKeyPress(event: KeyboardEvent): void {
    if (!this.disabled) {
      const { form } = event.target as unknown as { form: EventTarget };

      if (form && event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();

        form.dispatchEvent(new Event('submit', { cancelable: true }));
        this.submitValue.emit(this.value);

        return;
      }
    }
  }

  onValueChange(event: Event): void {
    if (!this.disabled) {
      const element = event.target as HTMLTextAreaElement;
      const value = element.value;

      this.value = value;

      this.onChange?.(this.value);

      this.valueChange.emit(this.value);

      if (this.clearable) {
        element.blur();
      }
    }
  }

  onFocusOut(e: FocusEvent): void {
    this.focusOut.emit(e);
    this.markAsTouched();
  }

  writeValue(value: string): void {
    this.value = value;
    this.cdr.detectChanges();
  }

  registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.touched = true;
      this.onTouched?.();
      this.emitTouchStatusChanged();
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    this.cdr.markForCheck();
  }

  private emitTouchStatusChanged(): void {
    if (!this.control) {
      return;
    }

    const statusChanges = this.control.statusChanges as EventEmitter<string>;

    statusChanges.emit('TOUCHED');
  }
}
