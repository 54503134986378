import { debounceTime, filter, Observable, of, Subject, takeUntil } from 'rxjs';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { Destroyable, trackByProperty } from '@supy/common';

import { getDefaultRange, PredefinedRange } from '../../../date-range';
import { DropdownTreeNode } from '../../../tree-view-dropdown';
import { FilterGroup } from './filter-group.class';
import { BaseFilterChange, SelectFilterConfig, SelectType, SwitchConfig } from './filter-group.model';

@Component({
  selector: 'supy-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterGroupComponent<T extends BaseFilterChange> extends Destroyable implements OnInit {
  @Input() set filterGroup(filterGroup: FilterGroup<T>) {
    this.renderFilters(filterGroup);
  }

  get filterGroup(): FilterGroup<T> {
    return this.#filterGroup;
  }

  @Input() readonly latency: number = 200;
  @Input() readonly twoRows: boolean = false;
  @Input() readonly externalFiltering: boolean;
  @Input() readonly predefinedDateRanges: PredefinedRange[] = getDefaultRange();

  @Output() readonly filtersChange = new EventEmitter<T>();
  @Output() readonly filtersReset = new EventEmitter<void>();

  protected form = new UntypedFormGroup({
    search: new FormControl<string>(null),
  });

  protected readonly selectType = SelectType;
  protected readonly trackSelectionPropertiesByName = trackByProperty<SelectFilterConfig>('name');
  protected readonly trackSwitchPropertiesByName = trackByProperty<SwitchConfig>('name');

  #filterGroup = new FilterGroup<T>({});
  /**
   * TODO: check on init only why filtersChange is emitted when there is dropdowns in config
   * and when fixed check components that use this filters and rely on filtersChange on init
   * to fetch data like orders-list-retailer
   */
  #canEmit = false;

  protected appliedFilters = 0;

  private readonly debouncer$ = new Subject<T>();
  private readonly onAddNewProperty = (property: { name: string; value?: string }) => {
    if (this.filterGroup.searchConfig && property.name === 'search') {
      this.updateSearchConfig();
    } else {
      this.updateControlValue(property.name, property.value);
    }
    this.appliedFilters = this.getAppliedFilters();
    this.cdr.markForCheck();
  };

  private readonly getSnapshot = () => (this.form?.getRawValue?.() ?? {}) as T;

  constructor(private readonly cdr: ChangeDetectorRef) {
    super();
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      this.appliedFilters = this.getAppliedFilters();

      this.debouncer$.next(value as T);
      this.cdr.markForCheck();
    });
  }

  ngOnInit(): void {
    this.debouncer$
      .pipe(
        filter(() => this.#canEmit),
        debounceTime(this.latency),
        takeUntil(this.destroyed$),
      )
      .subscribe((v: T) => {
        const search = v.search?.trim() ?? '';
        const filters = { ...v, search };

        this.filterGroup?.change$.emit(filters);
        this.filterGroup?.change.set(filters);
        this.filtersChange.emit(filters);
      });
  }

  protected renderFilters(filtersGroup: FilterGroup<T>): void {
    this.#canEmit = false;

    let searchCtrl = this.form.get('search') as FormControl<string | null>;

    if (!searchCtrl) {
      searchCtrl = new FormControl<string | null>(null);
      this.form.addControl('search', searchCtrl, { emitEvent: false });
    }

    searchCtrl.setValue(filtersGroup.searchConfig?.value ?? null);

    (filtersGroup.selectionProperties ?? []).forEach(property => {
      let control = this.form.get(property.name) as UntypedFormControl;

      if (!control) {
        control = new UntypedFormControl(property.value);
        this.form.addControl(property.name, control, { emitEvent: false });
      } else {
        control.patchValue(property.value, { emitEvent: false });
      }
    });

    (filtersGroup.switchProperties ?? []).forEach(property => {
      let control = this.form.get(property.name) as UntypedFormControl;

      if (!control) {
        control = new UntypedFormControl(property.value);
        this.form.addControl(property.name, control, { emitEvent: false });
      } else {
        control.patchValue(property.value, { emitEvent: false });
      }
    });

    this.#filterGroup = filtersGroup;
    this.#filterGroup.registerOnUpdateProperty(this.onAddNewProperty);
    this.#filterGroup.registerOnGetSnapshot(this.getSnapshot);

    this.appliedFilters = this.getAppliedFilters();
    this.cdr.markForCheck();
    this.#canEmit = true;
  }

  protected ensureObservable<T>(list: T[] | Observable<T[]>): Observable<T[]> {
    return !Array.isArray(list) && list?.subscribe !== undefined ? list : (of(list ?? []) as Observable<T[]>);
  }

  protected treeObservable<T>(list: T[] | Observable<T[]>): Observable<DropdownTreeNode<T>[]> {
    return this.ensureObservable<DropdownTreeNode<T>>(list as DropdownTreeNode<T>[]);
  }

  protected onResetFilters(): void {
    this.#canEmit = false;

    const resetProperties = [...this.filterGroup.selectionProperties, ...this.filterGroup.switchProperties];

    resetProperties.forEach(property => {
      if (property.clearable) {
        const control = this.form.get(property.name);

        if (control) {
          control.reset();
        }
      }
    });

    this.form.get('search')?.reset();

    this.appliedFilters = this.getAppliedFilters();
    this.filterGroup?.reset$.emit();
    this.filtersReset.emit();
    this.cdr.markForCheck();
    this.#canEmit = true;
  }

  protected getValueFromList<T>(list: T[], valueKey: string, displayKey: string, item: string): string {
    const value = list.find(option => option[valueKey] === item);

    return (value && (value[displayKey] as string)) || '';
  }

  private getAppliedFilters(): number {
    const formValue = this.form.getRawValue() as Record<string, string>;

    let count = 0;

    Object.keys(formValue).forEach(key => {
      const clearableSelectionProperty = this.filterGroup.selectionProperties.find(({ name }) => name === key)
        ?.clearable;
      const clearableSwitchesProperty = this.filterGroup.switchProperties.find(({ name }) => name === key)?.clearable;

      if (clearableSelectionProperty || clearableSwitchesProperty || key === 'search') {
        const controlValue = formValue[key];

        if (this.isEmptyControlValue(controlValue)) {
          return;
        }
        count++;
      }
    });

    return count;
  }

  private updateSearchConfig() {
    let searchCtrl = this.form.get('search') as FormControl<string | null>;

    if (!searchCtrl) {
      searchCtrl = new FormControl<string | null>(null);
      this.form.addControl('search', searchCtrl, { emitEvent: false });
    }

    searchCtrl.setValue(this.filterGroup.searchConfig?.value ?? null, { emitEvent: false });
  }

  private updateControlValue(name: string, value?: string) {
    let control = this.form.get(name) as FormControl<string | null>;

    if (!control) {
      control = new FormControl<string | null>(null);
      this.form.addControl(name, control, { emitEvent: false });
    }

    control.setValue(value ?? null, { emitEvent: false });
  }

  private isEmptyControlValue(controlValue: string | string[] | boolean | number | null | unknown): boolean {
    if (controlValue === null || controlValue === undefined || controlValue === '') {
      return true;
    }

    if (Array.isArray(controlValue)) {
      return controlValue.length === 0;
    }

    if (typeof controlValue === 'object' && Object.keys(controlValue).length > 0) {
      return Object.values(controlValue).every(value => this.isEmptyControlValue(value));
    }

    return false;
  }
}
