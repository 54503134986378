import { IConfig, UnleashClient } from 'unleash-proxy-client';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';

import { DefaultUnleashGuard } from './guards';
import { DefaultUnleashResolver } from './resolvers';
import { UnleashDirective } from './unleash.directive';
import { UNLEASH_CLIENT } from './unleash.injector';

@NgModule({
  declarations: [UnleashDirective],
  providers: [DefaultUnleashResolver, DefaultUnleashGuard],
  exports: [UnleashDirective],
})
export class UnleashModule {
  static forRoot(config: IConfig): ModuleWithProviders<UnleashModule> {
    return {
      ngModule: UnleashModule,
      providers: [
        {
          provide: UNLEASH_CLIENT,
          useFactory: () => {
            const unleash = new UnleashClient({ ...config });

            return unleash;
          },
        },
        {
          provide: APP_INITIALIZER,
          useFactory: (unleash: UnleashClient) => {
            return () => unleash.start();
          },
          deps: [UNLEASH_CLIENT],
          multi: true,
        },
      ],
    };
  }
}
