import { first } from 'rxjs';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { IgxSnackbarComponent } from '@infragistics/igniteui-angular';

import { SnackbarOptions, SnackbarService, SnackbarTarget, SnackbarVariant } from '@supy/common';

import { SnackbarPosition, snackbarPositionSettings } from './snackbar.position';

@Component({
  selector: 'supy-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackbarComponent implements SnackbarTarget, AfterViewInit, OnInit {
  @ViewChild('button') button: ElementRef<HTMLButtonElement>;
  @ViewChild(IgxSnackbarComponent, { static: true }) snackbar: IgxSnackbarComponent;
  @Input() readonly position: SnackbarPosition = 'bottom-right';
  @Input() set variant(value: SnackbarVariant) {
    this.#variant = value;
  }

  get variant(): SnackbarVariant {
    return this.#variant;
  }

  @Input() readonly autoHide: boolean = true;
  @Input() readonly actionText: string = 'x';
  @Input() set displaySeconds(seconds: number) {
    this.#displayMilliseconds = seconds * 1000;
  }

  title: string;
  #displayMilliseconds = 5000;
  #variant: SnackbarVariant = 'error';

  get displayMilliseconds(): number {
    return this.#displayMilliseconds;
  }

  constructor(private readonly snackbarService: SnackbarService) {}

  ngOnInit(): void {
    this.snackbarService.registerComponent(this);
  }

  ngAfterViewInit(): void {
    const settings = snackbarPositionSettings[this.position];

    this.snackbar.positionSettings = settings;
  }

  open(message: string, options?: SnackbarOptions): void {
    const oldVariant = this.variant;

    this.title = message;

    if (options?.variant) {
      this.#variant = options.variant;
    }
    this.button.nativeElement.click();
    this.snackbar.closed.pipe(first()).subscribe(() => {
      this.#variant = oldVariant;
    });
  }

  close(): void {
    this.snackbar.close();
  }
}
