import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ISliderValueChangeEventArgs } from '@infragistics/igniteui-angular';

type Base64String = string;

type DataURL = string;

export type ImageCropperOutput = Blob | Base64String | DataURL;

type OutputType = 'blob' | 'base64' | 'dataurl';

@Component({
  selector: 'supy-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageCropperComponent implements OnInit {
  @Input() readonly imageChangedEvent: Event;
  @Input() readonly imageBlob: File;
  @Input() readonly imageURL: string;
  @Input() readonly imageBase64: string;
  @Input() readonly zoomMultiplier: number = 1;
  @Input() readonly outputType: OutputType = 'blob';
  @Output() readonly imageLoaded = new EventEmitter<void>();
  @Output() readonly imageCropped = new EventEmitter<ImageCropperOutput>();
  @Output() readonly zoomChanged = new EventEmitter<ISliderValueChangeEventArgs>();
  @Output() readonly imageInvalid = new EventEmitter<Event>();

  transform: ImageTransform;
  showCropper = false;

  ngOnInit(): void {
    this.transform = {
      scale: 1 * this.zoomMultiplier,
    };
  }

  onLoadImageFail(): void {
    this.imageInvalid.emit();
  }

  onValueChange(event: ISliderValueChangeEventArgs): void {
    this.changeZoom(event);
    this.zoomChanged.emit(event);
  }

  onImageCropped(event: ImageCroppedEvent): void {
    const { base64 } = event;
    let output: ImageCropperOutput = base64;

    switch (this.outputType) {
      case 'blob':
        output = this.base64ToBlob(base64);
        break;
      case 'dataurl':
        output = this.base64ToDataURL(base64);
        break;
    }
    this.imageCropped.emit(output);
  }

  onImageLoaded(): void {
    this.showCropper = true;
    this.imageLoaded.emit();
  }

  changeZoom(event: ISliderValueChangeEventArgs): void {
    const scale = ((event.value as number) / 100) * this.zoomMultiplier;
    const transform: ImageTransform = { scale };

    this.transform = transform;
  }

  base64ToBlob(base64: Base64String): Blob {
    const [, byteChars] = base64.split(',');
    const charCodeArray = [];

    for (const char of atob(byteChars)) {
      charCodeArray.push(char.charCodeAt(0));
    }

    const byteArray = new Uint8Array(charCodeArray);

    return new Blob([byteArray], { type: 'image/jpeg' });
  }

  base64ToDataURL(base64: Base64String): string {
    const blob = this.base64ToBlob(base64);
    const dataURL = URL.createObjectURL(blob);

    return dataURL;
  }
}
