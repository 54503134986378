import { BehaviorSubject, filter, map, takeUntil } from 'rxjs';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import {
  AutoPositionStrategy,
  CalendarSelection,
  DateRangeDescriptor,
  DateRangeType,
  HorizontalAlignment,
  IgxCalendarComponent,
  IgxToggleDirective,
  OverlaySettings,
  VerticalAlignment,
} from '@infragistics/igniteui-angular';

import { Destroyable, EventTime } from '@supy/common';

import { ButtonComponent } from '../../../button';
import {
  getLastMonths,
  getLastWeeks,
  getPreviousQuarters,
  PredefinedRange,
  PredefinedRangeBy,
} from '../../../date-range';
import { getDefaultCalendarPickerNormalPredefinedRanges } from './calendar-picker.helpers';

export enum CalendarDisableMode {
  AllExceptSpecialDates = 'allExceptSpecialDates',
  BeforeLastSpecialDate = 'beforeLastSpecialDate',
  None = 'none',
}

export interface CalendarPickerPredefinedDate {
  readonly customLabel?: string;
  readonly option: CalendarPickerPredefinedDateOption;
}

export enum CalendarPickerPredefinedDateOption {
  LastTwoSpecialDates = 'lastTwoSpecialDates',
  AllSpecialDates = 'allSpecialDates',
}

export type CalendarPickerPredefinedDateValues = CalendarPickerPredefinedDate | PredefinedRange;

@Component({
  selector: 'supy-calendar-picker',
  templateUrl: './calendar-picker.component.html',
  styleUrls: ['./calendar-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarPickerComponent extends Destroyable implements OnInit, AfterViewInit, ControlValueAccessor {
  @ViewChild('calendar') private readonly calendar: IgxCalendarComponent;

  @ViewChild(IgxToggleDirective, { static: true }) private readonly igxToggle: IgxToggleDirective;
  @ViewChild('toggler', { static: true }) private readonly toggler: ButtonComponent;

  @Output() readonly valueChanged = new EventEmitter<Date[] | Date>();
  @Output() readonly closed = new EventEmitter<Date[] | Date>();
  @Output() readonly opened = new EventEmitter<void>();

  @Input() readonly displayFormat: string = 'dd.MM.yy';
  @Input() readonly selectionType: CalendarSelection = CalendarSelection.RANGE;
  @Input() readonly disableMode: CalendarDisableMode = CalendarDisableMode.AllExceptSpecialDates;
  @Input() readonly placeholder: string = 'Date';
  @Input() readonly hideOutsideDays: boolean = false;
  @Input() @HostBinding('attr.name') readonly name: string;
  @Input() predefinedRanges: CalendarPickerPredefinedDateValues[] = [];
  @Input() readonly monthsViewNumber: number = 2;
  @Input() viewDate: Date = new Date(new Date().setMonth(new Date().getMonth() - 1));
  @Input() readonly isLoading: boolean = false;
  @Input() readonly onlyEmitOnConfirmation: boolean = false;
  @Input() readonly lastSpecialDateEventTime: EventTime = EventTime.StartOfDay;
  @Input() canSelectSingleDate = false;
  @Input() readonly disableAfter: Date;

  @Input() set specialDates(values: Date[]) {
    this.#specialDates = [...(values ?? [])];

    this.selectableDatesLoaded$.next(true);
  }

  get specialDates(): Date[] {
    return this.#specialDates;
  }

  get hasValue(): boolean {
    return this.value?.toString()?.length > 0;
  }

  @Input() value: Date[] | Date;
  onChange: (value: Date[] | Date) => void;

  onTouched: () => void;

  touched = false;

  #specialDates: Date[];
  #disabled: boolean;

  private readonly selectableDatesLoaded$ = new BehaviorSubject<boolean>(false);

  @Input() set disabled(value: boolean) {
    this.#disabled = value;

    this.igxToggle.close();

    this.cdr.markForCheck();
  }

  get disabled(): boolean {
    return this.#disabled;
  }

  private readonly positionSettings = {
    horizontalStartPoint: HorizontalAlignment.Left,
    verticalStartPoint: VerticalAlignment.Bottom,
  };

  private overlaySettings: OverlaySettings;

  get showSidebar(): boolean {
    return this.selectionType === CalendarSelection.RANGE && this.predefinedRanges?.length > 0;
  }

  get canSubmit(): boolean {
    return this.selectionType === CalendarSelection.RANGE ? Array.isArray(this.value) : !!this.value;
  }

  get displayValue(): string {
    switch (this.selectionType) {
      case CalendarSelection.RANGE: {
        const value = this.value as Date[];
        const startDate = value?.length ? value[0] : null;
        const endDate = value?.length ? value[value.length - 1] : null;

        return startDate || endDate
          ? `${startDate.getDate()}.${startDate.getMonth() + 1}.${startDate.getFullYear()} - ${endDate.getDate()}.${
              endDate.getMonth() + 1
            }.${endDate.getFullYear()}`
          : '';
      }
      case CalendarSelection.MULTI: {
        const value = this.value as Date[];

        return `(${value?.length}) Date${value?.length > 1 ? 's' : ''} Selected`;
      }
      case CalendarSelection.SINGLE: {
        const value = this.value as Date;

        return `${value.getDate()}.${value.getMonth() + 1}.${value.getFullYear()}`;
      }
    }
  }

  constructor(
    private readonly cdr: ChangeDetectorRef,
    @Optional()
    @Inject(NgControl)
    private readonly control?: NgControl,
  ) {
    super();

    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    if (this.selectionType === CalendarSelection.SINGLE || this.selectionType === CalendarSelection.MULTI) {
      this.canSelectSingleDate = true;
    }
  }

  ngAfterViewInit(): void {
    this.overlaySettings = {
      closeOnOutsideClick: true,
      modal: true,
      closeOnEscape: true,
      positionStrategy: new AutoPositionStrategy(this.positionSettings),
    };

    this.selectableDatesLoaded$
      .pipe(
        takeUntil(this.destroyed$),
        filter(Boolean),
        map(() => {
          const disabledDates = this.getDisabledDates(this.#specialDates);
          const specialDates = this.getSpecialDates(this.#specialDates);

          this.calendar.specialDates = specialDates;

          this.calendar.disabledDates = disabledDates;

          if (this.disableMode === CalendarDisableMode.AllExceptSpecialDates) {
            this.viewDate = specialDates?.length ? specialDates[specialDates.length - 1].dateRange[0] : new Date();
          } else {
            this.predefinedRanges = getDefaultCalendarPickerNormalPredefinedRanges();
          }
        }),
      )
      .subscribe();

    if (this.selectionType === CalendarSelection.SINGLE || this.selectionType === CalendarSelection.MULTI) {
      this.canSelectSingleDate = true;
    }
  }

  onSelectPredefinedDate(range: CalendarPickerPredefinedDateValues): void {
    this.disableMode === CalendarDisableMode.AllExceptSpecialDates
      ? this.selectPredefinedDateRange(range as CalendarPickerPredefinedDate)
      : this.selectNormalPredefinedDateRange(range as PredefinedRange);
  }

  selectPredefinedDateRange({ option }: CalendarPickerPredefinedDate): void {
    const uniqueAndSortedDates = this.getUniqueAndSortedDates(this.#specialDates);

    switch (option) {
      case CalendarPickerPredefinedDateOption.LastTwoSpecialDates: {
        if (uniqueAndSortedDates?.length > 1) {
          const start = uniqueAndSortedDates[uniqueAndSortedDates.length - 2];
          const end = uniqueAndSortedDates[uniqueAndSortedDates.length - 1];
          const dates = this.getDatesBetween(start, end);

          this.calendar.selectDate(dates);
          this.onSelection(dates);
        }
        break;
      }
      case CalendarPickerPredefinedDateOption.AllSpecialDates: {
        if (uniqueAndSortedDates?.length > 1) {
          const start = uniqueAndSortedDates[0];
          const end = uniqueAndSortedDates[uniqueAndSortedDates.length - 1];
          const dates = this.getDatesBetween(start, end);

          this.calendar.selectDate(dates);
          this.onSelection(dates);
        }
      }
    }
  }

  selectNormalPredefinedDateRange(predefinedRange: PredefinedRange): void {
    let today: Date = new Date();

    let start: Date;

    switch (predefinedRange.by) {
      case PredefinedRangeBy.Days:
        start = new Date(new Date().setDate(today.getDate() - predefinedRange.range + 1));
        break;
      case PredefinedRangeBy.Weeks:
        if (predefinedRange.previousRange) {
          const range = getLastWeeks(predefinedRange.range);

          today = range.end as Date;
          start = range.start as Date;
        } else {
          start = new Date(new Date().setDate(today.getDate() - predefinedRange.range * 7 + 1));
        }
        break;

      case PredefinedRangeBy.Months:
        if (predefinedRange.previousRange) {
          const range = getLastMonths(predefinedRange.range);

          today = range.end as Date;
          start = range.start as Date;
        } else {
          start = new Date(new Date().setMonth(today.getMonth() - predefinedRange.range));
        }
        break;
      case PredefinedRangeBy.Quarter:
        {
          const range = getPreviousQuarters(predefinedRange.previousRange ? 1 : 0);

          start = range.start as Date;

          if (today > range.end) {
            today = range.end as Date;
          }
        }
        break;
      case PredefinedRangeBy.Years:
        start = new Date(new Date().setFullYear(today.getFullYear() - predefinedRange.range));
        break;
    }

    const dates = this.getDatesBetween(start, today);

    this.calendar.selectDate(dates);
    this.onSelection(dates);
  }

  getDatesBetween(startDate: Date, endDate: Date): Date[] {
    let dates: Date[] = [];

    const currentDate = startDate;

    while (currentDate.getTime() <= endDate.getTime()) {
      dates = [...dates, new Date(currentDate)];

      currentDate.setUTCDate(currentDate.getUTCDate() + 1);
    }

    return dates;
  }

  getDisabledDates(inputDates: Date[]): DateRangeDescriptor[] {
    const disabledDates: DateRangeDescriptor[] = [];

    if (this.disableAfter) {
      disabledDates.push({
        type: DateRangeType.After,
        dateRange: [this.disableAfter],
      });
    }

    if (!inputDates?.length) {
      return disabledDates;
    }

    const uniqueAndSortedDates = this.getUniqueAndSortedDates(inputDates);

    const firstDate = uniqueAndSortedDates[0];
    const lastDate = uniqueAndSortedDates[uniqueAndSortedDates.length - 1];

    switch (this.disableMode) {
      case CalendarDisableMode.None:
        return disabledDates;

      case CalendarDisableMode.AllExceptSpecialDates: {
        disabledDates.push(
          {
            type: DateRangeType.Before,
            dateRange: [firstDate],
          },
          {
            type: DateRangeType.After,
            dateRange: [lastDate],
          },
        );

        for (const [i, startCountDate] of uniqueAndSortedDates.entries()) {
          if (i === uniqueAndSortedDates.length - 1) {
            break;
          }

          const nextCountDate = uniqueAndSortedDates[i + 1];

          const startDisable = new Date(startCountDate.getTime());

          startDisable.setDate(startDisable.getDate() + 1);

          if (startDisable.getTime() === nextCountDate.getTime()) {
            continue;
          }

          const endDisable = new Date(nextCountDate.getTime());

          endDisable.setDate(endDisable.getDate() - 1);

          const thisRange = {
            type: DateRangeType.Between,
            dateRange: [startDisable, endDisable],
          };

          disabledDates.push(thisRange);
        }

        return disabledDates;
      }

      case CalendarDisableMode.BeforeLastSpecialDate: {
        disabledDates.push({
          type: DateRangeType.Before,
          dateRange: [lastDate],
        });

        if (this.lastSpecialDateEventTime === EventTime.EndOfDay) {
          disabledDates.push({
            type: DateRangeType.Specific,
            dateRange: [lastDate],
          });
        }

        return disabledDates;
      }
    }
  }

  getSpecialDates(inputDates: Date[]): DateRangeDescriptor[] {
    if (!inputDates?.length) {
      return [];
    }

    const uniqueAndSortedDates = this.getUniqueAndSortedDates(inputDates);

    return [
      ...uniqueAndSortedDates.map(stockDate => ({
        type: DateRangeType.Specific,
        dateRange: [stockDate],
      })),
    ];
  }

  getUniqueAndSortedDates(inputDates: Date[]): Date[] {
    const uniqueDates = [...new Set(inputDates.map(date => date.setUTCHours(0, 0, 0, 0)))].map(date => new Date(date));

    return uniqueDates.sort((a, b) => (a.getTime() > b.getTime() ? 1 : -1));
  }

  protected toggleCalendar() {
    this.overlaySettings.target = this.toggler.buttonElement.nativeElement;

    if (!this.igxToggle.collapsed) {
      this.igxToggle.close();

      return;
    }

    this.igxToggle.open(this.overlaySettings);
  }

  open() {
    this.overlaySettings.target = this.toggler.buttonElement.nativeElement;

    this.igxToggle.open(this.overlaySettings);
  }

  onSelection(event: Date[] | Date) {
    if (this.canSelectSingleDate || (event as Date[]).length !== 1) {
      if (this.selectionType === CalendarSelection.RANGE) {
        const selectedDatesLength = (event as Date[]).length;
        const adjustedEndDate = (event as Date[])[selectedDatesLength - 1]
          ? new Date(new Date((event as Date[])[selectedDatesLength - 1]).setHours(23, 59, 59, 999))
          : null;
        const startDate = (event as Date[])[0] ?? null;

        const adjustedEvent: Date[] = [startDate, adjustedEndDate].filter(Boolean);

        this.writeValue(adjustedEvent);
        this.valueChanged.emit(adjustedEvent);

        if (!this.onlyEmitOnConfirmation) {
          this.onChange?.(adjustedEvent);
        }
      } else {
        this.writeValue(event as Date[]);
        this.valueChanged.emit(event);

        if (!this.onlyEmitOnConfirmation) {
          this.onChange?.(event);
        }
      }
    }
  }

  onSubmit(): void {
    if (this.onlyEmitOnConfirmation) {
      this.onChange?.(this.value);
    }

    this.igxToggle.close();
    this.closed.emit(this.value);
  }

  onClear(): void {
    this.value = null;
    this.calendar.deselectDate();
    this.writeValue(null);
    this.onChange?.(this.value);
    this.valueChanged.emit(this.value);
    this.cdr.detectChanges();
  }

  writeValue(value: Date[]): void {
    this.value = value;

    if (!value) {
      this.calendar?.writeValue(value);
      this.calendar?.deselectDate();
    }

    setTimeout(() => {
      this.cdr.markForCheck();
    }, 0);
  }

  registerOnChange(onChange: (value: Date[]) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.touched = true;
      this.onTouched?.();
      this.emitTouchStatusChanged();
    }
  }

  private emitTouchStatusChanged(): void {
    if (!this.control) {
      return;
    }

    const statusChanges = this.control.statusChanges as EventEmitter<string>;

    statusChanges.emit('TOUCHED');
  }
}
