import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'supy-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StarRatingComponent implements ControlValueAccessor {
  @Input() readonly stars: number = 5;

  protected starsToFill = 0;
  protected touched = false;
  protected disabled: boolean;

  #selectedStars = 0;

  onTouched: () => void;
  onChange: (value: number) => void;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    @Optional()
    @Inject(NgControl)
    private readonly control?: NgControl,
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  writeValue(value: number): void {
    this.#selectedStars = this.starsToFill = value;
    this.onChange?.(value);
    this.markAsTouched();
  }

  registerOnChange(onChange: (value: number) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  onClick(n: number): void {
    this.writeValue(n);
  }

  onMouseEnter(n: number): void {
    this.starsToFill = n > this.#selectedStars ? n : this.#selectedStars;
  }

  onMouseLeave(): void {
    this.starsToFill = this.#selectedStars;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.touched = true;
      this.onTouched?.();
      this.emitTouchStatusChanged();
    }
  }

  private emitTouchStatusChanged(): void {
    if (!this.control) {
      return;
    }

    const statusChanges = this.control.statusChanges as EventEmitter<string>;

    statusChanges?.emit('TOUCHED');
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }
}
