import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { AuthService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class UnAuthGuard {
  constructor(private readonly authService: AuthService) {}

  canActivate(): Observable<boolean> {
    return this.authService.user$.pipe(map(user => !user));
  }
}
