import { filter, first, Observable, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { AuthService } from '@supy/auth';
import { BaseHttpService, IQueryResponse, Query } from '@supy/common';
import { APP_CONFIG } from '@supy/core';

import { CountriesConfig } from '../config';
import { Country } from '../core';

@Injectable({ providedIn: 'root' })
export class CountriesService extends BaseHttpService {
  constructor(
    private readonly authService: AuthService,
    protected readonly httpClient: HttpClient,
    @Inject(APP_CONFIG) protected readonly config: CountriesConfig,
  ) {
    super(`${config.apiUrl}${config.apiUrlPrefix}/countries`);
  }

  getCountries(query: Query<Country>): Observable<IQueryResponse<Country>> {
    query.filtering.setFilter({ by: 'state', op: 'eq', match: 'active' });

    return this.authService.user$.pipe(
      filter(Boolean),
      first(),
      switchMap(() => this.get<IQueryResponse<Country>>('', query.toQueryParams())),
    );
  }
}
