<igx-tree-grid
  igxDrop
  class="supy-tree-grid"
  cellSelection="none"
  [data]="data"
  [childDataKey]="childDataKey"
  [primaryKey]="primaryKey"
  [foreignKey]="foreignKey"
  [autoGenerate]="autoGenerate"
  [height]="height"
  [width]="width"
  [rowEditable]="rowEditable"
  [rowHeight]="rowHeight"
  [rowClasses]="rowClasses"
  [dataCloneStrategy]="dataCloneStrategy"
  [clipboardOptions]="clipboardOptions"
  [allowFiltering]="allowFiltering"
  [filterStrategy]="filterStrategy"
  [sortStrategy]="sortStrategy"
  [filterMode]="filterMode"
  [rowDraggable]="rowDraggable"
  [emptyGridTemplate]="emptyGridTemplate"
  [addRowEmptyTemplate]="addRowEmptyTemplate"
  [expansionDepth]="expansionDepth"
  (columnMovingEnd)="columnMovingEnd.emit($event)"
  (cellClick)="cellClick.emit($event)"
  (selected)="selected.emit($event)"
  (cellEdit)="cellEdit.emit($event)"
  (cellEditEnter)="cellEditEnter.emit($event)"
  (cellEditDone)="cellEditDone.emit($event)"
  (cellEditExit)="cellEditExit.emit($event)"
  (rowEdit)="rowEdit.emit($event)"
  (rowEditDone)="rowEditDone.emit($event)"
  (sorting)="sorting.emit($event)"
  (filtering)="filtering.emit($event)"
  (filteringDone)="filteringDone.emit($event)"
  (dropped)="onDropAllowed($event)"
  (rowDeleted)="onRowDeleted($event)"
  (rowDragEnd)="onRowDragEnd($event)"
  (filteringExpressionsTreeChange)="filteringExpressionsTreeChange.emit($event)"
>
  <ng-template igxExcelStyleHeaderIcon>
    <supy-icon name="filter-alt"></supy-icon>
  </ng-template>
  <igx-column
    *ngFor="let gridColumn of columns; trackBy: trackColumnChanges"
    [sortable]="gridColumn.sortable"
    [header]="gridColumn.header"
    [field]="gridColumn.field"
    [width]="gridColumn.width"
    [minWidth]="gridColumn.minWidth"
    [maxWidth]="gridColumn.maxWidth"
    [dataType]="gridColumn.dataType"
    [disablePinning]="gridColumn.disablePinning"
    [disableHiding]="gridColumn.disableHiding"
    [hidden]="gridColumn.hidden"
    [movable]="gridColumn.movable"
    [formatter]="gridColumn.formatter"
    [filters]="gridColumn.filters"
    [filterable]="gridColumn.filterable"
    [headerClasses]="'align-' + gridColumn.align + ' ' + gridColumn.headerClasses"
    [headerGroupClasses]="gridColumn.headerGroupClasses"
    [editable]="gridColumn.editable"
    [cellClasses]="gridColumn.cellClasses"
    [resizable]="gridColumn.resizable"
    [required]="gridColumn.required"
  >
    <ng-template *ngIf="gridColumn?.cellTemplate?.template" igxCell let-value let-cell="cell">
      <ng-container
        *ngTemplateOutlet="
          gridColumn.cellTemplate.template;
          context: { $implicit: value, value: value, cell: cell, rowData: cell.row.data }
        "
      >
      </ng-container>
    </ng-template>
    <ng-template *ngIf="gridColumn?.cellEditorTemplate?.template" igxCellEditor let-value let-cell="cell">
      <ng-container
        *ngTemplateOutlet="
          gridColumn.cellEditorTemplate.template;
          context: { $implicit: value, value: value, cell: cell, rowData: cell.row.data }
        "
      ></ng-container>
    </ng-template>

    <ng-template *ngIf="gridColumn?.cellHeaderTemplate?.template" igxHeader let-value let-column="column">
      <ng-container
        *ngTemplateOutlet="gridColumn.cellHeaderTemplate.template; context: { $implicit: value, column: column }"
      ></ng-container>
    </ng-template>

    <ng-template *ngIf="gridColumn?.filterCellTemplate?.template" igxFilterCellTemplate let-column="column">
      <ng-container
        *ngTemplateOutlet="gridColumn.filterCellTemplate.template; context: { column: column }"
      ></ng-container>
    </ng-template>
  </igx-column>

  <igx-action-strip *ngIf="editableActions" #actionStrip>
    <div class="supy-tree-grid__editable-actions" *ngIf="!inEditMode(actionStrip.context)">
      <supy-button
        *ngIf="showAddAction && actionStrip.context?.treeRow.level < addRowLevel"
        variant="icon"
        title="Add"
        (buttonClick)="startAddRow(actionStrip.context)"
      >
        <supy-icon name="plus-flat-circle" color="success" size="medium"></supy-icon>
      </supy-button>
      <supy-button *ngIf="showEditAction" variant="icon" title="Edit" (buttonClick)="startEditRow(actionStrip.context)">
        <supy-icon name="edit" color="primary" size="medium"></supy-icon>
      </supy-button>
      <supy-button
        *ngIf="showDeleteAction || actionStrip.context?.treeRow.level > 0"
        variant="icon"
        title="Delete"
        (buttonClick)="deleteRow(actionStrip.context)"
      >
        <supy-icon name="delete" color="error" size="medium"></supy-icon>
      </supy-button>
    </div>
  </igx-action-strip>

  <ng-template igxRowEditActions let-endRowEdit>
    <div class="supy-tree-grid__row-edit-actions">
      <supy-button color="error" igxRowEditTabStop (buttonClick)="endRowEdit(false)">Cancel</supy-button>
      <supy-button color="primary" igxRowEditTabStop (buttonClick)="endRowEdit(true)">Apply</supy-button>
    </div>
  </ng-template>

  <ng-template #addRowEmptyTemplate>
    <supy-button *ngIf="showAddRowEmptyButton" color="secondary" (buttonClick)="onRowAdd()">Add item</supy-button>
  </ng-template>

  <ng-template igxRowEditText></ng-template>

  <igx-grid-footer>
    <ng-content select="supy-tree-grid-footer"></ng-content>
  </igx-grid-footer>

  <igx-grid-footer *ngIf="addedInFooter" class="supy-tree-grid-footer__add-row">
    <supy-button color="default" width="full" (buttonClick)="onRowAdd()">
      <span class="supy-tree-grid-footer__add-row-content">
        <span class="supy-tree-grid-footer__add-row-content-label">{{ addedInFooterTitle }}</span>
        <supy-icon name="plus-flat-circle" title="Add" color="primary" size="medium"></supy-icon>
      </span>
    </supy-button>
  </igx-grid-footer>
</igx-tree-grid>
