<div class="supy-file-upload">
  <div [hidden]="imgUrl || (files?.length && singleImage)" class="supy-file-upload__multiple">
    <div
      [ngClass]="['supy-file-upload__drop-container', variant, variant === 'inline' && files?.length ? 'hidden' : '']"
      [class.over]="dragOver"
      ngFileDrop
      [options]="options"
      (uploadOutput)="onUploadOutput($event)"
      [uploadInput]="uploadInput"
    >
      <supy-icon
        class="supy-file-upload__drop-container-icon"
        name="upload"
        [color]="disabled ? 'gray' : 'primary'"
        [size]="variant === 'block' ? 'huge' : 'medium'"
      ></supy-icon>
      <div>
        <div class="supy-file-upload__drop-container-title {{ variant }}">
          Drag & Drop file<span *ngIf="maxUploads > 1">s</span> here or
          <label [class.disabled]="disabled" class="upload-button" for="supy-file-upload-input"> Browse </label>
          <input
            [disabled]="disabled"
            id="supy-file-upload-input"
            name="supy-file-upload-input"
            type="file"
            ngFileSelect
            [options]="options"
            (uploadOutput)="onUploadOutput($event)"
            [uploadInput]="uploadInput"
            multiple
          />
        </div>
        <div *ngIf="fileExts?.length" class="supy-file-upload__drop-container-supported {{ variant }}">
          <span>Supports: </span>
          <span *ngFor="let type of fileExts; let last = last"
            >{{ type | uppercase }} <span *ngIf="!last">, </span>
          </span>
          <span *ngIf="hasSize">less than {{ sizeInMB }}MB</span>
        </div>
      </div>
    </div>

    <div class="supy-file-upload__item {{ variant }}" *ngFor="let file of files; let i = index">
      <div class="supy-file-upload__item-content {{ variant }}">
        <div class="supy-file-upload__item-filename">
          <span class="supy-file-upload__item-title">{{ file.name }}</span>
        </div>

        <supy-button name="remove-file" variant="icon" color="secondary" (buttonClick)="removeFile(file.id)">
          <supy-icon name="delete-circle" color="error"></supy-icon>
        </supy-button>
      </div>
      <div *ngIf="variant === 'block'" class="supy-file-upload__progress">
        <div
          class="supy-file-upload__progress-bar"
          [style.width]="uploadUrl ? file?.progress?.data?.percentage + '%' : 100 + '%'"
          [class.done]="isUploadSucceed(file) || !uploadUrl"
          [class.error]="isUploadFailed(file)"
        ></div>
      </div>
    </div>
  </div>

  <div *ngIf="(files?.length && singleImage) || imgUrl" class="supy-file-upload__single-image">
    <div class="supy-file-upload__drop-container">
      <div *ngIf="!imgUrl && !isUploadSucceed(files[0]); else imgTmpl" class="supy-file-upload__single-image-progress">
        <div
          class="supy-file-upload__progress-bar"
          [style.width]="files[0]?.progress?.data?.percentage + '%'"
          [class.error]="isUploadFailed(files[0])"
        ></div>

        <span class="supy-file-upload__single-image-progress-status">
          <span *ngIf="isUploadFailed(files[0])">Upload error</span>
          <span *ngIf="!isUploadFailed(files[0])">Loading...</span>
        </span>
      </div>

      <ng-template #imgTmpl>
        <img
          crossorigin="anonymous"
          class="supy-file-upload__single-image-src"
          [src]="files[0]?.response[responseUrlKey] || imgUrl"
        />
      </ng-template>
    </div>

    <supy-button
      class="supy-file-upload__single-image-clear-btn"
      name="remove-file"
      variant="icon"
      color="secondary"
      (buttonClick)="removeFile(files[0]?.id)"
    >
      <supy-icon name="close" color="error"></supy-icon>
    </supy-button>
  </div>

  <div
    *ngIf="variant === 'block'"
    [style.visibility]="rejectionReason ? 'visible' : 'hidden'"
    class="supy-file-upload__error"
  >
    {{ rejectionReason }}
  </div>
</div>
