<div class="supy-multiple-media-uploader">
  <input type="file" multiple [accept]="acceptedFormats" #fileInput (change)="onFileChange($event)" hidden />

  <div class="supy-multiple-media-uploader__main-container">
    <ng-container *ngIf="allFileUrls().length; else noFiles">
      <span
        class="supy-multiple-media-uploader__file-container"
        [style]="{ width: dimensions, height: dimensions }"
        *ngFor="let fileUrl of allFileUrls(); let index = index"
      >
        <supy-icon
          *ngIf="index > 0 || (index === 0 && !hideFirstAttachmentRemoval)"
          class="supy-multiple-media-uploader__remove-icon"
          name="round-x"
          [class.supy-multiple-media-uploader__remove-icon--hidden]="disabled"
          [size]="iconSize"
          (click)="onRemoveFile({ fileUrl, index })"
        ></supy-icon>

        <div class="supy-multiple-media-uploader__image-container">
          <a [href]="fileUrl" target="_blank">
            <img
              [src]="fileUrl"
              supyBackupImage
              backupImage="/assets/images/pdf.svg"
              class="supy-multiple-media-uploader__image"
            />
          </a>
        </div>
      </span>
    </ng-container>

    <button class="supy-multiple-media-uploader__upload-input" (click)="onInputClick()" [disabled]="disabled">
      <supy-loader *ngIf="isLoading; else cameraIcon" [size]="iconSize"></supy-loader>
      <ng-template #cameraIcon>
        <supy-icon name="camera" [size]="iconSize" class="supy-multiple-media-uploader__upload-input--icon"></supy-icon>
      </ng-template>
      <span class="supy-multiple-media-uploader__upload-input--text">{{
        isLoading ? 'Uploading...' : actionText
      }}</span>
    </button>
  </div>

  <ng-template #noFiles>
    <div
      class="supy-multiple-media-uploader__no-files"
      [style]="{ width: dimensions, height: dimensions }"
      (click)="onInputClick()"
      [class.supy-multiple-media-uploader__upload-input--disabled]="disabled"
    >
      <supy-icon name="document-upload" color="primary" [size]="iconSize"></supy-icon>
    </div>
  </ng-template>

  <igx-input-group class="supy-multiple-media-uploader__hints">
    <input type="hidden" igxInput />
    <igx-hint class="supy-multiple-media-uploader__hint-placeholder">
      <supy-input-hint-container class="supy-multiple-media-uploader__hint-container">
        <ng-content select="supy-input-error"></ng-content>
        <ng-content select="supy-input-hint"></ng-content>
      </supy-input-hint-container>
    </igx-hint>
  </igx-input-group>
</div>
