import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxSnackbarModule } from '@infragistics/igniteui-angular';

import { SnackbarComponent } from './components';

@NgModule({
  declarations: [SnackbarComponent],
  imports: [CommonModule, IgxSnackbarModule],
  exports: [SnackbarComponent],
})
export class SnackbarModule {}
