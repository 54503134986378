import { transition, trigger, useAnimation } from '@angular/animations';
import { getLocaleDirection } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnInit,
  Optional,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { swingInTopFwd } from '@infragistics/igniteui-angular';

import { InputDensity } from '../../input';
import { DropdownTreeNode } from '../models';

@Component({
  selector: 'supy-dropdown-tree',
  templateUrl: './dropdown-tree.component.html',
  styleUrls: ['./dropdown-tree.component.scss'],
  animations: [
    trigger('swingInOut', [
      transition('void => *', [
        useAnimation(swingInTopFwd, {
          params: {
            duration: '.2s',
            easing: 'ease-out',
          },
        }),
      ]),
    ]),
  ],
})
export class DropdownTreeComponent<T = string> implements OnInit, OnChanges, ControlValueAccessor {
  @Input() set data(value: DropdownTreeNode<T>[]) {
    if (value) {
      this.#data = structuredClone(value);
    }
  }

  get data(): DropdownTreeNode<T>[] {
    return this.#data;
  }

  @Input() set value(key: T | T[]) {
    this.init(key);
  }

  @Input() name: string;
  @Input() minSearchValue = 3;
  @Input() placeholder: string;
  @Input() disabled = false;
  @Input() readOnly = false;
  @Input() returnStrategy: 'key' | 'object' = 'key';
  @Input() displayStrategy: 'value' | 'path' = 'value';
  @Input() selection: 'single' | 'multiple' = 'single';
  @Input() multipleSelectionStrategy: 'node' | 'children' = 'node';
  @Input() clearable = true;
  @Input() density: InputDensity = 'small';
  @Input() variant: 'border' | 'flat' = 'border';
  @Input() autoExpanded: boolean;
  @Input() autoWidth: boolean;

  @Output() valueChange = new EventEmitter();
  @ViewChild('dropdownOverlay') dropdownOverlay: ElementRef<HTMLElement>;

  #data: DropdownTreeNode<T>[];

  placeholderValue: string;

  treeNodeValue: DropdownTreeNode<T>;

  overlayDisplayed = signal<boolean>(false);
  suggestedNode: DropdownTreeNode<T>;
  nodeIdPrefix = 'tree-node-id';
  path: DropdownTreeNode<T>[] = [];

  private selectedNodes: DropdownTreeNode<T>[];
  private readonly dir: 'ltr' | 'rtl';

  get treeNodeValuePath(): string {
    return this.path.map(node => node.name).reduce((prev, curr) => (!prev ? `${curr}` : `${prev} > ${curr}`), null);
  }

  get allSelected(): boolean {
    return this.data.every(node => this.getChildrenSelectionState(node) === 'all');
  }

  get selectedNodesPath(): string {
    return this.selectedNodes
      .map(node => node.name)
      .reduce((prev, curr) => (!prev ? `${curr}` : `${prev}, ${curr}`), null);
  }

  get textAreaValue() {
    return this.selection === 'single'
      ? this.displayStrategy === 'value'
        ? this.treeNodeValue?.name ?? ''
        : this.treeNodeValuePath
      : this.selectedNodes?.length > 0
      ? this.displayStrategy === 'value'
        ? `(${this.selectedNodes.length}) ${this.placeholderValue}`
        : this.selectedNodesPath
      : '';
  }

  get textAreaBoundingRect() {
    const innerRectangle = this.element?.nativeElement?.getBoundingClientRect();
    const rem = parseFloat(getComputedStyle(document.documentElement).fontSize);

    return {
      top: innerRectangle?.top + rem,
      left: innerRectangle?.left + (this.dir === 'ltr' ? rem : -rem),
      width: innerRectangle?.width,
    };
  }

  onChange!: (value: DropdownTreeNode<T>) => void;
  onTouched!: () => void;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly element: ElementRef<HTMLElement>,
    @Inject(LOCALE_ID) private readonly locale: string,
    @Optional() @Inject(NgControl) private readonly control?: NgControl,
  ) {
    this.dir = getLocaleDirection(this.locale);
  }

  ngOnInit() {
    this.cdr.detach();

    if (this.control) {
      this.control.valueAccessor = this;
      this.control.control?.valueChanges.subscribe((key: T | T[]) => {
        if (this.data) {
          this.init(key);
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.placeholder) {
      this.placeholderValue = changes.placeholder.currentValue as string;
      this.cdr.detectChanges();
    }

    if (this.control && this.data) {
      this.init(this.control.control.value as T);
    }
  }

  @HostListener('document:mousedown', ['$event'])
  private onClick(event: MouseEvent): void {
    if (!this.dropdownOverlay?.nativeElement.contains(event.target as Element)) {
      this.overlayDisplayed.set(false);
      this.cdr.detectChanges();
    }
  }

  @HostListener('document:keyup', ['$event'])
  private onKeyup(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.overlayDisplayed.set(false);
      this.cdr.detectChanges();
    }
  }

  onExpand(event: Event, node: DropdownTreeNode<T>): void {
    event.stopPropagation();
    this.setTreeExpandStatus(this.data, node.id, { toggle: true, toggleChildren: this.autoExpanded });
    this.cdr.detectChanges();
  }

  onSelect(node: DropdownTreeNode<T>): void {
    if (node.id === this.treeNodeValue?.id) {
      this.overlayDisplayed.set(false);
      this.cdr.detectChanges();

      return;
    }

    if (this.control) {
      this.control.control.setValue(node?.id);
    }

    this.writeValue(node);
    this.valueChange.emit(this.returnStrategy === 'key' ? node?.id : node);
    this.overlayDisplayed.set(false);
    this.cdr.detectChanges();
  }

  onClear(e: MouseEvent): void {
    if (this.disabled) {
      return;
    }

    e.stopPropagation();

    if (this.control) {
      this.control.control.setValue(null);
    }
    this.writeValue(null);
    this.valueChange.emit(null);
    this.cdr.detectChanges();
  }

  onToggleAll(checked: boolean) {
    this.data.forEach(node => this.onSelectMultiple(checked, node));
  }

  onSelectMultiple(checked: boolean, node: DropdownTreeNode<T>): void {
    this.selectNode(() => checked, node);

    const selectedNodes = this.getSelectedNodes(this.data);
    const selectedNodesIds = selectedNodes?.map(node => node.id);

    if (this.control) {
      this.control.valueAccessor = this;
      this.control.control.setValue(selectedNodesIds);
    }

    this.valueChange.emit(selectedNodesIds);
    this.selectedNodes = selectedNodes;
    this.cdr.detectChanges();
  }

  open(): void {
    if (this.treeNodeValue && this.selection === 'single') {
      this.setTreeExpandStatus(this.data, this.treeNodeValue.id, { toggle: false, toggleChildren: this.autoExpanded });
      this.searchInTree(this.data, null, this.treeNodeValue.id).expanded = false;
      this.scrollIntoView(this.treeNodeValue.id);
    }

    this.overlayDisplayed.set(true);
    this.suggestedNode = null;
    this.cdr.detectChanges();
  }

  onSearch(value: string): void {
    this.suggestedNode = null;

    if (value.length >= this.minSearchValue) {
      this.suggestedNode = this.searchInTree(this.data, value);
    }

    const key = this.suggestedNode
      ? this.suggestedNode.id
      : this.selection === 'single'
      ? this.treeNodeValue?.id
      : this.data[0].id;

    this.setTreeExpandStatus(this.data, key);
    this.cdr.detectChanges();
    this.scrollIntoView(key);
  }

  writeValue(value: DropdownTreeNode<T> | string[]): void {
    this.treeNodeValue = value as DropdownTreeNode<T>;

    if (Array.isArray(value) && this.data) {
      this.init(value as T[]);
    }
  }

  registerOnChange(fn: (value: DropdownTreeNode<T>) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  private init(key: T | T[]): void {
    switch (this.selection) {
      case 'single':
        this.initForOneSelection(key as T);
        break;
      case 'multiple':
        this.initForMultipleSelection(key as T[]);
        break;
    }

    this.cdr.detectChanges();
  }

  private initForOneSelection(key: T): void {
    this.writeValue(this.searchInTree(this.data, null, key));
    this.setTreeExpandStatus(this.data, key);
    this.setTreeSelectedStatus(this.data, key);

    if (this.displayStrategy === 'path') {
      this.path = this.findPath(this.data);
    }
  }

  private initForMultipleSelection(keys: T[]): void {
    this.data.forEach(node => {
      this.selectNode((id: T) => (keys ? keys.includes(id) : false), node);

      if (this.multipleSelectionStrategy === 'children') {
        this.setNodeChildrenSelectedState(node);
      }
    });

    this.selectedNodes = this.getSelectedNodes(this.data);
  }

  private findPath(tree: DropdownTreeNode<T>[], pathArr: DropdownTreeNode<T>[] = []): DropdownTreeNode<T>[] {
    return tree.reduce((accArr, node) => {
      if (node.selected) {
        accArr.push(node);
      }

      if (node.children?.length) {
        return this.findPath(node.children, accArr);
      }

      return accArr;
    }, pathArr);
  }

  private selectNode(selected: (id?: T) => boolean, node: DropdownTreeNode<T>) {
    node.selected = selected(node.id);

    node.children?.forEach(node => {
      this.selectNode(selected, node);
    });
  }

  private setTreeExpandStatus(
    tree: DropdownTreeNode<T>[],
    key: T,
    toggleOptions: { toggle: boolean; toggleChildren?: boolean } = { toggle: false, toggleChildren: true },
  ): void {
    tree.forEach(node => {
      node.expanded = this.isNodeExpanded(node, key, toggleOptions.toggle);

      if (node.expanded && node.children?.length) {
        if (toggleOptions.toggleChildren) {
          node.children.forEach(child => (child.expanded = true));
        } else {
          this.setTreeExpandStatus(node.children, key, toggleOptions);
        }
      }
    });
  }

  private setTreeSelectedStatus(tree: DropdownTreeNode<T>[], key: T): boolean {
    let found = false;

    for (const node of tree) {
      if (node.id === key) {
        node.selected = true;
        found = true;
        continue;
      }

      if (node.children?.length) {
        node.selected = this.setTreeSelectedStatus(node.children, key);
      } else {
        node.selected = false;
      }
    }

    return found;
  }

  private isNodeExpanded(node: DropdownTreeNode<T>, key: T, toggle?: boolean): boolean {
    if (node.id === key) {
      return toggle ? !node.expanded : true;
    } else {
      return node.children?.length ? this.isNodeContainValue(node.children, key) : false;
    }
  }

  private isNodeContainValue(children: DropdownTreeNode<T>[], key: T): boolean {
    return children.reduce((prev, curr) => {
      if (prev) {
        return prev;
      }

      return this.isNodeExpanded(curr, key);
    }, false);
  }

  private searchInTree(tree: DropdownTreeNode<T>[], value?: string, key?: T): DropdownTreeNode<T> {
    return tree?.reduce((prev, curr) => {
      if (prev) {
        return prev;
      }

      if (key && curr.id && curr.id.toString() === key.toString()) {
        return curr;
      }

      if (value && curr.name.toLowerCase().includes(value.toLowerCase())) {
        return curr;
      }

      return this.searchInTree(curr.children, value, key);
    }, null);
  }

  private getAllChildrenAsArray(node: DropdownTreeNode<T>): DropdownTreeNode<T>[] {
    if (!node.children?.length) {
      return [];
    }

    return [
      ...node.children,
      ...node.children
        .reduce((arr: DropdownTreeNode<T>[], currChild) => {
          if (!currChild.children) {
            return [];
          }

          arr.push(...this.getAllChildrenAsArray(currChild));

          return arr;
        }, [])
        .flat()
        .filter(Boolean),
    ];
  }

  private getSelectedNodes(tree: DropdownTreeNode<T>[]): DropdownTreeNode<T>[] {
    return this.convertTreeToArray(tree).reduce<DropdownTreeNode<T>[]>((acc, node) => {
      if (node.selected && !node.unselectable) {
        acc.push(node);
      }

      return acc;
    }, []);
  }

  private convertTreeToArray(tree: DropdownTreeNode<T>[]): DropdownTreeNode<T>[] {
    return tree
      .reduce((arr: DropdownTreeNode<T>[], node) => {
        arr.push(node, ...this.getAllChildrenAsArray(node));

        return arr;
      }, [])
      .flat();
  }

  private setNodeChildrenSelectedState(node: DropdownTreeNode<T>) {
    node.childrenSelected = this.getChildrenSelectionState(node);

    node.children?.forEach(node => {
      this.setNodeChildrenSelectedState(node);
    });
  }

  private getChildrenSelectionState(node: DropdownTreeNode<T>): 'all' | 'some' | 'none' {
    const childrenArray = this.getAllChildrenAsArray(node).filter(node => !node.unselectable);
    const selectedCount = childrenArray.reduce((count, child) => {
      if (child?.selected) {
        count++;
      }

      return count;
    }, 0);

    return childrenArray.length === selectedCount && (childrenArray.length > 0 || node.selected)
      ? 'all'
      : selectedCount > 0
      ? 'some'
      : 'none';
  }

  private scrollIntoView(key: T): void {
    setTimeout(
      () =>
        document
          .getElementById(`${this.nodeIdPrefix}:${key as unknown as string}`)
          ?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' }),
    );
  }
}
