import { from, Observable, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}

  canActivate(_route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Observable<boolean> {
    return this.authService.user$.pipe(
      switchMap(user => {
        if (user) {
          return of(true);
        }

        const returnUrl = state?.url;

        return from(
          this.router.navigateByUrl('/auth/login', {
            state: { returnUrl },
          }),
        );
      }),
    );
  }
}
