<ng-container *supyContext="{ topItems: topItems$ | async, bottomItems: bottomItems$ | async } as sideNavContext">
  <ng-container *ngIf="sideNavContext.topItems?.length || sideNavContext.bottomItems?.length">
    <div class="supy-side-nav">
      <button
        class="supy-side-nav__burger"
        name="burger-switch"
        (click)="selectedGroup && onHandle(null, reversedMenuState)"
      >
        <supy-icon class="supy-side-nav__icon" name="menu-burger" size="medium"></supy-icon>
      </button>

      <div class="supy-side-nav__items">
        <div class="supy-side-nav__items-top">
          <ng-container *ngFor="let item of sideNavContext.topItems">
            <ng-container [ngTemplateOutlet]="itemTmpl" [ngTemplateOutletContext]="{ item }"> </ng-container>
          </ng-container>
        </div>
        <div class="supy-side-nav__items-bottom">
          <ng-container *ngFor="let item of sideNavContext.bottomItems">
            <ng-container [ngTemplateOutlet]="itemTmpl" [ngTemplateOutletContext]="{ item }"> </ng-container>
          </ng-container>
          <p *ngIf="versionHash" class="supy-side-nav__version-hash">v. {{ versionHash }}</p>
        </div>
      </div>
    </div>

    <div *ngIf="subMenuState === 'out'" class="supy-side-nav-overlay" (click)="onHandle(null, 'in')"></div>

    <div class="supy-side-nav-sub" [ngClass]="['supy-side-nav-sub--' + subMenuState]">
      <supy-image class="supy-side-nav-sub__logo" src="/assets/images/supy-logo-wide.svg"></supy-image>

      <a
        *ngIf="!selectedGroup?.quickActionOff && (selectedGroup?.quickAction || selectedGroup?.quickActionUrl)"
        class="supy-side-nav-sub__quick-action"
        (click)="onSubActionClick(selectedGroup)"
        [name]="selectedGroup?.quickActionTitleAlt"
        [title]="selectedGroup?.quickActionTitleAlt"
        [routerLink]="selectedGroup?.quickActionUrl"
        [queryParams]="selectedGroup?.quickActionQueryParams"
        queryParamsHandling="merge"
      >
        <supy-icon
          [name]="
            selectedGroup?.underMaintenance ? 'exclamation-hex' : $any(selectedGroup?.quickActionIcon) ?? 'plus-flat'
          "
          [color]="selectedGroup?.underMaintenance ? 'warn' : null"
          size="small"
        ></supy-icon>
        {{ selectedGroup?.quickActionTitle }}
      </a>

      <div class="supy-side-nav-sub__item-wrapper">
        <ng-container *ngFor="let item of selectedGroup?.children">
          <a
            (click)="item.type === 'action' ? item.callback() : onHandle(null, 'in')"
            class="supy-side-nav-sub__item"
            [routerLink]="item.url"
            [class.active]="item.active"
            [name]="item.title"
            [title]="item.titleAlt ?? ''"
          >
            <supy-icon
              class="supy-side-nav__icon"
              *ngIf="item.icon"
              [name]="item.underMaintenance ? 'exclamation-hex' : $any(item.icon)"
              [color]="item.underMaintenance ? 'warn' : item.active ? 'primary' : null"
              size="medium"
            ></supy-icon>
            {{ item.title }}
          </a>

          <div *ngFor="let subItem of item?.children" class="supy-side-nav-sub__item-tree">
            <div class="supy-side-nav-sub__item-tree-line"></div>
            <a
              (click)="subItem.type === 'action' ? item.callback() : onHandle(null, 'in')"
              class="supy-side-nav-sub__item-tree-item"
              [routerLink]="subItem.url"
              [class.active]="subItem.active"
              [name]="subItem.title"
              [title]="subItem.titleAlt ?? ''"
            >
              {{ subItem.title }}
            </a>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #itemTmpl let-item="item">
  <a
    *ngIf="item.url"
    (click)="item.type === 'action' ? item.callback() : onHandle(null, 'in')"
    class="supy-side-nav__item"
    [routerLink]="item.url"
    [class.active]="item.active"
    [name]="item.title"
    [title]="item.titleAlt ?? ''"
  >
    <supy-icon
      class="supy-side-nav__icon"
      [name]="item.underMaintenance ? 'exclamation-hex' : item.active ? item.iconActive ?? item.icon : item.icon"
      [color]="item.underMaintenance ? 'warn' : item.active ? 'primary' : null"
      size="medium"
    ></supy-icon>
    {{ item.title }}
  </a>
  <button
    *ngIf="!item.url"
    (click)="item.type === 'action' ? item.callback() : this.onHandleAsync(item)"
    class="supy-side-nav__item"
    [class.active]="item.active"
    [class.highlighted]="selectedGroup && !item.active && item.key === selectedGroup.key"
    [name]="item.title"
    [title]="item.titleAlt ?? ''"
  >
    <supy-icon
      class="supy-side-nav__icon"
      [name]="item.underMaintenance ? 'exclamation-hex' : item.active ? item.iconActive ?? item.icon : item.icon"
      [color]="item.underMaintenance ? 'warn' : null"
      size="medium"
    ></supy-icon>
    {{ item.title }}
  </button>
</ng-template>
