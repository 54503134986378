import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IDialogCancellableEventArgs, IDialogEventArgs } from '@infragistics/igniteui-angular';

import { Destroyable } from '@supy/common';

import { ButtonModule } from '../../../button';
import { DialogComponent, DialogModule, DialogWidth, IDialogComponent } from '../../../dialog';
import { IconModule } from '../../../icon';

@Component({
  selector: 'supy-drawer',
  styleUrls: ['./drawer.component.scss'],
  templateUrl: './drawer.component.html',
  standalone: true,
  imports: [CommonModule, DialogModule, ButtonModule, IconModule],
})
export class DrawerComponent extends Destroyable implements IDialogComponent {
  @ViewChild(DialogComponent, { static: true }) private readonly dialog: DialogComponent;

  @Input() readonly width: DialogWidth = '70vw';
  @Input() readonly showPagination: boolean = true;
  @Input() readonly previousDisabled: boolean;
  @Input() readonly nextDisabled: boolean;
  @Input() readonly closeOnOutsideClick: boolean = false;

  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();
  @Output() readonly navigationClicked = new EventEmitter<boolean>();

  openDialog(): void {
    this.dialog?.openDialog();
  }

  closeDialog(): void {
    this.dialog?.closeDialog();
  }
}
