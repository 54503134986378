<div class="supy-snackbar">
  <igx-snackbar
    #snackbar
    [autoHide]="autoHide"
    [displayTime]="displayMilliseconds"
    [positionSettings]="snackbar.positionSettings"
    [class]="'supy-snackbar--' + variant"
    [actionText]="actionText"
    (clicked)="close()"
  >
    <p *ngIf="title" class="supy-snackbar-title" [innerHTML]="title"></p>
    <ng-content></ng-content>
  </igx-snackbar>
</div>
<button class="supy-snackbar__hidden-button" #button (click)="snackbar.open()"></button>
