import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

export interface HeaderWithIconParams {
  readonly header: string;
  readonly tooltip: string;
}

@Component({
  selector: 'supy-header-with-icon',
  template: `
    <div class="ag-header-cell-comp-wrapper">
      <span class="ag-header-cell-text">{{ params.header }}&nbsp;</span>
      <supy-icon name="info" size="small" [supyTooltip]="modifierTooltip" position="top"></supy-icon>
      <supy-tooltip #modifierTooltip>
        {{ params.tooltip }}
      </supy-tooltip>
    </div>
  `,
})
export class HeaderWithIconComponent implements IHeaderAngularComp {
  protected params: IHeaderParams & HeaderWithIconParams;

  agInit(params: IHeaderParams & HeaderWithIconParams): void {
    this.params = params;
  }

  refresh(_: IHeaderParams): boolean {
    return false;
  }
}
