<div class="supy-donut-chart">
  <div class="supy-donut-chart--relative" [ngStyle]="{ width: width + '%', height: height + '%' }">
    <div class="supy-donut-chart--overlay">
      <igx-doughnut-chart
        [allowSliceSelection]="allowSliceSelection"
        (sliceClick)="onSliceClick($event)"
        [innerExtent]="innerExtent"
        [height]="height + '%'"
        [width]="width + '%'"
        name="chart"
        #chart
      >
        <igx-ring-series
          [dataSource]="data"
          [labelExtent]="labelExtent"
          [labelsPosition]="labelsPosition"
          [outlines]="outlines"
          [radiusFactor]="radiusFactor"
          [startAngle]="startAngle"
          [brushes]="brushes"
          valueMemberPath="value"
          labelMemberPath="summary"
          legendLabelMemberPath="category"
          #series
          name="series"
        >
        </igx-ring-series>
      </igx-doughnut-chart>
      <div
        *ngIf="allowSliceSelection"
        class="supy-donut-chart--selection"
        [ngStyle]="{ top: height / 2 + '%', left: width / 2 + '%' }"
      >
        <ng-content select="[sliceSelectionTemplate]"></ng-content>
      </div>
    </div>
  </div>

  <igx-item-legend [orientation]="orientation" name="Legend" #legend></igx-item-legend>
</div>
