<ng-container *ngIf="dropdownRef">
  <span
    class="supy-dropdown-toggle"
    [igxToggleAction]="dropdownRef.dropdown"
    [igxToggleOutlet]="elementRef || outlet"
    [overlaySettings]="overlaySettings"
    [attr.data-dropdown-id]="dropdownRef.dropdown.id"
  >
    <ng-content></ng-content>
  </span>
  <div igxOverlayOutlet #outlet="overlay-outlet"></div>
</ng-container>
