import { Currency } from '@supy.api/dictionaries';

import { LoginType } from '@supy/auth';
import { PreferredType } from '@supy/common';

import { BaseMixpanelEvent, TrackMessageSend } from './mixpanel-events.interface';

export class MixpanelEvent implements BaseMixpanelEvent {
  static readonly eventName?: string;
  readonly eventName?: string;
  readonly success: boolean;

  constructor(event: Partial<MixpanelEvent>) {
    this.success = event.success ?? false;
    this.eventName = MixpanelEvent.eventName;
  }
}

export class UserCreation extends MixpanelEvent {
  static readonly eventName = 'user_creation';
  readonly eventName?: string;
  readonly id?: string;
  readonly $firstname: string;
  readonly $lastname: string;
  readonly photo: boolean;
  readonly $phonenumber: string;
  readonly role: string;
  // TODO how to define userType properly?
  readonly userType?: string;

  constructor(event: Partial<UserCreation>) {
    super(event);
    this.$firstname = event.$firstname ?? null;
    this.$lastname = event.$lastname ?? null;
    this.photo = event.photo ?? null;
    this.$phonenumber = event.$phonenumber ?? null;
    this.role = event.role ?? null;
    this.userType = event.userType ?? null;
    this.eventName = UserCreation.eventName;
  }
}

export class AddProduct extends MixpanelEvent {
  static readonly eventName: string = 'add_product_to_retailer_catalog';
  readonly eventName?: string;
  readonly branchName: string[];
  readonly restaurantName: string[];
  readonly supplierName: string;
  readonly selfCompletion: boolean;
  readonly bulk?: boolean;
  readonly itemsAmount?: number;

  constructor(event: Partial<AddProduct>) {
    super(event);
    this.branchName = event.branchName ?? null;
    this.restaurantName = event.restaurantName ?? null;
    this.supplierName = event.supplierName ?? null;
    this.selfCompletion = event.selfCompletion ?? null;
    this.eventName = AddProduct.eventName;
    this.bulk = event.bulk ?? false;
    this.itemsAmount = event.itemsAmount ?? 1;
  }
}

export class EditProduct extends AddProduct {
  static readonly eventName = 'edit_product_in_retailer_catalog';
  readonly eventName?: string;
  readonly productNameChangedEn: boolean;
  readonly productNameChangedAr: boolean;
  readonly uomChanged: boolean;
  readonly priceChanged: boolean;
  readonly itemCodeChanged: boolean;
  readonly originalPrice: number;
  readonly newPrice: number;
  readonly priceDifference: number;

  constructor(event: Partial<EditProduct>) {
    super(event);
    this.productNameChangedEn = event.productNameChangedEn ?? null;
    this.productNameChangedAr = event.productNameChangedAr ?? null;
    this.uomChanged = event.uomChanged ?? null;
    this.priceChanged = event.priceChanged ?? null;
    this.itemCodeChanged = event.itemCodeChanged ?? null;
    this.originalPrice = event.originalPrice ?? null;
    this.newPrice = event.newPrice ?? null;
    this.priceDifference = event.priceDifference ?? null;
    this.eventName = EditProduct.eventName;
  }
}

export class Login extends MixpanelEvent {
  static readonly eventName = 'login';
  readonly eventName?: string;
  readonly lastLoginDate?: Date | string;
  readonly firstTime: boolean;
  readonly loginMethod: LoginType;

  constructor(event: Partial<Login>) {
    super(event);
    this.lastLoginDate = event.lastLoginDate ?? null;
    this.firstTime = event.firstTime ?? null;
    this.loginMethod = event.loginMethod ?? null;
    this.eventName = Login.eventName;
  }
}

export class PageVisit extends MixpanelEvent {
  static readonly eventName: string = 'page_visit';
  readonly eventName?: string;
  readonly restaurantName: string[];
  readonly pageName: string;

  constructor(event: Partial<PageVisit>) {
    super(event);
    this.restaurantName = event.restaurantName ?? null;
    this.pageName = event.pageName ?? null;
    this.eventName = PageVisit.eventName;
  }
}

export class PageVisitStart extends PageVisit {
  static readonly eventName = 'page_visit_start';
  readonly eventName?: string;

  constructor(event: Partial<PageVisitStart>) {
    super(event);
    this.eventName = PageVisitStart.eventName;
  }
}

export class ChannelVisit extends MixpanelEvent {
  static readonly eventName = 'channel_visit';
  readonly eventName?: string;
  readonly restaurantName: string;
  readonly branchName: string;
  readonly supplierName: string;
  readonly pageName: string;

  constructor(event: Partial<ChannelVisit>) {
    super(event);
    this.restaurantName = event.restaurantName ?? null;
    this.branchName = event.branchName ?? null;
    this.supplierName = event.supplierName ?? null;
    this.pageName = event.pageName ?? null;
    this.eventName = ChannelVisit.eventName;
  }
}

export class SendMessage extends MixpanelEvent implements TrackMessageSend {
  static readonly eventName = 'send_message';
  readonly eventName?: string;
  readonly restaurantName: string;
  readonly branchName: string;
  readonly supplierName: string;
  readonly message: string;
  readonly messageLength: number;

  constructor(event: Partial<SendMessage>) {
    super(event);
    this.restaurantName = event.restaurantName ?? null;
    this.branchName = event.branchName ?? null;
    this.supplierName = event.supplierName ?? null;
    this.message = event.message ?? null;
    this.messageLength = event.messageLength ?? null;
    this.eventName = SendMessage.eventName;
  }
}

abstract class BasicOrder extends MixpanelEvent {
  readonly restaurantName: string;
  readonly branchName: string;
  readonly supplierName: string;
  readonly numberOfUniqueProducts: number;
  readonly uniqueProducts: string[];
  readonly productCategories?: string[];
  readonly currency: Currency;
  readonly orderId: string;

  protected constructor(event: Partial<BasicOrder>) {
    super(event);
    this.restaurantName = event.restaurantName ?? null;
    this.branchName = event.branchName ?? null;
    this.supplierName = event.supplierName ?? null;
    this.numberOfUniqueProducts = event.numberOfUniqueProducts ?? null;
    this.uniqueProducts = event.uniqueProducts ?? null;
    this.productCategories = event.productCategories ?? null;
    this.currency = event.currency ?? null;
    this.orderId = event.orderId ?? null;
  }
}

export class CreateDraftOrder extends BasicOrder {
  static readonly eventName = 'create_draft_order';
  readonly eventName?: string;
  readonly gmv: number;
  readonly addedSupplierNote: boolean;
  readonly customDeliveryDate: boolean;

  constructor(event: Partial<CreateDraftOrder>) {
    super(event);
    this.gmv = event.gmv ?? null;
    this.addedSupplierNote = event.addedSupplierNote ?? null;
    this.customDeliveryDate = event.customDeliveryDate ?? null;
    this.eventName = CreateDraftOrder.eventName;
  }
}

export class SubmitOrder extends BasicOrder {
  static readonly eventName = 'submit_order';
  readonly eventName?: string;
  readonly gmvOrdering: number;
  readonly addedSupplierNote: boolean;
  readonly customDeliveryDate: boolean;
  readonly repeatOrder: boolean;
  readonly draftOrder: boolean;

  constructor(event: Partial<SubmitOrder>) {
    super(event);
    this.gmvOrdering = event.gmvOrdering ?? null;
    this.addedSupplierNote = event.addedSupplierNote ?? null;
    this.customDeliveryDate = event.customDeliveryDate ?? null;
    this.repeatOrder = event.repeatOrder ?? null;
    this.draftOrder = event.draftOrder ?? null;
    this.eventName = SubmitOrder.eventName;
  }
}

export class ConfirmOrder extends BasicOrder {
  static readonly eventName: string = 'confirm_order';
  readonly eventName?: string;
  readonly gmvOrdering: number;
  readonly gmvConfirming: number;
  readonly qtyChangedSupplier: number;
  readonly priceChangedSupplier: number;
  readonly outOfStocksSupplier: number;

  constructor(event: Partial<ConfirmOrder>) {
    super(event);
    this.gmvOrdering = event.gmvOrdering ?? null;
    this.gmvConfirming = event.gmvConfirming ?? null;
    this.qtyChangedSupplier = event.qtyChangedSupplier ?? null;
    this.priceChangedSupplier = event.priceChangedSupplier ?? null;
    this.outOfStocksSupplier = event.outOfStocksSupplier ?? null;
    this.eventName = ConfirmOrder.eventName;
  }
}

export class ReceiveOrder extends ConfirmOrder {
  static readonly eventName = 'receive_order';
  readonly eventName?: string;
  readonly gmvReceiving: number;
  readonly qtyChangedRetailer: number;
  readonly productAddedRetailer: number;
  readonly priceChangedRetailer: number;
  readonly orderInvoices: number;
  readonly invoiceNumbers: number;
  readonly onDeliveryDate: boolean;

  constructor(event: Partial<ReceiveOrder>) {
    super(event);
    this.gmvReceiving = event.gmvReceiving ?? null;
    this.qtyChangedRetailer = event.qtyChangedRetailer ?? null;
    this.productAddedRetailer = event.productAddedRetailer ?? null;
    this.priceChangedRetailer = event.priceChangedRetailer ?? null;
    this.orderInvoices = event.orderInvoices ?? null;
    this.invoiceNumbers = event.invoiceNumbers ?? null;
    this.onDeliveryDate = event.onDeliveryDate ?? null;
    this.eventName = ReceiveOrder.eventName;
  }
}

export class RejectOrder extends BasicOrder {
  static readonly eventName = 'reject_order';
  readonly eventName?: string;
  readonly gmvOrdering: number;

  constructor(event: Partial<RejectOrder>) {
    super(event);
    this.gmvOrdering = event.gmvOrdering ?? null;
    this.eventName = RejectOrder.eventName;
  }
}

export class CreatePreferredChannel extends MixpanelEvent {
  static readonly eventName = 'create_preferred_channel';
  readonly eventName?: string;
  readonly supplierName: string;
  readonly preferredType: PreferredType;
  readonly restaurantName: string;
  readonly numberOfBranches: number;
  readonly numberOfAssignedProducts: number;

  constructor(event: Partial<CreatePreferredChannel>) {
    super(event);
    this.eventName = CreatePreferredChannel.eventName;
    this.supplierName = event.supplierName;
    this.preferredType = event.preferredType;
    this.restaurantName = event.restaurantName;
    this.numberOfBranches = event.numberOfBranches;
    this.numberOfAssignedProducts = event.numberOfAssignedProducts;
  }
}

export class RequestNewSupplier extends MixpanelEvent {
  static readonly eventName = 'request_new_supplier';
  readonly eventName?: string;
  readonly restaurantName: string[];
  readonly supplierName: string;
  readonly supplierAccountManagers: number;

  constructor(event: Partial<RequestNewSupplier>) {
    super(event);
    this.eventName = RequestNewSupplier.eventName;
    this.supplierName = event.supplierName;
    this.supplierAccountManagers = event.supplierAccountManagers;
    this.restaurantName = event.restaurantName;
  }
}

export class ExpandOrder extends MixpanelEvent {
  static readonly eventName = 'expand_order';
  readonly eventName?: string;
  readonly restaurantName: string;
  readonly orderId: string;

  constructor(event: Partial<ExpandOrder>) {
    super({ ...event, success: true });
    this.eventName = ExpandOrder.eventName;
    this.orderId = event.orderId;
    this.restaurantName = event.restaurantName;
  }
}

export class DiscardDraftOrder extends MixpanelEvent {
  static readonly eventName = 'discard_draft_order';
  readonly eventName?: string;
  readonly restaurantName: string;
  readonly branchName: string;
  readonly supplierName: string;
  readonly numberOfUniqueProducts: number;
  readonly uniqueProducts: string[];
  readonly orderId: string;
  readonly gmv: number;

  constructor(event: Partial<DiscardDraftOrder>) {
    super(event);
    this.restaurantName = event.restaurantName ?? null;
    this.branchName = event.branchName ?? null;
    this.supplierName = event.supplierName ?? null;
    this.numberOfUniqueProducts = event.numberOfUniqueProducts ?? null;
    this.uniqueProducts = event.uniqueProducts ?? null;
    this.gmv = event.gmv ?? null;
    this.orderId = event.orderId ?? null;
    this.eventName = DiscardDraftOrder.eventName;
  }
}

export class ApplyOrderLogFilter extends MixpanelEvent {
  static readonly eventName = 'apply_filter';
  readonly eventName?: string;
  readonly filterType? = 'General' as const; // We've got 'IntraChannel' and 'InterChannel' types for mobile app
  readonly orderType: 'Category Order' | 'Regular Order' | 'GRN';
  readonly branchName?: string[];
  readonly statuses: string[];
  readonly date: boolean;

  constructor(event: Partial<ApplyOrderLogFilter>) {
    super({ ...event, success: true });
    this.orderType = event.orderType ?? null;
    this.branchName = event.branchName ?? null;
    this.statuses = event.statuses ?? null;
    this.date = event.date ?? false;
    this.eventName = ApplyOrderLogFilter.eventName;
  }
}

export class SupplierItemSearch extends MixpanelEvent {
  static readonly eventName: string = 'supplier_item_search';
  readonly eventName?: string;
  readonly totalItems: number;

  constructor(event: Partial<SupplierItemSearch>) {
    super({ ...event, success: true });
    this.totalItems = event.totalItems ?? null;
    this.eventName = SupplierItemSearch.eventName;
  }
}

export class MatchmakingEvent extends SupplierItemSearch {
  static readonly eventName = 'matchmaking';
  readonly eventName?: string;
  readonly itemsUploaded: number;

  constructor(event: Partial<MatchmakingEvent>) {
    super(event);
    this.itemsUploaded = event.itemsUploaded ?? null;
    this.eventName = MatchmakingEvent.eventName;
  }
}
