import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[supyBackupImage]',
})
export class BackupImageDirective {
  @Input() readonly backupImage: string;

  constructor(private readonly el: ElementRef<HTMLInputElement>) {}

  @HostListener('error')
  private onError() {
    this.el.nativeElement.src = this.backupImage;
  }
}
