import { EventEmitter, signal } from '@angular/core';

import { Writable } from '@supy/core';

import { DEFAULT_DATE_RANGE_DISPLAY_MONTHS_COUNT } from '../../../date-range';
import { BaseFilterChange, FilterConfig, SearchConfig, SelectFilterConfig, SwitchConfig } from './filter-group.model';

export class FilterGroup<T extends BaseFilterChange> {
  readonly config: FilterConfig;
  readonly change$ = new EventEmitter<T>();
  readonly change = signal<T>({} as T);
  readonly reset$ = new EventEmitter<void>();

  get selectionProperties(): SelectFilterConfig[] {
    return this.#selectionProperties;
  }

  get switchProperties(): SwitchConfig[] {
    return this.#switchProperties;
  }

  get searchConfig(): SearchConfig {
    return this.#searchConfig;
  }

  #selectionProperties: SelectFilterConfig[] = [];
  #switchProperties: SwitchConfig[] = [];
  #searchConfig: SearchConfig;

  protected onUpdateProperty: (...args) => void = () => null;
  protected onGetSnapshot: () => T = () => ({}) as T;

  //TODO: (Dima/Ali 29.12.2023) something to consider is to pass the filters config as an array
  // instead of { search, dropdowns, switches } so that we can have more combination possibles
  constructor(config: FilterConfig) {
    this.config = config;

    this.setUpProperties(config);
  }

  getSelectionProperty(name: keyof T): SelectFilterConfig | null {
    return this.selectionProperties.find(property => property.name === name);
  }

  getSwitchProperty(name: keyof T): SwitchConfig | null {
    return this.switchProperties.find(property => property.name === name);
  }

  updateSearchProperty(options: Partial<SearchConfig>): void {
    const property = this.searchConfig;

    if (property) {
      for (const key in options) {
        (property[key] as SearchConfig[keyof SearchConfig]) = options[key] as SearchConfig[keyof SearchConfig];
      }
      this.onUpdateProperty(property);
    } else {
      this.#searchConfig = { ...options };
    }
  }

  updateSelectionProperty(name: keyof T, options: Partial<SelectFilterConfig>): void {
    const property = this.getSelectionProperty(name) as Writable<SelectFilterConfig>;

    if (property) {
      for (const key in options) {
        (property[key] as SelectFilterConfig[keyof SelectFilterConfig]) = options[key];
      }
      this.onUpdateProperty(property);
    } else {
      this.addSelectionProperty({ ...options, name } as SelectFilterConfig);
    }
  }

  updateSwitchProperty(name: keyof T, options: Partial<SwitchConfig>): void {
    const property = this.getSwitchProperty(name) as Writable<SwitchConfig>;

    if (property) {
      for (const key in options) {
        (property[key] as SwitchConfig[keyof SwitchConfig]) = options[key] as SwitchConfig[keyof SwitchConfig];
      }
      this.onUpdateProperty(property);
    } else {
      this.addSwitchProperty({ ...options, name } as SwitchConfig);
    }
  }

  addSelectionProperty(property: SelectFilterConfig): void {
    const updatedProperty = {
      ...property,
      clearable: property.clearable ?? true,
      displayMonthsCount: property.displayMonthsCount ?? DEFAULT_DATE_RANGE_DISPLAY_MONTHS_COUNT,
    };

    this.#selectionProperties.push(updatedProperty);
    this.onUpdateProperty(updatedProperty);
  }

  addSwitchProperty(property: SwitchConfig): void {
    const updatedProperty = { ...property, clearable: property.clearable ?? true };

    this.#switchProperties.push(updatedProperty);
    this.onUpdateProperty(updatedProperty);
  }

  registerOnUpdateProperty(fn: (...args) => void): void {
    this.onUpdateProperty = fn;
  }

  registerOnGetSnapshot(fn: () => T): void {
    this.onGetSnapshot = fn;
  }

  getSnapshot(): T {
    return this.onGetSnapshot();
  }

  private setUpProperties(config: FilterConfig): void {
    if (config.search) {
      this.#searchConfig = config.search;
    }

    this.#selectionProperties = [];
    this.#switchProperties = [];

    (config?.dropdowns || []).forEach(property => {
      const updatedProperty = {
        ...property,
        clearable: property.clearable ?? true,
        displayMonthsCount: property.displayMonthsCount ?? DEFAULT_DATE_RANGE_DISPLAY_MONTHS_COUNT,
      };

      this.#selectionProperties.push(updatedProperty);
    });

    (config?.switches || []).forEach(property => {
      const updatedProperty = { ...property, clearable: property.clearable ?? true };

      this.#switchProperties.push(updatedProperty);
    });
  }
}
