import { first } from 'rxjs';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { ICellEditorParams } from '@ag-grid-community/core';
import { Component, ViewChild } from '@angular/core';

import { CounterInputComponent } from '../../../counter-input';

export interface InputCounterCellEditorContext {
  readonly disabled?: boolean;
  readonly min?: number;
  readonly max?: number;
  readonly step?: number;
  readonly precision?: number;
}
@Component({
  selector: 'supy-input-counter-cell-editor',
  template: `
    <div class="supy-input-counter-cell-editor__cell">
      <supy-counter-input
        #focusableComponent
        [value]="getValue()"
        [min]="context?.min"
        [step]="context?.step"
        [max]="context?.max"
        [disabled]="context?.disabled"
        (valueChange)="valueChangeHandler($event)"
        [textSelection]="true"
        [precision]="context?.precision"
      ></supy-counter-input>
    </div>
  `,
  styleUrls: ['./input-counter-cell-editor.component.scss'],
})
export class InputCounterCellEditorComponent implements ICellEditorAngularComp {
  @ViewChild('focusableComponent') private readonly focusableComponent: CounterInputComponent;

  protected value: number;
  protected params: ICellEditorParams<unknown, number, InputCounterCellEditorContext>;
  protected context: InputCounterCellEditorContext;

  agInit(params: ICellEditorParams<unknown, number, InputCounterCellEditorContext>): void {
    this.params = params;
    this.context = params.context;
    this.value = params.value ?? 0;
  }

  getValue(): number {
    return this.value;
  }

  refresh(): boolean {
    return true;
  }

  afterGuiAttached(): void {
    this.onStartEditing();
  }

  protected onStartEditing(): void {
    if (this.focusableComponent) {
      this.focusableComponent.focus();

      this.focusableComponent.input.focusOut.pipe(first()).subscribe(() => {
        this.params.stopEditing();
      });
    }
  }

  protected valueChangeHandler(value: number): void {
    this.value = value;
  }
}
