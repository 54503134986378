import { BehaviorSubject, debounceTime, filter, first, Subject, takeUntil } from 'rxjs';
import { useAnimation } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import {
  DisplayDensity,
  IComboSearchInputEventArgs,
  IComboSelectionChangingEventArgs,
  IGX_INPUT_GROUP_TYPE,
  IgxComboComponent,
  IgxInputGroupType,
  OverlaySettings,
  swingInTopBck,
  swingOutTopBck,
} from '@infragistics/igniteui-angular';
import { IBaseCancelableBrowserEventArgs } from '@infragistics/igniteui-angular/lib/core/utils';
import { ActionsExecuting } from '@ngxs-labs/actions-executing';

import { Destroyable } from '@supy/common';

import { MenuPosition, menuPositionSettings } from '../../../dropdown';
import { densityMap, InputDensity } from '../../../input';
import { ComboEmptyDirective, ComboFooterDirective, ComboItemDirective } from './combo-box.directives';
import { ComboboxPositionStrategy } from './position.strategy';

@Component({
  selector: 'supy-combo-box',
  templateUrl: './combo-box.component.html',
  styleUrls: ['./combo-box.component.scss'],
  providers: [{ provide: IGX_INPUT_GROUP_TYPE, useValue: 'border' }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComboboxComponent<T> extends Destroyable implements ControlValueAccessor, OnInit, AfterViewInit {
  private static readonly VALUE_UPDATE_DEBOUNCE_TIME = 100;
  @ViewChild(IgxComboComponent)
  readonly comboBox: IgxComboComponent;

  @ContentChild(ComboEmptyDirective)
  readonly emptyDirective: ComboEmptyDirective;

  @ContentChild(ComboFooterDirective)
  readonly footerDirective: ComboFooterDirective;

  @ContentChild(ComboItemDirective)
  readonly itemDirective: ComboItemDirective;

  @Input()
  readonly overlaySettings: OverlaySettings = { outlet: this.elem };

  @Input() @HostBinding('attr.name') readonly name: string;

  // Note: when you calculate item height don't forget to include margins as well or virtual scroll could stop working
  @Input() readonly itemHeight: number = 72;

  @Input() readonly enableMarqueeEffect: boolean = true;

  @Input() readonly latency: number = 500;

  @Input() readonly smallSize: boolean = false;

  private _disabledValues: T[] = [];
  @Input()
  set disabledValues(values: T[] | undefined) {
    if (!values || values.length === 0) {
      this._disabledValues = [];
      this.disabledSet = undefined;
    } else {
      this._disabledValues = values.filter((item): item is T => item !== undefined);
      this.disabledSet = new Set<T[keyof T]>(
        this._disabledValues
          .filter((item): item is T => item !== null && typeof item === 'object' && this.valueKey in item)
          .map(item => item[this.valueKey as keyof T]),
      );
    }
  }

  get disabledValues(): T[] {
    return this._disabledValues;
  }

  disabledSet?: Set<T[keyof T]>;

  @Input() readonly valueKey?: string;

  @Input() set list(values: T[]) {
    if (values) {
      this._list = [...values];

      if (values?.length) {
        this.listLoaded$.next(true);
      }
    }
  }

  get list(): T[] {
    return this._list;
  }

  @Input() readonly type: IgxInputGroupType = 'border';

  @Input() set value(value: string[]) {
    if (value) {
      this._value = value;

      this.comboboxValueSelect$.next();
    }
  }

  get value(): string[] {
    return this._value;
  }

  @Input() readonly titleKey: string;
  @Input() readonly displayKey: string;
  @Input() readonly subtitleKey: string;
  @Input() readonly iconKey?: string;
  @Input() readonly photoKey: string = '';
  @Input() readonly pipeKey: string;
  @Input() readonly filterable: boolean = true;
  @Input() readonly showAvatar: boolean = true;
  @Input() readonly localSearch: boolean = false;
  @Input() readonly searchPlaceholder: string = 'Search...';
  @Input() readonly selectAllText: string = $localize`:@@common.actions.selectAll:Select All`;
  @Input() readonly placeholder: string = $localize`:@@common.actions.choose:Choose`;
  @Input() readonly comboBoxMaxHeight: number = 300;
  @Input() readonly position: MenuPosition = 'top';
  @Input() readonly withItemMargins: boolean = true;
  @Input() readonly multiple: boolean = false;
  @Input() readonly isLoading: boolean | ActionsExecuting = false;
  @Input() disabled: boolean;
  @Input() readonly allowCustomValues: boolean = false;
  @Input() readonly itemWidth: string;
  @Input() set density(value: InputDensity) {
    this.displayDensity = densityMap[value] ?? 'compact';
  }

  @Input() readonly selectAllAllowed: boolean = true;

  @Input() readonly variant: 'border' | 'flat' = 'border';
  @Input() readonly clearable: boolean = true;

  @Output() readonly searchInputUpdated = new EventEmitter<string>();
  @Output() readonly closed = new EventEmitter<string[]>();
  @Output() readonly opened = new EventEmitter<string[]>();
  @Output() readonly selectionChanging = new EventEmitter<IComboSelectionChangingEventArgs>();
  @Output() readonly changed = new EventEmitter<string[]>();
  @Output() readonly cleared = new EventEmitter<T>();

  protected displayDensity: DisplayDensity = 'compact';

  private _value: string[];
  private _list: T[];
  private readonly listLoaded$ = new BehaviorSubject<boolean>(false);
  private readonly debouncer$ = new Subject<string>();
  private readonly comboboxValueSelect$ = new Subject<void>();

  showCombo = true;
  selectedAll = false;
  selectedAllClicked: boolean;

  onChange: (value: string[]) => void;

  onTouched: () => void;

  touched = false;

  @HostBinding('class.supy-combo-box--no-item-margins')
  get noItemMargins() {
    return !this.withItemMargins;
  }

  get isOpen(): boolean {
    return !this.comboBox?.collapsed;
  }

  constructor(
    public elem: ElementRef,
    private readonly cdr: ChangeDetectorRef,
    private readonly hostElement: ElementRef<HTMLElement>,
    @Inject(DOCUMENT) private readonly document: Document,
    @Optional()
    @Inject(NgControl)
    private readonly control?: NgControl,
  ) {
    super();

    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    const settings = menuPositionSettings[this.position];
    const positionStrategy = new ComboboxPositionStrategy({
      ...settings,
      openAnimation: useAnimation(swingInTopBck, { params: { duration: '500ms' } }),
      closeAnimation: useAnimation(swingOutTopBck, { params: { duration: '250ms' } }),
    });

    this.overlaySettings.positionStrategy = positionStrategy;

    setTimeout(() => {
      this.cdr.markForCheck();
    });

    this.debouncer$.pipe(debounceTime(this.latency), takeUntil(this.destroyed$)).subscribe(v => {
      this.searchInputUpdated.emit(v);
    });

    this.listenForValueSelect();
  }

  ngAfterViewInit(): void {
    this.selectValues();
  }

  clearComboBox(): void {
    this.comboBox.comboInput.clear();
  }

  onSelectAll(checked: boolean): void {
    this.selectedAll = checked;
    this.selectedAllClicked = true;

    if (this.valueKey && this.disabledSet) {
      const filteredItems: T[keyof T][] = this.list.reduce(
        (acc, item) => {
          if (!this.disabledSet.has(item[this.valueKey] as T[keyof T])) {
            acc.push(item[this.valueKey] as T[keyof T]);
          }

          return acc;
        },
        [] as T[keyof T][],
      );

      if (this.selectedAll) {
        this.comboBox.select(filteredItems);
      } else {
        this.comboBox.deselect(filteredItems);
      }
    } else {
      if (this.selectedAll) {
        this.comboBox.selectAllItems();
        this.cdr.detectChanges();

        return;
      }

      this.comboBox.deselectAllItems();
    }

    this.cdr.detectChanges();
  }

  open() {
    this.comboBox.open();
  }

  onOpening(event: IBaseCancelableBrowserEventArgs): void {
    if (this.disabled) {
      event.cancel = true;

      return;
    }

    this.showCombo = false;

    const { nativeElement } = this.hostElement;

    const overlayWrapper = nativeElement.querySelector('.igx-overlay__wrapper');
    const inputGroupBundle = overlayWrapper
      ? overlayWrapper.querySelector('.igx-input-group__bundle')
      : this.document.querySelector('.igx-overlay__wrapper .igx-combo__search');
    const listContainer = overlayWrapper
      ? overlayWrapper.querySelector('.igx-combo__content')
      : this.document.querySelector('.igx-overlay__wrapper .igx-combo__content');

    if (!this.multiple) {
      listContainer?.classList.add('igx-combo__no-checkboxes');
    }

    inputGroupBundle?.classList.add('igx-input-group__search-icon');

    this.opened.emit(this._value);
  }

  onClosing(event: IBaseCancelableBrowserEventArgs): void {
    if (this.disabled) {
      event.cancel = true;

      return;
    }

    this.showCombo = true;
    this.selectedAllClicked = false;
    this.closed.emit(this._value);

    if (this.touched) {
      return;
    }
    this.onTouched?.();
    this.emitTouchStatusChanged();
  }

  onSearchInputUpdate(event: IComboSearchInputEventArgs): void {
    if (!this.localSearch) {
      event.cancel = true;
    }
    this.debouncer$.next(event.searchText);
  }

  onSelectionChanging(event: IComboSelectionChangingEventArgs): void {
    if (!this.multiple && event.removed.length && this.clearable) {
      event.cancel = true;
      this.cleared.emit(event.removed as T);
      this.value = [];

      return;
    }

    if (!this.selectAllAllowed && event.newSelection.length === 0 && event.removed.length > 0) {
      event.cancel = true;

      return;
    }

    if (this.valueKey && this.disabledSet) {
      const addedDisabled = event.added.some((item: T[keyof T]) => this.disabledSet.has(item));
      const removedDisabled = event.removed.some((item: T[keyof T]) => this.disabledSet.has(item));

      if (event.removed.length && event.newSelection.length === 0 && !event.added.length && !event.cancel) {
        const remainingItems = event.oldSelection.filter((item: T[keyof T]) => this.disabledSet.has(item));

        event.newSelection = remainingItems;
        event.cancel = false;

        this._value = event.newSelection as string[];
        this.selectedAll = event.oldSelection.length - event.removed.length === this.list.length;

        this.onChange?.(event.newSelection as string[]);

        if (event.event || this.selectedAllClicked) {
          this.changed.emit(event.newSelection as string[]);
        }

        setTimeout(() => {
          this.cdr.detectChanges();
        }, 0);

        return;
      }

      if ((addedDisabled || removedDisabled) && !event.cancel) {
        event.cancel = true;

        return;
      }
    }

    if (!this.multiple && event.added.length) {
      event.newSelection = event.added;
      this.comboBox.close();
    }

    this._value = event.newSelection as string[];
    this.selectedAll = event.oldSelection.length + event.added.length - event.removed.length === this.list.length;

    this.onChange?.(event.newSelection as string[]);

    if (event.event || this.selectedAllClicked) {
      this.changed.emit(event.newSelection as string[]);
    }

    setTimeout(() => {
      this.cdr.detectChanges();
    }, 0);
  }

  writeValue(event: string[]): void {
    const value = Array.isArray(event) ? event : [event].filter(Boolean);
    const same = this._value?.length === value?.length && value?.every((item, index) => item === this._value[index]);

    this.selectedAll = value?.length === this.list?.length;

    if (!value?.length && this.comboBox?.value) {
      this.comboBox?.deselectAllItems();
    }

    if ((!value?.length && !this._value?.length) || same) {
      // removes loop in the grid - it's trying to set same value all the time in edit row mode
      return;
    }

    this._value = value;
    this.cdr.detectChanges();
  }

  registerOnChange(onChange: (value: string[]) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    this.cdr.markForCheck();
  }

  private emitTouchStatusChanged(): void {
    if (!this.control) {
      return;
    }

    const statusChanges = this.control.statusChanges as EventEmitter<string>;

    statusChanges.emit('TOUCHED');
  }

  private selectValues() {
    this.listLoaded$
      .pipe(takeUntil(this.destroyed$), filter(Boolean), first())
      .subscribe(() => this.comboboxValueSelect$.next());
  }

  private listenForValueSelect(): void {
    this.comboboxValueSelect$
      .pipe(debounceTime(ComboboxComponent.VALUE_UPDATE_DEBOUNCE_TIME), takeUntil(this.destroyed$))
      .subscribe(() => {
        setTimeout(() => {
          this.comboBox?.writeValue(this._value);
          this.cdr.detectChanges();
        }, 0);
      });
  }
}
