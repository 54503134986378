import { getLocaleDirection } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, LOCALE_ID, Output } from '@angular/core';
import { IgxButtonType } from '@infragistics/igniteui-angular';

import { ButtonColor } from '../../../button';

type PaginationIcon = 'left-caret' | 'right-caret';

// TODO: update pagination behaviour, make it smarter once the backend APIs have fully implemented "total"
@Component({
  selector: 'supy-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent {
  @Input() readonly previousDisabled: boolean;
  @Input() readonly nextDisabled: boolean;
  @Input() pageIndex: number;
  @Input() readonly buttonColor: ButtonColor = 'primary';
  @Input() readonly buttonVariant: IgxButtonType | 'link' = 'raised';
  @Input() readonly hidePageNumbers: boolean;
  @Output() readonly indexChange = new EventEmitter<number>();
  @Output() readonly previousClicked = new EventEmitter<void>();
  @Output() readonly nextClicked = new EventEmitter<void>();

  protected previousIconName: PaginationIcon = 'left-caret';
  protected nextIconName: PaginationIcon = 'right-caret';

  private readonly dir: 'ltr' | 'rtl';

  constructor(@Inject(LOCALE_ID) private readonly locale: string) {
    this.dir = getLocaleDirection(this.locale);
    this.previousIconName = this.dir === 'ltr' ? 'left-caret' : 'right-caret';
    this.nextIconName = this.dir === 'ltr' ? 'right-caret' : 'left-caret';
  }

  onPrevious(): void {
    this.pageIndex--;
    this.indexChange.next(this.pageIndex);
    this.previousClicked.emit();
  }

  onNext(): void {
    this.pageIndex++;
    this.indexChange.emit(this.pageIndex);
    this.nextClicked.emit();
  }
}
