import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

import { IconType } from '../../../icon';

export interface AutocompleteCellRendererContext<TData = unknown> {
  readonly showIcon?: boolean;
  readonly icon?: (rowData: TData) => IconType;
  readonly displayFn?: (option: TData) => string;
}

@Component({
  selector: 'supy-autocomplete-cell-renderer',
  template: `
    <div class="supy-autocomplete__cell">
      <supy-icon *ngIf="context?.showIcon" [name]="context.icon?.(rowData)"></supy-icon>
      <span *ngIf="rowData">{{ context?.displayFn?.(rowData) || params.value || 'Search..' }}</span>
    </div>
  `,
  styleUrls: ['./autocomplete-cell-renderer.component.scss'],
})
export class AutocompleteCellRendererComponent<
  TData = unknown,
  TValue = unknown,
  TContext extends AutocompleteCellRendererContext<TData> = unknown,
> implements ICellRendererAngularComp
{
  protected rowData: TData;
  protected context: TContext;
  protected params: ICellRendererParams<TData, TValue, TContext>;

  agInit(params: ICellRendererParams<TData, TValue, TContext>): void {
    this.params = params;
    this.context = params.context;
    this.rowData = this.getRowData(params);
  }

  refresh(params: ICellRendererParams<TData, TValue, TContext>): boolean {
    // set value into cell again
    this.rowData = this.getRowData(params);

    return true;
  }

  private getRowData(params: ICellRendererParams<TData, TValue, TContext>): TData {
    return params.data;
  }
}
