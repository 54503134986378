<form class="supy-filters__row" [formGroup]="form">
  <div class="supy-filters__row--inputs supy-filters__row--between" *ngIf="twoRows && filterGroup.searchConfig">
    <ng-container *ngTemplateOutlet="searchInputTemplate"></ng-container>
    <ng-content select="[supyFiltersExtraContent]"></ng-content>
  </div>
  <div class="supy-filters__row--inputs">
    <ng-container *ngIf="!twoRows && filterGroup.searchConfig">
      <ng-container *ngTemplateOutlet="searchInputTemplate"></ng-container>
    </ng-container>

    <ng-container *ngIf="filterGroup.selectionProperties.length">
      <supy-icon name="filters" size="small" color="gray"></supy-icon>

      <ng-container
        class="row no-gutters"
        *ngFor="let property of filterGroup.selectionProperties; trackBy: trackSelectionPropertiesByName"
      >
        <ng-container *ngIf="!property.hide" [ngSwitch]="property.selectType">
          <ng-container *ngSwitchCase="selectType.Combo">
            <div class="supy-filters__select--wrapper" *ngIf="ensureObservable(property.options) | async as options">
              <supy-combo-box
                #filter
                [class.supy-filters__filter-control--hidden]="property.disabled"
                [formControlName]="property.name"
                [valueKey]="property.valueKey"
                [titleKey]="property.titleKey"
                [displayKey]="property.displayKey"
                [subtitleKey]="property.subtitleKey"
                [pipeKey]="property.pipeKey"
                [placeholder]="property.placeholder"
                [name]="property.name"
                [multiple]="property.multiple"
                [localSearch]="property.localSearch"
                [itemHeight]="property.itemHeight || 72"
                [list]="options"
                displayKey="name"
                itemWidth="25rem"
                position="top-right"
              ></supy-combo-box>
              <div class="supy-filters__select">
                <ng-container *ngIf="form.get(property.name) as control">
                  <supy-filter-tile
                    [initialValue]="control.value?.length"
                    [clearable]="property.clearable"
                    [placeholder]="property.placeholder"
                    [name]="property.name"
                    [disabled]="property.disabled"
                    (openToggle)="filter.open()"
                    (clear)="form.get(property.name).reset()"
                    [isOpen]="filter?.isOpen"
                  >
                    <ng-container *ngIf="property.multiple; else singleSelect">
                      ({{ control.value?.length }}) {{ property.placeholder }}
                    </ng-container>
                    <ng-template #singleSelect>
                      <ng-container
                        *ngIf="
                          control.value &&
                          getValueFromList(options, property.valueKey, property.displayKey, control.value[0]) as value
                        "
                      >
                        <ng-container [ngSwitch]="property.displayPipe">
                          <ng-container *ngSwitchCase="'titlecase'">
                            {{ value | titlecase }}
                          </ng-container>
                          <ng-container *ngSwitchCase="'uppercase'">
                            {{ value | uppercase }}
                          </ng-container>
                          <ng-container *ngSwitchDefault>
                            {{ value }}
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-template>
                  </supy-filter-tile>
                </ng-container>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="selectType.Select">
            <div class="supy-filters__select--wrapper" *ngIf="ensureObservable(property.options) | async as options">
              <supy-select
                #filter
                [class.supy-filters__filter-control--hidden]="property.disabled"
                [formControlName]="property.name"
                [placeholder]="property.placeholder"
                [name]="property.name"
              >
                <supy-select-item *ngFor="let item of options" [value]="item[property.valueKey]">
                  <ng-container [ngSwitch]="property.displayPipe">
                    <ng-container *ngSwitchCase="'titlecase'">
                      {{ item[property.displayKey] | titlecase }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'uppercase'">
                      {{ item[property.displayKey] | uppercase }}
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      {{ item[property.displayKey] }}
                    </ng-container>
                  </ng-container>
                </supy-select-item>
              </supy-select>
              <div class="supy-filters__select">
                <ng-container *ngIf="form.get(property.name) as control">
                  <supy-filter-tile
                    [initialValue]="control.value"
                    [clearable]="property.clearable"
                    [placeholder]="property.placeholder"
                    [name]="property.name"
                    [disabled]="property.disabled"
                    (openToggle)="filter.select.open()"
                    (clear)="form.get(property.name).reset()"
                    [isOpen]="!filter?.select?.collapsed"
                  >
                    <ng-container
                      *ngIf="getValueFromList(options, property.valueKey, property.displayKey, control.value) as value"
                    >
                      <ng-container [ngSwitch]="property.displayPipe">
                        <ng-container *ngSwitchCase="'titlecase'">
                          {{ value | titlecase }}
                        </ng-container>
                        <ng-container *ngSwitchCase="'uppercase'">
                          {{ value | uppercase }}
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                          {{ value }}
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </supy-filter-tile>
                </ng-container>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="selectType.DatePicker">
            <div class="supy-filters__select--wrapper">
              <supy-date-input
                #filter
                [class.supy-filters__filter-control--hidden]="property.disabled"
                [formControlName]="property.name"
                [placeholder]="property.placeholder"
                [name]="property.name"
              ></supy-date-input>
              <div class="supy-filters__select">
                <ng-container *ngIf="form.get(property.name) as control">
                  <supy-filter-tile
                    [initialValue]="control.value"
                    [clearable]="property.clearable"
                    [placeholder]="property.placeholder"
                    [name]="property.name"
                    [disabled]="property.disabled"
                    (openToggle)="filter.datePicker.open()"
                    (clear)="form.get(property.name).reset()"
                  >
                    {{ control.value | date: 'dd - MM - yyy' }}
                  </supy-filter-tile>
                </ng-container>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="selectType.DateRange">
            <div class="supy-filters__select--wrapper">
              <supy-date-range
                #filter
                [class.supy-filters__filter-control--hidden]="property.disabled"
                [formControlName]="property.name"
                [placeholder]="property.placeholder"
                [name]="property.name"
                [value]="$any(property.value)"
                [predefinedRanges]="predefinedDateRanges"
                [hideOutsideDays]="property.hideOutsideDays"
                [disabled]="property.disabled"
                [displayMonthsCount]="property.displayMonthsCount"
              ></supy-date-range>
              <div class="supy-filters__select">
                <ng-container *ngIf="form.get(property.name) as control">
                  <supy-filter-tile
                    [initialValue]="control.value"
                    [clearable]="property.clearable"
                    [placeholder]="property.placeholder"
                    [name]="property.name"
                    [disabled]="property.disabled"
                    (openToggle)="filter.datePicker.open()"
                    (clear)="form.get(property.name).reset()"
                    [isOpen]="!filter?.isCollapsed"
                  >
                    {{ control.value?.start | date: 'dd.MM.yy' }} -
                    {{ control.value?.end | date: 'dd.MM.yy' }}
                  </supy-filter-tile>
                </ng-container>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="selectType.CalendarRange">
            <div class="supy-filters__select--wrapper">
              <supy-calendar-picker
                #filter
                [class.supy-filters__filter-control--hidden]="property.disabled"
                [formControlName]="property.name"
                [placeholder]="property.placeholder"
                [name]="property.name"
                [value]="$any(property.value)"
                [specialDates]="property.specialDates"
                [viewDate]="property.calendarViewDate"
                [canSelectSingleDate]="property.canSelectSingleDate"
                [hideOutsideDays]="property.hideOutsideDays"
                [disableMode]="property.calendarDisableMode"
                [disabled]="property.disabled"
                [onlyEmitOnConfirmation]="property.onlyEmitOnConfirmation"
              ></supy-calendar-picker>
              <div class="supy-filters__select">
                <ng-container *ngIf="form.get(property.name) as control">
                  <supy-filter-tile
                    [initialValue]="control.value"
                    [clearable]="property.clearable"
                    [placeholder]="property.placeholder"
                    [name]="property.name"
                    [disabled]="property.disabled"
                    (openToggle)="filter.open()"
                    (clear)="form.get(property.name).reset()"
                  >
                    {{ filter.displayValue }}
                  </supy-filter-tile>
                </ng-container>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="selectType.DropdownTree">
            <div class="supy-filters__select--wrapper">
              <supy-dropdown-tree
                #filter
                [class.supy-filters__filter-control--hidden]="property.disabled"
                [formControlName]="property.name"
                [placeholder]="property.placeholder"
                [name]="property.name"
                [data]="treeObservable(property.options) | async"
                [returnStrategy]="property.returnStrategy"
                [multipleSelectionStrategy]="property.multipleSelectionStrategy"
                [selection]="property.multiple ? 'multiple' : 'single'"
                [displayStrategy]="property.displayStrategy ?? 'value'"
                [autoWidth]="true"
              ></supy-dropdown-tree>
              <div class="supy-filters__select">
                <ng-container *ngIf="form.get(property.name) as control">
                  <supy-filter-tile
                    [initialValue]="control.value?.length"
                    [clearable]="property.clearable"
                    [placeholder]="property.placeholder"
                    [name]="property.name"
                    [disabled]="property.disabled"
                    (openToggle)="filter.open()"
                    (clear)="form.get(property.name).reset()"
                    [isOpen]="filter?.overlayDisplayed()"
                  >
                    {{ filter.textAreaValue }}
                  </supy-filter-tile>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="filterGroup.switchProperties.length">
      <ng-container *ngFor="let property of filterGroup.switchProperties; trackBy: trackSwitchPropertiesByName">
        <supy-switch
          [name]="property.name"
          [formControlName]="property.name"
          [labelPosition]="property.labelPosition"
          [size]="property.size"
          >{{ property.label }}</supy-switch
        >
      </ng-container>
    </ng-container>

    <supy-button
      class="supy-filters__reset"
      *ngIf="(filterGroup.selectionProperties?.length && appliedFilters) || externalFiltering"
      (buttonClick)="onResetFilters()"
      name="reset-filters"
      color="error-tertiary"
      i18n="@@common.actions.clearFilters"
    >
      <supy-icon name="close-circle" color="error"></supy-icon>
      Clear Filters ({{ externalFiltering ? (appliedFilters ?? 0) + 1 : appliedFilters }})
    </supy-button>
  </div>

  <ng-template #searchInputTemplate>
    <supy-input
      class="supy-filters__input"
      [style.width]="filterGroup.searchConfig.width"
      [placeholder]="filterGroup.searchConfig.placeholder"
      formControlName="search"
      [clearable]="filterGroup.searchConfig.clearable"
      [isLoading]="filterGroup.searchConfig.isLoading"
      suffix="search"
      name="search"
    ></supy-input>
  </ng-template>
</form>
