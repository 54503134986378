<supy-button
  #toggler
  (buttonClick)="toggleCalendar()"
  class="supy-calendar-picker__toggler"
  [disabled]="disabled || isLoading"
>
  <span *ngIf="hasValue; else placeholderTemplate">{{ displayValue }}</span>
  <ng-template #placeholderTemplate>
    <span class="supy-calendar-picker__placeholder">{{ placeholder }}</span>
  </ng-template>
  <div class="supy-calendar-picker__flex">
    <supy-icon
      name="close-circle"
      color="error"
      *ngIf="hasValue"
      (click)="$event.stopPropagation(); onClear()"
    ></supy-icon>
    <supy-icon name="arrow-circle-down" *ngIf="!isLoading; else loader"></supy-icon>
    <ng-template #loader>
      <supy-loader></supy-loader>
    </ng-template>
  </div>
</supy-button>
<div igxToggle class="supy-calendar-picker__main">
  <div class="supy-calendar-picker__side" *ngIf="showSidebar">
    <div class="supy-calendar-picker__predefined-dates">
      <supy-button
        color="default"
        (buttonClick)="onSelectPredefinedDate(range)"
        *ngFor="let range of predefinedRanges"
        >{{ range?.customLabel }}</supy-button
      >
    </div>
  </div>
  <div class="supy-calendar-picker__content">
    <div class="supy-calendar-picker__wrapper">
      <igx-calendar
        #calendar
        class="supy-calendar-picker"
        [selection]="selectionType"
        [value]="value"
        (selected)="onSelection($event)"
        [attr.data-name]="name"
        [monthsViewNumber]="monthsViewNumber"
        [viewDate]="viewDate"
        [hideOutsideDays]="hideOutsideDays"
      >
      </igx-calendar>
    </div>
    <div class="supy-calendar-picker__footer">
      <supy-button color="secondary" (buttonClick)="onClear()">Clear</supy-button>
      <supy-button color="primary" [disabled]="!canSubmit" (buttonClick)="onSubmit()">Done</supy-button>
    </div>
  </div>
</div>
