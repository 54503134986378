import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'supy-filter-tile',
  templateUrl: './filter-tile.component.html',
  styleUrls: ['./filter-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterTileComponent<T> {
  @Input() @HostBinding('attr.name') protected readonly name: string;
  @Input() protected readonly clearable: boolean;
  @Input() protected readonly isOpen: boolean;
  @Input() protected set initialValue(value: T) {
    //TODO: Refactor this code to handle boolean in a cleaner way.
    if (typeof value === 'boolean' && !value) {
      this.calculatedInitialValue = true as T;
    } else if (!value || Object.values(value).some(i => !i)) {
      this.calculatedInitialValue = null;
    } else {
      this.calculatedInitialValue = value;
    }
  }

  @Input() protected readonly noPaddings: boolean;
  @Input() protected readonly transparent: boolean;
  @Input() protected readonly disabled: boolean;
  @Input() protected readonly placeholder: string;

  @Output() protected readonly openToggle = new EventEmitter<void>();
  @Output() protected readonly clear = new EventEmitter<void>();

  protected calculatedInitialValue: T;
}
