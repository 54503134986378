<div class="supy-login-email">
  <div class="supy-login-email__form">
    <div class="supy-login-email__section-logo">
      <supy-image *ngIf="authParams.logoUrl" [width]="284" [height]="38" [src]="authParams.logoUrl"></supy-image>
    </div>
    <div class="supy-login-email__section-title">
      <h1 class="supy-login-email__title">{{ authParams.title }}</h1>
      <p class="supy-login-email__subtitle" i18n="@@login.emailSubtitle">
        Enter your email address linked to supy to receive the login instructions
      </p>
    </div>
    <form (ngSubmit)="onSubmit()" [formGroup]="form" class="supy-login-email__form-content">
      <div class="supy-login-email__inputs">
        <div class="supy-login-email__input">
          <label class="supy-login-email__label" i18n="@@common.email">Your Email</label>
          <supy-input-with-button>
            <supy-input name="email" placeholder="email@example.com" type="email" formControlName="input">
              <supy-input-error *supyErrorIf="'required'" i18n="@@login.emailRequired">
                Email is required
              </supy-input-error>
              <supy-input-error *supyErrorIf="'email'" i18n="@@login.emailInvalid">
                Email is not valid
              </supy-input-error>
            </supy-input>
            <supy-button
              supy-button
              [disabled]="form.invalid || state.isLoading"
              type="submit"
              density="medium"
              color="primary"
              name="login"
            >
              <supy-icon name="paper-plane"></supy-icon>
            </supy-button>
          </supy-input-with-button>
        </div>
      </div>
      <div *ngIf="authParams.loginTypes.includes(loginTypes.Phone)">
        <a
          class="body-2 supy-anchor supy-anchor--primary"
          [routerLink]="['../phone']"
          [state]="navigation?.extras.state"
          i18n="@@login.usePhoneInstead"
        >
          Login with phone number instead
        </a>
      </div>
    </form>
  </div>
</div>
