import { InvoiceUnitTypeEnum } from '../enums';
import { IdType, SkeletonObjectType } from '../models';
import { Branch } from './branch.entity';
import { SimpleUser } from './user.entity';

export type SupplierId = string;

export enum SupplierState {
  Active = 'active',
  Deleted = 'deleted',
  Pending = 'pending',
}

export enum SupplierType {
  supplier = 'supplier',
  centralKitchen = 'central-kitchen',
  category = 'category',
}

export enum SupplierPartnershipTypeEnum {
  Integrated = 'integrated',
  Independent = 'independent',
}

export enum IntegratedUnitTypeEnum {
  Uom = 'uom',
  Package = 'package',
}

export interface IntegratedUnit {
  readonly id: string;
  readonly name: string;
  readonly reference?: IdType;
  readonly scope: InvoiceUnitTypeEnum;
  readonly isFixedQuantity?: boolean;
}

export class SupplierMetadata {
  readonly taxRegistrationNumber?: string;
  readonly retailerId?: string;
  readonly warehouseId?: string;
  readonly adHoc?: boolean;
  readonly partnershipType?: SupplierPartnershipTypeEnum;
  readonly partnershipContact?: string;
  readonly integratedUnits?: IntegratedUnit[];
}

export class SupplierSettings {
  readonly minOrderValue?: number;
  readonly cutOffTime?: number;
}

export class UpdateSupplierInfo {
  readonly id: string;
  readonly name?: string;
  readonly trn?: string;
  readonly country?: string;
}

export class Supplier {
  readonly id: SupplierId;
  readonly name: string;
  readonly email: string;
  readonly phone: string;
  readonly address: string;
  readonly logoUrl?: string;
  readonly branchIds?: string[];
  readonly branches?: Partial<Branch>[];
  readonly color: string;
  readonly users: SimpleUser[];
  readonly countryId: string;
  readonly type?: SupplierType;
  readonly code?: string;
  readonly uuid?: string;

  readonly state: SupplierState;

  readonly metadata?: SupplierMetadata;
  readonly settings?: SupplierSettings;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  readonly createdBy?: SkeletonObjectType;
  readonly displayName?: string;
  readonly retailerId?: string;
  readonly defaultTaxId?: string;
  readonly isExposed?: boolean;

  constructor(args: Partial<Supplier>) {
    this.id = args.id ?? '';
    this.name = args.name ?? '';
    this.email = args.email ?? '';
    this.phone = args.phone ?? '';
    this.logoUrl = args.logoUrl;
    this.color = args.color ?? '';
    this.address = args.address ?? '';
    this.type = args.type ?? SupplierType.supplier;
    this.metadata = args.metadata;
    this.settings = args.settings;
    this.state = args.state ?? SupplierState.Active;
    this.createdAt = args.createdAt ?? new Date();
    this.updatedAt = args.updatedAt ?? new Date();
    this.createdBy = args.createdBy;
    this.displayName = args.displayName ?? '';
    this.code = args.code ?? '';
    this.retailerId = args.retailerId ?? '';
    this.defaultTaxId = args.defaultTaxId ?? '';
    this.isExposed = args.isExposed;
  }
}

export type ViewSupplier = Omit<Supplier, 'users'>;

export type SupplierSnapshot = Pick<
  Supplier,
  'id' | 'name' | 'email' | 'phone' | 'address' | 'type' | 'metadata' | 'isExposed' | 'logoUrl'
>;
