import { ChangeDetectionStrategy, Component, OnInit, Optional } from '@angular/core';

import { IntercomService } from '@supy/components';

@Component({
  selector: 'supy-auth',
  templateUrl: './auth.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthComponent implements OnInit {
  constructor(@Optional() private readonly intercomService: IntercomService) {}

  ngOnInit(): void {
    this.intercomService?.startAnonymous();
  }
}
