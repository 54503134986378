import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { IAfterGuiAttachedParams, ICellEditorParams } from '@ag-grid-community/core';
import { Component, ViewChild } from '@angular/core';

import { InputComponent } from '../../../input';

export interface InputCellEditorContext {
  readonly disabled?: boolean;
  readonly placeholder?: string;
  readonly numeric?: boolean;
  readonly precision?: number;
  readonly readonly?: boolean;
  readonly density?: 'small' | 'medium';
  readonly maximum?: number;
  readonly autocomplete?: string;
  readonly centerText?: boolean;
}

const KEY_BACKSPACE = 'Backspace';
const KEY_F2 = 'F2';

@Component({
  selector: 'supy-input-cell-editor',
  template: `
    <div class="supy-input-editor__cell">
      <supy-input
        #focusableComponent
        [disabled]="context?.disabled"
        [placeholder]="context?.placeholder"
        [readonly]="context?.readonly"
        [numeric]="context?.numeric"
        [precision]="context?.precision"
        [textSelection]="textSelection"
        [density]="context?.density ?? 'small'"
        [value]="getValue()"
        (valueChange)="valueChangeHandler($event)"
        [centerText]="context?.centerText"
        [autocomplete]="context?.autocomplete ?? 'off'"
        (focusOut)="params.stopEditing()"
      ></supy-input>
    </div>
  `,
  styleUrls: ['./input-cell-editor.component.scss'],
})
export class InputCellEditorComponent<
  TData = unknown,
  TValue extends string | number = string | number,
  TContext extends InputCellEditorContext = InputCellEditorContext,
> implements ICellEditorAngularComp
{
  // TODO: add support for nested colDef values  (e.g. 'foo.bar')
  protected value: TValue | string | number;
  protected rowData: TData;
  protected params: ICellEditorParams<TData, TValue, TContext>;
  protected context: TContext;
  protected textSelection = false;
  private cancelBeforeStart = false;

  @ViewChild('focusableComponent') private readonly focusableComponent: InputComponent<TValue>;

  agInit(params: ICellEditorParams<TData, TValue, TContext>): void {
    this.params = params;
    this.context = params.context;
    this.rowData = this.getRowData(params);
    this.value = params.value;

    this.setInitialValue(this.params);

    if (this.context.numeric) {
      // only start edit if key pressed is a number, not a letter
      const eventKey = params.eventKey;

      this.cancelBeforeStart = !!(eventKey && eventKey.length === 1 && '.1234567890'.indexOf(eventKey) < 0);
    }
  }

  setInitialValue(params: ICellEditorParams<TData, TValue, TContext>): void {
    let startValue: string | number | TValue;
    let textSelection = true;
    const eventKey = params.eventKey;

    if (eventKey === KEY_BACKSPACE) {
      // if backspace or delete pressed, we clear the cell
      startValue = '';
    } else if (eventKey && eventKey.length === 1) {
      // if a letter was pressed, we start with the letter
      startValue = eventKey;
      textSelection = false;
    } else {
      // otherwise we start with the current value
      startValue = params.value;

      if (params.eventKey === KEY_F2) {
        textSelection = false;
      }
    }

    this.valueChangeHandler(startValue);
    this.textSelection = textSelection;
  }

  getValue(): TValue | string | number {
    return this.value;
  }

  isCancelAfterEnd(): boolean {
    return this.context.numeric ? Number(this.value) > this.context.maximum : false;
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  afterGuiAttached(_?: IAfterGuiAttachedParams): void {
    this.onStartEditing();
  }

  protected valueChangeHandler(value: TValue | string | number): void {
    if (this.context.numeric) {
      if (value === '-') {
        this.value = value;

        return;
      }

      if (value === '.') {
        this.value = '0.';

        return;
      }

      if (value === '-.') {
        this.value = '-0.';

        return;
      }

      if (value === '-0.') {
        this.value = value;

        return;
      }

      if (value === '-0') {
        this.value = value;

        return;
      }

      this.value = value != null ? Number(value) : 0;

      return;
    }

    this.value = value;
  }

  protected onStartEditing(): void {
    if (this.focusableComponent) {
      this.focusableComponent.focus();
    }
  }

  private getRowData(params: ICellEditorParams<TData, TValue, TContext>): TData {
    return params.data;
  }
}
