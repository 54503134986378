import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ContentChildren,
  HostBinding,
  Input,
  QueryList,
  signal,
  ViewChild,
} from '@angular/core';
import { IgxDropDownComponent, IgxDropDownItemComponent } from '@infragistics/igniteui-angular';

import { DropdownItemComponent } from '../dropdown-item';

@Component({
  selector: 'supy-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent {
  @ViewChild(IgxDropDownComponent, { static: true })
  readonly dropdown: IgxDropDownComponent;

  @ContentChildren(DropdownItemComponent)
  readonly children: QueryList<DropdownItemComponent>;

  @Input() @HostBinding('attr.name') readonly name: string;

  @Input()
  @HostBinding('attr.width')
  readonly width: string;

  @Input() set customClasses(value: string[]) {
    this.#customClasses.set(value);
  }

  readonly #customClasses = signal<string[]>([]);

  readonly getCustomClasses = computed(() => this.#customClasses().join(' '));

  selectItem(item: IgxDropDownItemComponent, event: PointerEvent): void {
    this.dropdown.selectItem(item, event);
  }
}
