<supy-dialog
  class="supy-drawer"
  initialState="open"
  position="right"
  stretch="vertical"
  [width]="width"
  [closeOnOutsideClick]="closeOnOutsideClick"
  (dialogClosed)="dialogClosed.emit($event)"
  (dialogOpened)="dialogOpened.emit($event)"
  (dialogOpening)="dialogOpening.emit($event)"
  (dialogClosing)="dialogClosing.emit($event)"
>
  <supy-dialog-header>
    <div class="supy-drawer__header">
      <div class="supy-drawer__flex-space-between">
        <supy-button variant="icon" color="primary-blend" name="close" title="Close" (buttonClick)="closeDialog()">
          <supy-icon name="close-circle" size="medium"></supy-icon>
        </supy-button>

        <div *ngIf="showPagination" class="supy-drawer__actions">
          <supy-button
            variant="icon"
            color="primary-blend"
            name="previous"
            title="Previous"
            [disabled]="previousDisabled"
            (buttonClick)="navigationClicked.emit(false)"
          >
            <supy-icon name="long-arrow-left-circle" size="medium"></supy-icon>
          </supy-button>
          <supy-button
            variant="icon"
            color="primary-blend"
            name="next"
            title="Next"
            [disabled]="nextDisabled"
            (buttonClick)="navigationClicked.emit(true)"
          >
            <supy-icon name="long-arrow-right-circle" size="medium"></supy-icon>
          </supy-button>
        </div>
      </div>
      <div>
        <ng-content select="[header]"></ng-content>
      </div>
    </div>
  </supy-dialog-header>
  <supy-dialog-content>
    <div class="supy-drawer__content">
      <ng-content select="[content]"></ng-content>
    </div>
  </supy-dialog-content>
  <supy-dialog-footer class="supy-drawer__footer">
    <ng-content select="[footer]"></ng-content>
  </supy-dialog-footer>
</supy-dialog>
