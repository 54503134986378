import isEqual from 'lodash-es/isEqual';
import { distinctUntilChanged, filter, Observable, timer } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { parse } from 'yaml';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { LocalizedData } from '@supy/common';
import { APP_CONFIG } from '@supy/core';

import { MaintenanceConfig } from '../config';
import { ROUTES_MAP } from './routes-map.const';

export interface MaintenanceInfo {
  readonly features?: string[];
  readonly notificationMessage: LocalizedData;
  readonly startDate: string;
  readonly endDate: string;
  readonly type: 'web' | 'mobile';
  readonly whitelist: {
    readonly userIds?: string[];
    readonly retailerIds?: string[];
  };
}

@Injectable({
  providedIn: 'root',
})
export class MaintenanceInfoService {
  private readonly maintenanceUrl: string;
  private maintenanceInfo: MaintenanceInfo[] = [];
  private readonly configFileRequest: Observable<MaintenanceInfo[]>;

  constructor(
    private readonly http: HttpClient,
    @Inject(APP_CONFIG) private readonly config: MaintenanceConfig,
  ) {
    this.maintenanceUrl = this.config.maintenance.configUrl;
    this.configFileRequest = timer(0, 60 * 1000).pipe(
      switchMap(() =>
        this.http.get(this.maintenanceUrl, {
          responseType: 'text',
        }),
      ),
      map(res => parse(res) as MaintenanceInfo[]),
      distinctUntilChanged((_, current) => {
        const previousWeb = this.maintenanceInfo.find(({ type }) => type === 'web');
        const currentWeb = current.find(({ type }) => type === 'web');

        if (!previousWeb || !currentWeb) {
          return false;
        }

        return isEqual(previousWeb, currentWeb);
      }),
      shareReplay(1),
    );
    this.getMaintenanceInfo().subscribe(info => (this.maintenanceInfo = info));
  }

  getMaintenanceInfo(): Observable<MaintenanceInfo[]> {
    return this.configFileRequest;
  }

  isUnderMaintenance$(route: string): Observable<{ underMaintenance: boolean; message?: LocalizedData }> {
    return this.getMaintenanceInfo().pipe(
      map(info => info.find(({ type }) => type === 'web')),
      filter(Boolean),
      map(info => {
        const affectedFeatures = new Set<string>();
        const features = ROUTES_MAP.get(route);

        const isMaintenanceTime = this.isMaintenanceTime(info);
        const isWholeAppDisabled = info.features.some(feature => feature === '*' || feature === '/');

        if (isMaintenanceTime && isWholeAppDisabled) {
          return {
            underMaintenance: true,
            message: info.notificationMessage,
          };
        }

        if (features) {
          features.forEach(feature => {
            affectedFeatures.add(feature);
            affectedFeatures.add(feature.split('.')[0]);
          });
        }

        const isUnderMaintenance = isMaintenanceTime && info.features.some(feature => affectedFeatures.has(feature));

        return {
          underMaintenance: isUnderMaintenance,
          message: isUnderMaintenance ? info.notificationMessage : undefined,
        };
      }),
    );
  }

  isUnderMaintenance(route: string): boolean {
    const info = this.maintenanceInfo.find(({ type }) => type === 'web');

    if (!info) {
      return false;
    }

    const isMaintenanceTime = this.isMaintenanceTime(info);

    if (!isMaintenanceTime) {
      return false;
    }

    const isWholeAppDisabled = info.features.some(feature => feature === '*' || feature === '/');

    if (isWholeAppDisabled) {
      return true;
    }

    const affectedFeatures = new Set<string>();
    const features = ROUTES_MAP.get(route);

    if (features) {
      features.forEach(feature => {
        affectedFeatures.add(feature);
        affectedFeatures.add(feature.split('.')[0]);
      });
    }

    return isMaintenanceTime && info.features.some(feature => affectedFeatures.has(feature));
  }

  isMaintenanceTime(info: MaintenanceInfo): boolean {
    const now = new Date();
    const start = new Date(info.startDate);
    const end = new Date(info.endDate);

    return now >= start && now <= end;
  }
}
