<supy-input
  class="supy-input-autocomplete"
  [ngClass]="{ 'ng-dirty ng-invalid ng-touched': touched && !value }"
  type="text"
  [value]="inputValue"
  [placeholder]="placeholder"
  [focusOnInit]="focusOnInit"
  (focusIn)="onFocusIn($event)"
  (focusOut)="onFocusOut($event)"
  (valueChange)="onSearchValueChange($event)"
  autocomplete="off"
  [latency]="debounceTime"
  [isLoading]="isLoading"
  [supyAutocomplete]="dropdown"
  [prefix]="prefix"
  [suffix]="suffix"
  [clearable]="clearable"
  (cleared)="onClear()"
></supy-input>

<igx-drop-down
  #dropdown
  maxHeight="400px"
  [width]="width"
  (closed)="closed.emit($event)"
  (selectionChanging)="onSelectionChange($event)"
>
  <ng-container *ngIf="options">
    <ng-container *ngIf="!options.length && searchAllowed && !value">
      <igx-drop-down-item class="supy-input-autocomplete__no-match-option">
        <ng-container [ngTemplateOutlet]="noMatchOption"></ng-container>
      </igx-drop-down-item>
      <ng-template #defaultNoMatchOption>
        <igx-drop-down-item class="supy-input-autocomplete__no-match-option" [disabled]="true">
          No match found
        </igx-drop-down-item>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="customOptionsTemplate; else simpleOptions">
      <igx-drop-down-item
        class="supy-input-autocomplete__dropdown-item"
        *ngFor="let option of options"
        [value]="option"
        (keydown.enter)="onValueChange(option)"
        (click)="onValueChange(option)"
      >
        <ng-container
          [ngTemplateOutlet]="customOptionsTemplate"
          [ngTemplateOutletContext]="{ option: option }"
        ></ng-container>
      </igx-drop-down-item>
    </ng-container>

    <ng-template #simpleOptions>
      <igx-drop-down-item
        *ngFor="let option of options"
        [value]="option"
        (keydown.enter)="onValueChange(option)"
        (click)="onValueChange(option)"
      >
        <p>{{ displayValueFn(option) }}</p>
      </igx-drop-down-item>
    </ng-template>
  </ng-container>
</igx-drop-down>
