import {
  FilterConditionType,
  IQuery,
  IQueryFiltering,
  IQueryFilteringGroup,
  IQueryOrdering,
  IQueryPaging,
  QueryMatchType,
  QueryOperationType,
  QueryOrderDirection,
} from './collection-response.interface';

export interface ICloneable<T> {
  clone(): T;
}

export class QueryFiltering<
    T,
    TKey extends keyof T = keyof T,
    TValue extends T[TKey] = T[TKey],
    TOperation extends QueryOperationType<TValue> = QueryOperationType<TValue>,
    TMatch extends QueryMatchType<TOperation, TValue> = QueryMatchType<TOperation, TValue>,
  >
  implements IQueryFiltering<T>, ICloneable<QueryFiltering<T>>
{
  constructor(
    readonly by: TKey,
    readonly op: TOperation,
    readonly match: TMatch,
    encode = true,
  ) {
    this.by = by;
    this.op = op;
    this.match = typeof match === 'string' && encode ? (encodeURIComponent(match) as TMatch) : match;
  }

  clone(): QueryFiltering<T> {
    return new QueryFiltering(this.by, this.op, this.match);
  }
}

export class QueryFilteringGroup<T> implements IQueryFilteringGroup<T>, ICloneable<QueryFilteringGroup<T>> {
  condition: FilterConditionType;
  filtering: QueryFiltering<T>[];
  groups: QueryFilteringGroup<T>[];

  constructor(op: FilterConditionType, filtering: IQueryFiltering<T>[], expressions: IQueryFilteringGroup<T>[]) {
    this.condition = op;
    this.filtering = filtering.map(x => new QueryFiltering<T>(x.by, x.op, x.match));
    this.groups = expressions.map(x => new QueryFilteringGroup<T>(x.condition, x.filtering, x.groups));
  }

  withFiltering(filtering: IQueryFiltering<T>[]): this {
    this.filtering = [
      ...this.filtering,
      ...filtering.map(filter => new QueryFiltering<T>(filter.by, filter.op, filter.match)),
    ];

    return this;
  }

  withGroup(op: FilterConditionType, filtering: IQueryFiltering<T>[]): this {
    this.groups.push(new QueryFilteringGroup<T>(op, filtering, []));

    return this;
  }

  setCondition(condition: FilterConditionType): this {
    this.condition = condition;

    return this;
  }

  setFilter(filter: IQueryFiltering<T>, encode = true): this {
    const filtering = this.filtering.filter(x => x.by !== filter.by);

    this.filtering = [...filtering, new QueryFiltering<T>(filter.by, filter.op, filter.match, encode)];

    return this;
  }

  removeFilter(filter: IQueryFiltering<T>): this {
    const filtering = this.filtering.filter(x => x.by !== filter.by);

    this.filtering = [...filtering];

    return this;
  }

  removeFiltering(filters: IQueryFiltering<T>[]): this {
    const by = filters.map(x => x.by);
    const filtering = this.filtering.filter(x => !by.includes(x.by));

    this.filtering = [...filtering];

    return this;
  }

  clone(): QueryFilteringGroup<T> {
    return new QueryFilteringGroup(
      this.condition,
      this.filtering.map(x => x.clone()),
      this.groups.map(x => x.clone()),
    );
  }
}

export class QueryOrdering<T> implements IQueryOrdering<T>, ICloneable<QueryOrdering<T>> {
  constructor(
    readonly by: keyof T,
    readonly dir: QueryOrderDirection,
  ) {}

  clone(): QueryOrdering<T> {
    return new QueryOrdering(this.by, this.dir);
  }
}

export class QueryPaging implements IQueryPaging, ICloneable<QueryPaging> {
  private static readonly NO_OFFSET = 0;
  private static readonly NO_LIMIT = -1;

  constructor(
    readonly offset: number = 0,
    readonly limit: number = 0,
  ) {}

  static get NoLimit(): QueryPaging {
    return new QueryPaging(this.NO_OFFSET, this.NO_LIMIT);
  }

  clone(): QueryPaging {
    return new QueryPaging(this.offset, this.limit);
  }
}

export class Query<T> implements IQuery<T>, ICloneable<Query<T>> {
  readonly filtering: QueryFilteringGroup<T>;
  readonly ordering: QueryOrdering<T>[] = [];
  readonly paging: QueryPaging;

  constructor(query?: Partial<IQuery<T>>) {
    if (query?.filtering) {
      if (query?.filtering instanceof Array) {
        this.filtering = new QueryFilteringGroup('and', query?.filtering, []);
      } else {
        const filtering = query?.filtering;

        this.filtering = new QueryFilteringGroup(
          filtering?.condition ?? 'and',
          filtering?.filtering ?? [],
          filtering?.groups ?? [],
        );
      }
    }
    this.ordering = query?.ordering?.map(x => new QueryOrdering(x.by, x.dir)) || [];
    this.paging = new QueryPaging(query?.paging?.offset, query?.paging?.limit);
  }

  clone(): Query<T> {
    return new Query({
      filtering: this.filtering.clone(),
      ordering: this.ordering.map(x => x.clone()),
      paging: this.paging.clone(),
    });
  }

  serialize(): string {
    return JSON.stringify(this);
  }

  toQueryParams(): Partial<Record<keyof Query<T>, string>> {
    const params: Partial<Record<keyof Query<T>, string>> = {};

    if (this.paging) {
      params.paging = JSON.stringify(this.paging);
    }

    if (this.filtering) {
      params.filtering = JSON.stringify(this.filtering);
    }

    if (this.ordering) {
      params.ordering = JSON.stringify(this.ordering);
    }

    return params;
  }
}

export class QueryBuilder<T> {
  private query: Query<T>;

  constructor(query?: Partial<IQuery<T>>) {
    this.query = new Query(query);
  }

  static from<T>(query: Partial<Query<T>>): QueryBuilder<T> {
    return new QueryBuilder(query);
  }

  static fromString<T>(string: string | null): QueryBuilder<T> {
    if (!string) return new QueryBuilder<T>(new Query());

    const query = JSON.parse(string) as Partial<Query<T>>;

    return new QueryBuilder<T>(query);
  }

  static empty<T>(): Query<T> {
    return new Query<T>();
  }

  withQuery(query: IQuery<T>): this {
    this.query = new Query<T>(query);

    return this;
  }

  withOrdering(ordering: IQueryOrdering<T>[]): this {
    this.query = new Query<T>({
      ...this.query,
      ordering,
    });

    return this;
  }

  withPaging(paging: IQueryPaging): this {
    this.query = new Query<T>({
      ...this.query,
      paging,
    });

    return this;
  }

  setOrder(order: IQueryOrdering<T>): this {
    const ordering = this.query.ordering.filter(x => x.by !== order.by);

    this.query = new Query({
      ...this.query,
      ordering: [...ordering, new QueryOrdering<T>(order.by, order.dir)],
    });

    return this;
  }

  removeOrder(order: IQueryOrdering<T>): this {
    const ordering = this.query.ordering.filter(x => x.by !== order.by);

    this.query = new Query<T>({
      ...this.query,
      ordering,
    });

    return this;
  }

  get filtering(): QueryFilteringGroup<T> {
    return this.query.filtering;
  }

  build(): Query<T> {
    return new Query<T>(this.query);
  }

  serialize(): string {
    return this.query.serialize();
  }
}
