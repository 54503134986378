import { filter, first, map, Observable, switchMap, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { AuthService, AuthUser } from '@supy/auth';
import { IQueryResponse, Query, User, UserId, UserWithBranches } from '@supy/common';

import { USERS_BFF_URI, USERS_URI } from '../../config';
import {
  CreateRetailerUserRequest,
  CreateUserRequest,
  RemoveUsersFromRetailerRequest,
  UpdateRetailerUserRequest,
  UpdateUserRequest,
  UploadImageRequest,
  UploadImageResponse,
} from '../../core';

@Injectable()
export class UsersService {
  constructor(
    private readonly authService: AuthService,
    protected readonly httpClient: HttpClient,
    @Inject(USERS_URI) private readonly uri: string,
    @Inject(USERS_BFF_URI) private readonly uriBff: string,
  ) {}

  syncUser(): Observable<User> {
    return this.authService.user$.pipe(
      filter(Boolean),
      first(),
      switchMap(authUser =>
        this.getUser(authUser.id).pipe(
          tap(user => this.authService.setUserData(new AuthUser({ ...authUser, ...user }))),
        ),
      ),
    );
  }

  getUser(id: UserId): Observable<User> {
    return this.httpClient.get<User>(`${this.uri}/${id}`);
  }

  getUsers(query: Query<User>): Observable<IQueryResponse<User>> {
    return this.httpClient.get<IQueryResponse<User>>(this.uri, { params: query.toQueryParams() });
  }

  getRetailerUsers(retailerId: string, query: Query<User>): Observable<IQueryResponse<User>> {
    return this.httpClient.get<IQueryResponse<User>>(`${this.uriBff}/retailer/${retailerId}`, {
      params: query.toQueryParams(),
    });
  }

  getRetailerUser(userId: string): Observable<UserWithBranches> {
    return this.httpClient.get<UserWithBranches>(`${this.uriBff}/${userId}`);
  }

  createUser(body: CreateUserRequest): Observable<User> {
    return this.httpClient.post<User>(this.uri, body);
  }

  createUserBff(body: CreateUserRequest): Observable<User> {
    return this.httpClient.post<User>(this.uriBff, body);
  }

  editUser(id: UserId, body: UpdateUserRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.uri}/${id}`, body);
  }

  editUserBff(id: string, body: UpdateUserRequest): Observable<void> {
    let settings = body.settings;

    if (settings) {
      const { chat, order, showApproxPrice, viewCategory, sendNewOrderEmail } = settings;

      settings = { chat, order, showApproxPrice, viewCategory, sendNewOrderEmail };
    }

    return this.httpClient.patch<void>(`${this.uriBff}/${id}`, settings ? { ...body, settings } : body);
  }

  deleteUser(id: UserId): Observable<void> {
    return this.httpClient.delete<void>(`${this.uri}/${id}`);
  }

  uploadUserImage(body: UploadImageRequest): Observable<UploadImageResponse> {
    const formData = new FormData();

    formData.append('file', body.file);

    return this.httpClient.post<UploadImageResponse>(`${this.uri}/image`, formData);
  }

  getCurrentUserWithBranches(): Observable<UserWithBranches> {
    return this.authService.user$.pipe(
      filter(Boolean),
      first(),
      switchMap(authUser => this.getUserWithBranches(authUser.id)),
    );
  }

  private getUserWithBranches(id: string): Observable<UserWithBranches> {
    return this.httpClient
      .get<UserWithBranches>(`${this.uri}/${id}/branches`)
      .pipe(map(userWithBranches => new UserWithBranches({ ...userWithBranches })));
  }

  createRetailerUserBFF(body: CreateRetailerUserRequest): Observable<User> {
    return this.httpClient.post<User>(this.uriBff, body);
  }

  updateRetailerUserBFF(id: string, body: UpdateRetailerUserRequest): Observable<User> {
    return this.httpClient.patch<User>(`${this.uriBff}/${id}`, body);
  }

  removeUsersFromRetailerBFF(body: RemoveUsersFromRetailerRequest): Observable<void> {
    return this.httpClient.post<void>(`${this.uriBff}/retailer/remove`, body);
  }
}
