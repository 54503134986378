import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IgxDropDownModule, IgxToggleModule } from '@infragistics/igniteui-angular';

import { IconModule } from '../icon';
import { DropdownComponent, DropdownItemComponent, DropdownToggleComponent } from './components';

@NgModule({
  declarations: [DropdownComponent, DropdownItemComponent, DropdownToggleComponent],
  imports: [CommonModule, RouterModule, IgxDropDownModule, IgxToggleModule, IconModule],
  exports: [DropdownComponent, DropdownItemComponent, DropdownToggleComponent],
})
export class DropdownModule {}
