import { Injectable } from '@angular/core';

import { SnackbarOptions, SnackbarTarget } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private snackbarComponent: SnackbarTarget;

  open(message: string, options?: SnackbarOptions): void {
    this.snackbarComponent.open(message, options);
  }

  success(message: string): void {
    this.open(message, { variant: 'success' });
  }

  error(message: string): void {
    this.open(message, { variant: 'error' });
  }

  warn(message: string): void {
    this.open(message, { variant: 'warn' });
  }

  registerComponent(elementRef: SnackbarTarget): void {
    this.snackbarComponent = elementRef;
  }
}
