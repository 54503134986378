import { Observable } from 'rxjs';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { Select, Store } from '@ngxs/store';

import { CommonConfig, extractCommitHash } from '@supy/common';
import { APP_CONFIG } from '@supy/core';

import { SideNavItem } from '../../core';
import { SideNavSelectGroup, SideNavState } from '../../store';

const animDuration = 200;

type MenuState = 'in' | 'out';

@Component({
  selector: 'supy-side-nav',
  templateUrl: 'side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavComponent {
  @Select(SideNavState.topItems)
  protected readonly topItems$: Observable<SideNavItem[]>;

  @Select(SideNavState.bottomItems)
  protected readonly bottomItems$: Observable<SideNavItem[]>;

  protected subMenuState: MenuState = 'in';

  get selectedGroup(): SideNavItem {
    return this.store.selectSnapshot(SideNavState.selectedGroup);
  }

  get reversedMenuState(): MenuState {
    return this.subMenuState === 'out' ? 'in' : 'out';
  }

  protected versionHash: string | undefined;

  private readonly config = inject<CommonConfig>(APP_CONFIG);

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly store: Store,
  ) {
    this.versionHash = extractCommitHash(this.config.versionHash);
  }

  protected onHandle(item: SideNavItem | null, menuState: MenuState): void {
    this.subMenuState = menuState;
    this.store.dispatch(new SideNavSelectGroup(item));
  }

  protected onHandleAsync(item: SideNavItem): void {
    const groupSwitched = item.key !== this.selectedGroup?.key;
    const itemInput = this.subMenuState === 'in' || groupSwitched ? item : null;

    if (groupSwitched) {
      setTimeout(() => {
        this.subMenuState = 'out';
        this.cdr.markForCheck();
      }, animDuration / 1.5);
    }

    this.onHandle(itemInput, this.reversedMenuState);
  }

  protected onSubActionClick(item: SideNavItem): void {
    if (item.quickAction) {
      item.quickAction();
    }

    this.onHandle(null, 'in');
  }
}
