import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IgxTabsModule } from '@infragistics/igniteui-angular';

import { ButtonModule } from '../button';
import { CalendarPickerModule } from '../calendar-picker';
import { ComboboxModule } from '../combo-box';
import { DateInputModule } from '../date-input';
import { DateRangeModule } from '../date-range';
import { IconModule } from '../icon';
import { InputModule } from '../input';
import { SelectModule } from '../select';
import { SwitchModule } from '../switch';
import { TooltipModule } from '../tooltip';
import { DropdownTreeModule } from '../tree-view-dropdown';
import { FilterGroupComponent, FilterTileComponent } from './components';

@NgModule({
  declarations: [FilterGroupComponent, FilterTileComponent],
  imports: [
    CommonModule,
    IgxTabsModule,
    IconModule,
    RouterModule,
    ComboboxModule,
    InputModule,
    SelectModule,
    ButtonModule,
    ReactiveFormsModule,
    DateInputModule,
    DateRangeModule,
    DropdownTreeModule,
    SwitchModule,
    CalendarPickerModule,
    TooltipModule,
  ],
  exports: [FilterGroupComponent, FilterTileComponent],
})
export class FiltersModule {}
