import { ResourceCheck } from '@cerbos/core';

import { ToggleFeature } from '@supy/common';
import { IconType } from '@supy/components';

export enum SideNavItemType {
  Group = 'group',
  RouterLink = 'router-link',
  Action = 'action',
}

export interface SideNavItem {
  readonly key: string;
  readonly permissions?: ResourceCheck[];
  readonly toggleKeys?: ToggleFeature | ToggleFeature[];
  readonly disabledToggleKeys?: ToggleFeature | ToggleFeature[];
  readonly quickActionToggleKeys?: ToggleFeature | ToggleFeature[];
  readonly disabledQuickActionToggleKeys?: ToggleFeature | ToggleFeature[];
  readonly title: string;
  readonly titleAlt?: string;
  readonly icon?: IconType;
  readonly iconActive?: IconType;
  readonly url?: string;
  readonly active?: boolean;
  readonly children?: SideNavItem[];
  readonly position?: 'top' | 'bottom';
  readonly type: SideNavItemType;
  readonly off?: boolean;
  readonly callback?: () => void;
  readonly quickAction?: () => void;
  readonly quickActionUrl?: string;
  readonly quickActionQueryParams?: { [key: string]: string | number | boolean };
  readonly quickActionIcon?: string;
  readonly quickActionTitle?: string;
  readonly quickActionTitleAlt?: string;
  readonly quickActionPermissions?: ResourceCheck[];
  readonly quickActionOff?: boolean;
  readonly underMaintenance?: boolean;
}
