<section class="splash-screen">
  <img class="splash-screen__logo" src="/assets/images/supy-logo-wide.svg" />
  <svg class="splash-screen__loading" viewBox="0 0 100 100">
    <circle cx="50" cy="50" fill="none" stroke-width="6" r="40" stroke-dasharray="188 64"></circle>
  </svg>
  <div class="splash-screen__footer" *ngIf="showAction()">
    <span class="splash-screen__footer-text">If you're not automatically redirected</span>
    <button class="splash-screen__footer-link" (click)="refresh()">refresh this page</button>
  </div>
</section>
