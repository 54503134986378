import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';

import { SentryInterceptor } from './sentry';
import { SnackbarErrorInterceptor } from './snackbar';

export const HTTP_COMMON_INTERCEPTOR_PROVIDERS: Provider[] = [
  { provide: HTTP_INTERCEPTORS, useClass: SentryInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: SnackbarErrorInterceptor, multi: true },
];
