import { LabelsPosition, LegendOrientation } from 'igniteui-angular-charts';
import { InjectionToken, ValueProvider } from '@angular/core';

/**
 * Common Donut Chart options, they might be extended based on use case.
 */
export interface DonutChartOptions {
  /**
   * The height of the donut chart's canvas.
   * @default {75}
   */
  readonly height: number;
  /**
   * The width of the donut chart's canvas.
   * @default {75}
   */
  readonly width: number;
  /**
   * Whether the slices can be selected.
   * @default {false}
   */
  readonly allowSliceSelection: boolean;
  /**
   * The inner extent of the donut chart relative to the outer ring's radius.
   * @default {90}
   */
  readonly innerExtent: number;
  /**
   * The current Legend object's orientation.
   * @default {LegendOrientation.Vertical}
   */
  readonly legendOrientation: LegendOrientation;
  /**
   * The position of chart labels.
   * @default {LabelsPosition.OutsideEnd}
   */
  readonly labelsPosition: LabelsPosition;
  /**
   * The pixel amount by which the labels are offset from the edge of the slices.
   * @default {30}
   */
  readonly labelExtent: number;
  /**
   * The scaling factor of the donut chart's radius. Value between 0 and 1.
   * @default {0.5}
   */
  readonly radiusFactor: number;
  /**
   * The starting angle of the chart.
   * @default {25}
   */
  readonly startAngle: number;
  /**
   * The palette of brushes to use for outlines on the slices.
   * @default {[`#ffffff`]}
   */
  readonly outlines: string[];
  /**
   * The palette of brushes to use for the slices.
   */
  readonly brushes: string[];
  /**
   * THe color of the selected slice.
   * @default {'#000000'}
   */
  selectedSliceFill: string;
}

export const DONUT_CHART_DEFAULT_OPTIONS: DonutChartOptions = {
  height: 75,
  width: 75,
  allowSliceSelection: false,
  innerExtent: 90,
  legendOrientation: LegendOrientation.Vertical,
  labelsPosition: LabelsPosition.OutsideEnd,
  labelExtent: 30,
  radiusFactor: 0.5,
  startAngle: 25,
  outlines: ['#ffffff'],
  brushes: ['#6144BF', '#F1CB30', '#C95040', '#589CD1', '#70C697', '#8E7AD1', '#B0A2DF', '#D2CAED'],
  selectedSliceFill: '#f8cc39',
};

export const DONUT_CHART_OPTIONS = new InjectionToken<DonutChartOptions>('Default config for donut chart', {
  factory: () => DONUT_CHART_DEFAULT_OPTIONS,
});

export const donutChartOptionsProvider: (options: Partial<DonutChartOptions>) => ValueProvider = (
  options: Partial<DonutChartOptions>,
) => ({
  provide: DONUT_CHART_OPTIONS,
  useValue: { ...DONUT_CHART_DEFAULT_OPTIONS, ...options },
});
