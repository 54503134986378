import { BehaviorSubject, distinctUntilChanged, filter, takeUntil } from 'rxjs';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Params, Router } from '@angular/router';

import { Destroyable } from '@supy/common';
import { Span } from '@supy/opentelemetry';

export interface Breadcrumb {
  readonly label: string;
  readonly url?: string;
  readonly params?: Params;
}

@Component({
  selector: 'supy-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent extends Destroyable implements OnInit {
  @Input() readonly aliases?: Record<string, string> = {};

  @Input() set breadcrumbs(value: Breadcrumb[]) {
    if (value) {
      this.#breadcrumbListChanges.next(value);
    }
  }

  @Input() readonly type: 'static' | 'dynamic' | 'semi-static' = 'dynamic';
  @Input() readonly showBackButton: boolean;
  @Input() readonly mergeParams: boolean;
  @Input() readonly skipFirstRoute: boolean;

  readonly #breadcrumbListChanges = new BehaviorSubject<Breadcrumb[]>([]);
  readonly breadcrumbList$ = this.#breadcrumbListChanges.asObservable();

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.type === 'dynamic') {
      this.#breadcrumbListChanges.next(this.buildBreadCrumb(this.route.root));
      this.router.events
        .pipe(
          takeUntil(this.destroyed$),
          filter(event => event instanceof NavigationEnd),
          distinctUntilChanged(),
        )
        .subscribe(() => {
          this.#breadcrumbListChanges.next(this.buildBreadCrumb(this.route.root));
        });
    }

    if (this.type === 'semi-static') {
      const passedInBreadcrumbs = this.#breadcrumbListChanges.getValue();

      this.#breadcrumbListChanges.next(this.buildBreadCrumb(this.route.root).concat(passedInBreadcrumbs));
    }
  }

  @Span()
  async onClickBreadCrumb(breadcrumb: Breadcrumb): Promise<void> {
    if (!breadcrumb.url) {
      return;
    }

    const options: NavigationExtras = { queryParams: breadcrumb.params };

    if (this.mergeParams) {
      options.queryParamsHandling = 'merge';
    }

    await this.router.navigate([breadcrumb.url], options);
  }

  @Span()
  async goBack() {
    const currentBreadcrumbs = this.#breadcrumbListChanges.getValue();

    const urlToGoBack =
      currentBreadcrumbs.length > 1
        ? currentBreadcrumbs
            .slice(0, currentBreadcrumbs.length - 1)
            .reverse()
            .find(br => br.url)?.url
        : currentBreadcrumbs[0].url;

    const options: NavigationExtras = { relativeTo: this.route };

    if (this.mergeParams) {
      options.queryParamsHandling = 'merge';
    }

    await this.router.navigate([urlToGoBack], options);
  }

  /**
   * Recursively build breadcrumb according to activated route.
   * @param route
   * @param url
   * @param breadcrumbs
   */
  private buildBreadCrumb(route: ActivatedRoute, url = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    //If no routeConfig is available we are on the root path
    let label = (route.routeConfig?.data?.breadcrumb as string) ?? '';
    let path = route.routeConfig?.path ?? '';

    // If the route is dynamic route such as ':id', remove it
    const routeParts = path.split('/');
    const isDynamicRoute = routeParts.some(routePart => routePart.startsWith(':'));

    if (isDynamicRoute && route.snapshot) {
      routeParts.forEach(routePart => {
        if (routePart.startsWith(':')) {
          const [, paramName] = routePart.split(':');

          path = path.replace(routePart, route.snapshot.params[paramName] as string);
          label = route.snapshot.params[paramName] as string;
        }
      });
    }

    // skipping display if user has passed in data {breadcrumb: false}
    if (!route.routeConfig?.data?.breadcrumb) {
      label = '';
    }

    //In the routeConfig the complete path is not available,
    //so we rebuild it each time
    if (this.aliases) {
      for (const [key, value] of Object.entries(this.aliases)) {
        label = label.replace(new RegExp(`{{${key}}}`, 'g'), value);
      }
    }

    const nextUrl = path ? `${url}/${path}` : url;

    const breadcrumb: Breadcrumb = {
      label,
      url: nextUrl,
      params: route.snapshot.queryParams,
    };

    // Only adding route with non-empty label
    const newBreadcrumbs = breadcrumb.label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];

    if (route.firstChild) {
      //If we are not on our current path yet,
      //there will be more children to look after, to build our breadcrumb
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }

    return this.skipFirstRoute ? newBreadcrumbs.slice(1) : newBreadcrumbs;
  }

  trackByLabel(_: number, item: Breadcrumb): string {
    return item.label;
  }
}
