import { Observable, of } from 'rxjs';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { IAfterGuiAttachedParams, ICellEditorParams } from '@ag-grid-community/core';
import { Component, TemplateRef, ViewChild } from '@angular/core';

import { AutocompleteComponent } from '../../../autocomplete';
import { IconType } from '../../../icon';

const KEY_LEFT = 'ArrowLeft';
const KEY_UP = 'ArrowUp';
const KEY_RIGHT = 'ArrowRight';
const KEY_DOWN = 'ArrowDown';
const KEY_PAGE_UP = 'PageUp';
const KEY_PAGE_DOWN = 'PageDown';
const KEY_PAGE_HOME = 'Home';
const KEY_PAGE_END = 'End';

export interface AutocompleteCellEditorContext<TData = unknown, TValue = unknown> {
  readonly options: TValue[] | Observable<TValue[]> | ((rowData: TData) => Observable<TValue[]>);
  readonly displayFn?: (option: TValue) => string;
  readonly validatorFn?: (search: string, options: TValue[]) => TValue;
  readonly customOptionsTemplate?: TemplateRef<unknown>;
  readonly noMatchOption?: TemplateRef<unknown>;
  readonly placeholder?: string;
  readonly suffixIcon?: IconType;
  readonly prefixIcon?: IconType | ((rowData: TData) => IconType | null);
  readonly isLoading?: (rowData: TData) => boolean;
  readonly fetchDone?: (rowData: TData) => Observable<boolean>;
  readonly onItemSelected?: (rowData: TData, value: TValue) => void;
  readonly searchValueChange?: (rowData: TData, search: string) => void;
}

@Component({
  selector: 'supy-autocomplete-cell-editor',
  template: `
    <div class="supy-autocomplete-edit__cell">
      <supy-autocomplete
        #focusableComponent
        class="ag-custom-component-popup"
        [placeholder]="context?.placeholder ?? 'Start typing to find an item'"
        [suffix]="context?.suffixIcon ?? 'search'"
        [value]="value"
        (keydown)="onKeyDown($event)"
        [debounceTime]="300"
        [nonTextWidth]="50"
        [prefix]="getPrefixIcon()"
        [options]="ensureObservable(context?.options) | async"
        [customOptionsTemplate]="context?.customOptionsTemplate"
        [noMatchOption]="context?.noMatchOption"
        [validatorFn]="context?.validatorFn"
        [displayValueFn]="context?.displayFn"
        [fetchDone]="context?.fetchDone?.(rowData)"
        [isLoading]="context?.isLoading?.(rowData)"
        (valueChange)="valueChangeHandler($event)"
        (closed)="onCloseDropdown()"
        (searchValueChange)="context?.searchValueChange?.(rowData, $event)"
      ></supy-autocomplete>
    </div>
  `,
  styleUrls: ['./autocomplete-cell-editor.component.scss'],
})
export class AutocompleteCellEditorComponent<
  TData = unknown,
  TValue extends {
    id: string;
  } = {
    id: string;
  },
  TContext extends AutocompleteCellEditorContext<TData, TValue> = AutocompleteCellEditorContext<TData, TValue>,
> implements ICellEditorAngularComp
{
  protected value: TValue;
  protected rowData: TData;
  protected params: ICellEditorParams<TData, TValue, TContext>;
  protected context: TContext;

  @ViewChild('focusableComponent') private readonly focusableComponent: AutocompleteComponent<
    TValue & {
      id: string;
    }
  >;

  agInit(params: ICellEditorParams<TData, TValue, TContext>): void {
    this.params = params;
    this.context = params.context;
    this.rowData = this.getRowData(params);
    this.value = params.value;
  }

  getValue(): TValue {
    return this.value;
  }

  isCancelAfterEnd(): boolean {
    return false;
  }

  isCancelBeforeStart(): boolean {
    return false;
  }

  afterGuiAttached(_?: IAfterGuiAttachedParams): void {
    this.onStartEditing();
  }

  protected valueChangeHandler(value: TValue): void {
    if (value) {
      this.value = value;
    }
  }

  protected onCloseDropdown() {
    if (this.value) {
      this.context?.onItemSelected?.(this.rowData, this.value);
      this.params.stopEditing();
      this.params.api.refreshCells();
    }
  }

  protected onStartEditing(): void {
    if (this.focusableComponent) {
      this.focusableComponent.focus();
    }
  }

  protected ensureObservable<T>(list: T[] | Observable<T[]> | ((rowData: TData) => Observable<T[]>)): Observable<T[]> {
    return !Array.isArray(list) && (list as Observable<T[]>).subscribe !== undefined
      ? (list as Observable<T[]>)
      : Array.isArray(list)
      ? of(list ?? [])
      : (list as (rowData: TData) => Observable<T[]>)(this.rowData);
  }

  protected getPrefixIcon(): IconType {
    return typeof this.context.prefixIcon === 'string'
      ? this.context.prefixIcon
      : this.context.prefixIcon?.(this.rowData);
  }

  protected onKeyDown(event: KeyboardEvent): void {
    const key = event.key;

    const isNavigationKey =
      key === KEY_LEFT ||
      key === KEY_RIGHT ||
      key === KEY_UP ||
      key === KEY_DOWN ||
      key === KEY_PAGE_DOWN ||
      key === KEY_PAGE_UP ||
      key === KEY_PAGE_HOME ||
      key === KEY_PAGE_END;

    if (isNavigationKey) {
      // this stops the grid from receiving the event and executing keyboard navigation
      event.stopPropagation();
    }
  }

  private getRowData(params: ICellEditorParams<TData, TValue, TContext>): TData {
    return params.data;
  }
}
