import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  CellType,
  DefaultDataCloneStrategy,
  FilteringStrategy,
  FilterMode,
  IColumnMovingEndEventArgs,
  IDataCloneStrategy,
  IDropDroppedEventArgs,
  IFilteringEventArgs,
  IFilteringExpressionsTree,
  IFilteringStrategy,
  IGridCellEventArgs,
  IGridEditDoneEventArgs,
  IGridEditEventArgs,
  IGridSortingStrategy,
  IgxGridComponent,
  IgxStringFilteringOperand,
  IgxTreeGridComponent,
  IRowDataEventArgs,
  IRowDragEndEventArgs,
  ISortingEventArgs,
  RowType,
} from '@infragistics/igniteui-angular';
import { IgxGridCRUDService } from '@infragistics/igniteui-angular/lib/grids/common/crud.service';
import { IgxRowDirective } from '@infragistics/igniteui-angular/lib/grids/row.directive';

import { Destroyable } from '@supy/common';

import { GridColumnComponent } from '../grid-column';

@Component({
  selector: 'supy-tree-grid',
  templateUrl: './tree-grid.component.html',
  styleUrls: ['./tree-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeGridComponent<T = unknown> extends Destroyable {
  @Input() readonly data: T[];
  @Input() readonly height: string | null = '100%';
  @Input() readonly width: string | null = '100%';
  @Input() readonly childDataKey: string;
  @Input() readonly autoGenerate: boolean = false;
  @Input() readonly allowFiltering: boolean = false;
  @Input() readonly filterStrategy: IFilteringStrategy = new FilteringStrategy();
  @Input() readonly sortStrategy: IGridSortingStrategy;
  @Input() readonly filterMode: FilterMode = FilterMode.quickFilter;
  @Input() readonly primaryKey: string;
  @Input() readonly foreignKey: string;
  @Input() readonly rowHeight: number = 54;
  @Input() readonly rowEditable: boolean;
  @Input() readonly rowClasses: IgxGridComponent['rowClasses'];
  @Input() readonly emptyGridTemplate: TemplateRef<void>;
  @Input() readonly editableActions: boolean;
  @Input() readonly rowDraggable: boolean;
  @Input() readonly dataCloneStrategy: IDataCloneStrategy = new DefaultDataCloneStrategy();
  @Input() readonly showAddRowEmptyButton: boolean = true;
  @Input() readonly showAddAction: boolean;
  @Input() readonly showEditAction: boolean;
  @Input() readonly showNestedEditAction: boolean = true;
  @Input() readonly showDeleteAction: boolean;
  @Input() readonly showNestedDeleteAction: boolean;
  @Input() readonly lazyDeletion: boolean;
  @Input() readonly lazyAdd: boolean;
  @Input() readonly addRowLevel: number = 1;
  @Input()
  readonly clipboardOptions: IgxGridComponent['clipboardOptions'] = {
    enabled: false,
    copyHeaders: false,
    separator: '\t',
    copyFormatters: false,
  };

  @Input() readonly addedInFooter: boolean;
  @Input() readonly addedInFooterTitle: string;
  @Input() readonly expansionDepth: number = Infinity;

  @Output() readonly cellClick: EventEmitter<IGridCellEventArgs> = new EventEmitter<IGridCellEventArgs>();
  @Output() readonly selected: EventEmitter<IGridCellEventArgs> = new EventEmitter<IGridCellEventArgs>();
  @Output() readonly cellEdit: EventEmitter<IGridEditEventArgs> = new EventEmitter<IGridEditEventArgs>();
  @Output() readonly cellEditEnter: EventEmitter<IGridEditEventArgs> = new EventEmitter<IGridEditEventArgs>();
  @Output() readonly cellEditDone = new EventEmitter<IGridEditDoneEventArgs>();
  @Output() readonly rowEdit: EventEmitter<IGridEditEventArgs> = new EventEmitter<IGridEditEventArgs>();
  @Output() readonly rowEditDone: EventEmitter<IGridEditDoneEventArgs> = new EventEmitter<IGridEditDoneEventArgs>();
  @Output() readonly cellEditExit = new EventEmitter<IGridEditDoneEventArgs>();
  @Output() readonly sorting: EventEmitter<ISortingEventArgs> = new EventEmitter<ISortingEventArgs>();
  @Output() readonly filtering: EventEmitter<IFilteringEventArgs> = new EventEmitter<IFilteringEventArgs>();
  @Output() readonly rowExpanded: EventEmitter<T> = new EventEmitter<T>();
  @Output() readonly rowDragEnd = new EventEmitter<IRowDragEndEventArgs>();
  @Output() readonly rowDeleted = new EventEmitter<IRowDataEventArgs>();
  @Output() readonly rowLazyAdded = new EventEmitter<RowType>();
  @Output() readonly rowLazyDeleted = new EventEmitter<RowType>();
  @Output() readonly rowAdding = new EventEmitter<void>();
  @Output() readonly rowAdded = new EventEmitter<IRowDataEventArgs>();

  @Output()
  readonly columnMovingEnd: EventEmitter<IColumnMovingEndEventArgs> = new EventEmitter<IColumnMovingEndEventArgs>();

  @Output()
  readonly filteringDone: EventEmitter<IFilteringExpressionsTree> = new EventEmitter<IFilteringExpressionsTree>();

  @Output()
  readonly filteringExpressionsTreeChange: EventEmitter<IFilteringExpressionsTree> =
    new EventEmitter<IFilteringExpressionsTree>();

  @ViewChild(IgxTreeGridComponent, { static: true }) private readonly igxTreeGrid: IgxTreeGridComponent;
  @ContentChildren(GridColumnComponent) readonly columns: QueryList<GridColumnComponent>;

  get nativeElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  constructor(
    protected readonly cdr: ChangeDetectorRef,
    private readonly elementRef: ElementRef<HTMLElement>,
  ) {
    super();
  }

  getCellByColumn(rowIndex: number, columnField: string): CellType {
    return this.igxTreeGrid.getCellByColumn(rowIndex, columnField);
  }

  endEdit(): void {
    this.igxTreeGrid.endEdit();
  }

  inEditMode(rowContext: RowType): boolean {
    return rowContext && (rowContext.inEditMode || rowContext.cells.some(cell => cell.editMode));
  }

  startAddRow(rowContext: RowType): void {
    if (this.lazyAdd) {
      this.rowLazyAdded.emit(rowContext);
    } else {
      rowContext.beginAddRow();
    }
  }

  startEditRow(rowContext: RowType): void {
    const firstEditable = rowContext.cells.filter(cell => cell.editable)[0];
    const grid = rowContext.grid;

    if ((grid.rowList as RowType[]).filter(row => row === rowContext).length !== 0) {
      (grid.gridAPI.crudService as IgxGridCRUDService).enterEditMode(firstEditable);
      firstEditable.activate(null);
    }
  }

  deleteRow(rowContext: RowType): void {
    if (this.lazyDeletion) {
      this.rowLazyDeleted.emit(rowContext);
    } else {
      rowContext.delete();
    }
  }

  filterColumnByString(term: string, key: string, ignoreCase = true): void {
    this.igxTreeGrid.filter(key, term, IgxStringFilteringOperand.instance().condition('contains'), ignoreCase);
  }

  getRowByKey(key: unknown): RowType {
    return this.igxTreeGrid.getRowByKey(key);
  }

  markForCheck(): void {
    this.igxTreeGrid.markForCheck();
  }

  commitTransactions(data: T[]): void {
    this.igxTreeGrid.transactions.commit(data);
  }

  trackColumnChanges(index: number, col: GridColumnComponent): string {
    return col.field + col.header;
  }

  onRowAdd(): void {
    this.rowAdding.emit();
  }

  onRowAdded($event: IRowDataEventArgs): void {
    this.rowAdded.emit($event);
  }

  onRowDeleted($event: IRowDataEventArgs): void {
    this.rowDeleted.emit($event);
  }

  onRowDragEnd($event: IRowDragEndEventArgs): void {
    this.rowDragEnd.emit($event);
  }

  onDropAllowed(args: IDropDroppedEventArgs): void {
    const event = args.originalEvent as { clientX: number; clientY: number };
    const currRowIndex = this.getCurrentRowIndex(this.igxTreeGrid.rowList.toArray(), {
      x: event.clientX,
      y: event.clientY,
    });

    if (currRowIndex === -1) {
      return;
    }

    this.igxTreeGrid.deleteRow((args.dragData as { key: string }).key);
    this.data.splice(currRowIndex, 0, (args.dragData as { data: T }).data);
  }

  private getCurrentRowIndex(rowList: IgxRowDirective[], cursorPosition: { x: number; y: number }): number {
    for (const row of rowList) {
      const rowRect = row.nativeElement.getBoundingClientRect();

      if (
        cursorPosition.y > rowRect.top + window.scrollY &&
        cursorPosition.y < rowRect.bottom + window.scrollY &&
        cursorPosition.x > rowRect.left + window.scrollX &&
        cursorPosition.x < rowRect.right + window.scrollX
      ) {
        // TODO: Fails here
        return this.data.findIndex(r => r[this.primaryKey] === row.key);
      }
    }

    return -1;
  }
}
