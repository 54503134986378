<igx-input-group
  [displayDensity]="displayDensity"
  [class.supy-input-line]="inputType === 'line'"
  class="supy-textarea"
  theme="material"
  [type]="inputType"
>
  <span igxPrefix *ngIf="prefix">
    <supy-icon [name]="prefix" [size]="displayDensity !== 'comfortable' ? 'small' : undefined"></supy-icon>
  </span>

  <textarea
    igxInput
    #textArea
    [id]="id"
    [name]="name"
    [disabled]="disabled"
    [readOnly]="readOnly"
    [placeholder]="placeholder"
    [autocomplete]="autocomplete || ''"
    [value]="value"
    [rows]="rows"
    [cols]="cols"
    (keypress)="onKeyPress($event)"
    (input)="onValueChange($event)"
    (focusout)="onFocusOut($event)"
    [class.supy-input__input--clearable]="clearable && value"
  ></textarea>

  <igx-suffix
    *ngIf="!disabled && !readOnly"
    [style.visibility]="value && clearable ? 'visible' : 'hidden'"
    class="clear-button"
  >
    <supy-icon name="close-circle" color="error" size="small" (click)="clearInput($event)"></supy-icon>
  </igx-suffix>

  <span class="supy-textarea__suffix" igxSuffix *ngIf="suffix">
    <supy-icon [name]="suffix" size="small"></supy-icon>
  </span>

  <igx-hint class="supy-input__hint-placeholder">
    <supy-input-hint-container>
      <ng-content select="supy-input-error"></ng-content>
      <ng-content select="supy-input-hint"></ng-content>
    </supy-input-hint-container>
  </igx-hint>
</igx-input-group>
