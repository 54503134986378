<div class="supy-star-rating">
  <supy-icon
    *ngFor="let star of [].constructor(stars); let i = index"
    name="star"
    class="cursor--pointer"
    [class.supy-star-rating--disabled]="disabled"
    [color]="i + 1 <= starsToFill ? 'warn' : 'secondary'"
    (click)="onClick(i + 1)"
    (mouseenter)="onMouseEnter(i + 1)"
    (mouseleave)="onMouseLeave()"
  ></supy-icon>
</div>
