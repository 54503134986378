import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export interface AvatarStackItem {
  readonly firstName: string;
  readonly lastName: string;
  readonly src?: string;
}

interface AvatarStackItemExtended extends AvatarStackItem {
  readonly initials: string;
}

type AvatarSize = 'xsmall' | 'small' | 'medium' | 'large' | 'tiny';

@Component({
  selector: 'supy-avatar-stack',
  templateUrl: './avatar-stack.component.html',
  styleUrls: ['./avatar-stack.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarStackComponent {
  @Input() set stack(value: AvatarStackItem[]) {
    this._stack = this.buildStack(value);
  }

  get stack(): AvatarStackItemExtended[] {
    return this._stack;
  }

  @Input() readonly size: AvatarSize;
  @Input() readonly visibleCount: number;

  private _stack: AvatarStackItemExtended[];

  private buildStack(items: AvatarStackItem[]): AvatarStackItemExtended[] {
    return items.map(item => ({ ...item, initials: `${item.firstName?.charAt(0)}${item.lastName?.charAt(0)}` }));
  }

  onClick(e: MouseEvent): void {
    e.stopPropagation();
  }
}
