import {
  CheckResourceRequest,
  CheckResourcesRequest,
  CheckResourcesResponse,
  CheckResourcesResult,
  IsAllowedRequest,
  Principal,
  Resource,
} from '@cerbos/core';
import { Store } from '@ngxs/store';

export {
  CheckResourceRequest,
  CheckResourcesRequest,
  CheckResourcesResponse,
  CheckResourcesResult,
  IsAllowedRequest,
  Principal,
  Resource,
};

export const AUTHZ_DEFAULT_ID = 'new';

export interface GetIsAllowedRequestOptions {
  readonly store: Store;
  readonly kind: string;
  readonly action: string;
  readonly principal?: Principal;
  readonly resource?: Resource;
}
