<div class="supy-image-uploader">
  <input
    type="file"
    [disabled]="isReadonly"
    [accept]="acceptedFormats"
    #fileInput
    (change)="onFileChange($event)"
    hidden
  />
  <div *ngIf="!imageUrl" (click)="fileInput.click()" class="supy-image-uploader__main-container">
    <span class="supy-image-uploader__image-circle">
      <supy-icon name="camera" icon="camera"></supy-icon>
    </span>
    <div class="supy-image-uploader__content">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="supy-image-uploader__main-container" *ngIf="imageUrl">
    <div class="supy-image-uploader__uploaded-logo">
      <supy-avatar [src]="imageUrl" size="large"></supy-avatar>
      <supy-button color="secondary" density="medium" variant="link" (buttonClick)="onRemoveImage()">
        Remove
      </supy-button>
    </div>
  </div>
  <igx-input-group class="supy-image-uploader__hints">
    <input type="hidden" igxInput />
    <igx-hint class="supy-image-uploader__hint-placeholder">
      <supy-input-hint-container class="supy-image-uploader__hint-container">
        <ng-content select="supy-input-error"></ng-content>
        <ng-content select="supy-input-hint"></ng-content>
      </supy-input-hint-container>
    </igx-hint>
  </igx-input-group>
  <div class="supy-image-uploader__cropper-container" *ngIf="cropperImageEvent && useCropper">
    <div class="supy-image-uploader__cropper-widget">
      <supy-image-cropper
        [imageChangedEvent]="cropperImageEvent"
        (imageCropped)="onImageCropped($event)"
        (imageLoaded)="onImageLoaded()"
        (imageInvalid)="onImageInvalid()"
      ></supy-image-cropper>
    </div>
    <div class="supy-image-uploader__cropper-actions" *ngIf="cropperImageLoaded">
      <supy-button color="secondary" (buttonClick)="onRemoveImage()">Remove</supy-button>
      <supy-button color="secondary" variant="outlined" (buttonClick)="onSaveImage()">Apply</supy-button>
    </div>
  </div>
</div>
