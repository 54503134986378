import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { LocalFile } from '@supy/common';

import { IconSize } from '../../../icon';
import { RemoveFileEvent } from '../../core';

export type MultipleMediaUploaderDimension = `${number}rem`;
@Component({
  selector: 'supy-multiple-media-uploader',
  templateUrl: './multiple-media-uploader.component.html',
  styleUrls: ['./multiple-media-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleMediaUploaderComponent),
      multi: true,
    },
  ],
})
export class MultipleMediaUploaderComponent<T> implements ControlValueAccessor {
  @ViewChild('fileInput') readonly input: ElementRef<HTMLInputElement>;

  @Input() readonly localUpload: boolean;
  @Input() readonly disabled: boolean = false;
  @Input() readonly actionText: string = 'Upload Media';
  @Input() readonly isLoading: boolean;
  @Input() readonly dimensions: MultipleMediaUploaderDimension = '4rem';
  @Input() readonly iconSize: IconSize = 'medium';
  @Input() readonly hideFirstAttachmentRemoval: boolean;
  @Input() readonly manualClickHandling: boolean;
  @Output() readonly fileUploaded: EventEmitter<LocalFile[]> = new EventEmitter<LocalFile[]>();
  @Output() readonly fileRemoved: EventEmitter<RemoveFileEvent> = new EventEmitter<RemoveFileEvent>();

  readonly acceptedFormats = ['image/png', 'image/jpeg', 'image/bmp', 'image/ico', 'image/webp', 'application/pdf'];

  touched = false;

  onChange: (value: LocalFile[]) => void;
  onTouched: () => void;

  #value: T[];

  readonly #fileUrls = signal<string[]>([]);
  readonly #localFileUrls = signal<string[]>([]);
  protected readonly allFileUrls = computed(() => [...this.#fileUrls(), ...this.#localFileUrls()]);

  @Input() set fileUrls(value: string[]) {
    this.#fileUrls.set(value ?? []);
  }

  onInputClick(): void {
    if (!this.disabled && !this.isLoading) {
      this.input.nativeElement.click();
    }
  }

  onRemoveFile(removeFile: RemoveFileEvent): void {
    if (!this.disabled) {
      this.#fileUrls.set(this.#fileUrls().filter(url => url !== removeFile.fileUrl));
      this.#localFileUrls.set(this.#localFileUrls().filter(url => url !== removeFile.fileUrl));
      this.input.nativeElement.value = '';
      this.fileRemoved.emit(removeFile);
    }
  }

  onFileChange(event: Event): void {
    const files = (event.target as HTMLInputElement).files;

    const uploadedFiles: LocalFile[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const format = file.type;
      const isValid = this.acceptedFormats.includes(format);

      if (isValid) {
        const formattedBlob = file.slice(0, file.size, file.type);
        const objectUrl = URL.createObjectURL(formattedBlob);

        if (this.localUpload) {
          this.#localFileUrls.set([...this.#localFileUrls(), objectUrl]);
        }

        uploadedFiles.push({ file, objectUrl });
      }
    }
    this.fileUploaded.emit(uploadedFiles);
  }

  writeValue(value: T[]): void {
    this.#value = value;
  }

  registerOnChange(onChange: (value: LocalFile[]) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.touched = true;
      this.onTouched?.();
    }
  }
}
