import { Language } from '@supy.api/dictionaries';

import { LocalizedData } from '@supy/common';

export function getLocalizedName(name?: LocalizedData): string {
  const htmlTag = globalThis.document?.querySelector('html');

  if (htmlTag) {
    const userLanguage = htmlTag.dataset.userLanguage;
    const alternateLanguage = htmlTag.dataset.alternateLanguage;

    return alternateLanguage &&
      userLanguage &&
      alternateLanguage === userLanguage &&
      alternateLanguage !== Language.English
      ? name?.ar ?? name?.en ?? ''
      : name?.en ?? '';
  }

  return name?.en ?? '';
}
