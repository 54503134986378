import { PredefinedRange, PredefinedRangeBy } from '../../../date-range';
import { CalendarPickerPredefinedDate, CalendarPickerPredefinedDateOption } from './calendar-picker.component';

export function getDefaultCalendarPickerPredefinedRanges(): CalendarPickerPredefinedDate[] {
  return [
    {
      option: CalendarPickerPredefinedDateOption.LastTwoSpecialDates,
      customLabel: 'Last 2 highlighted dates',
    },
    {
      option: CalendarPickerPredefinedDateOption.AllSpecialDates,
      customLabel: 'All highlighted dates',
    },
  ];
}

export function getDefaultCalendarPickerNormalPredefinedRanges(): PredefinedRange[] {
  return [
    { range: 1, previousRange: true, by: PredefinedRangeBy.Weeks },
    { range: new Date().getDate(), by: PredefinedRangeBy.Days, customLabel: 'This Month' },
    { range: 1, previousRange: true, by: PredefinedRangeBy.Months, customLabel: 'Last Month' },
    { range: 1, by: PredefinedRangeBy.Quarter, customLabel: 'This Quarter' },
    { range: 1, previousRange: true, by: PredefinedRangeBy.Quarter, customLabel: 'Last Quarter' },
  ];
}
