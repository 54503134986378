<div class="supy-input-container">
  <igx-input-group
    [displayDensity]="displayDensity"
    [class.supy-input-line]="inputType === 'line'"
    [class.supy-input-success]="suffixColor === 'success'"
    [class.supy-input-warn]="suffixColor === 'warn'"
    [class.supy-input-addon]="addonText?.length"
    class="supy-input"
    theme="material"
    [type]="inputType"
  >
    <ng-container
      *ngIf="prefixTemplate?.template"
      [ngTemplateOutlet]="prefixTemplate.template"
      igxPrefix
    ></ng-container>
    <ng-container
      *ngIf="suffixTemplate?.template"
      [ngTemplateOutlet]="suffixTemplate.template"
      igxSuffix
    ></ng-container>

    <span igxPrefix *ngIf="shownPrefix">
      <supy-icon [name]="shownPrefix" [size]="displayDensity !== 'comfortable' ? 'small' : undefined"></supy-icon>
    </span>

    <span igxSuffix *ngIf="shownSuffix && !isLoading && (!clearable || !value)">
      <supy-icon
        [name]="shownSuffix"
        [size]="displayDensity !== 'comfortable' ? 'small' : undefined"
        [color]="suffixColor"
      ></supy-icon>
    </span>

    <input
      igxInput
      [igxTextSelection]="textSelection"
      [supyNumeric]="numeric"
      [precision]="precision"
      [id]="id"
      [name]="name"
      [type]="type"
      [disabled]="disabled"
      [placeholder]="placeholder ?? ''"
      [autocomplete]="autocomplete ?? ''"
      [value]="value"
      [required]="required"
      (input)="onValueChange($event)"
      (focusout)="onFocusOut($event)"
      (focusin)="onFocusIn($event)"
      (keydown)="handleKeydown($event)"
      (keypress)="onInputKeypress($event)"
      [min]="min ?? ''"
      [max]="max ?? ''"
      [step]="step ?? ''"
      [readOnly]="readonly"
      [class]="{
        'supy-input__input--link': type === 'link',
        'supy-input__input--text-center': centerText,
        'supy-input__input--no-shadow': noShadow,
        'supy-input__input--readonly': readonly,
        'supy-input__input--invalid': true
      }"
    />
    <span igxSuffix *ngIf="clearable && value && !isLoading">
      <supy-icon
        (click)="clearInput()"
        name="close-circle"
        color="error"
        [size]="displayDensity !== 'comfortable' ? 'small' : undefined"
      ></supy-icon>
    </span>

    <span igxSuffix *ngIf="isLoading">
      <supy-loader color="primary"></supy-loader>
    </span>

    <igx-hint class="supy-input__hint-placeholder">
      <supy-input-hint-container>
        <ng-content select="supy-input-error"></ng-content>
        <ng-content select="supy-input-hint"></ng-content>
      </supy-input-hint-container>
    </igx-hint>
  </igx-input-group>

  <div
    *ngIf="addonText"
    class="supy-input__addon"
    [ngClass]="{
      'supy-input__addon--success': suffixColor === 'success',
      'supy-input__addon--warn': suffixColor === 'warn',
      'supy-input__addon--disabled': disabled
    }"
  >
    {{ addonText }}
  </div>
</div>
