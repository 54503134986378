import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

export const DEFAULT_PRECISION = 2;
export const DEFAULT_QUANTITY_PRECISION = 3;

@Directive({
  selector: '[supyNumeric]',
})
export class NumericDirective implements OnInit {
  @Input('supyNumeric') enableNumeric: boolean;

  @Input() set precision(value: number) {
    this.#precision = value ?? DEFAULT_PRECISION;
    this.#regex = new RegExp(`^-?\\d*(\\.\\d{0,${this.#precision}})?$`, 'g');
  }

  #precision: number;
  #regex: RegExp;

  readonly #specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
    'Del',
    'Delete',
  ];

  constructor(private readonly el: ElementRef<HTMLInputElement>) {}

  ngOnInit(): void {
    this.#regex = new RegExp(`^-?\\d*(\\.\\d{0,${this.#precision}})?$`, 'g');
  }

  @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
    if (this.enableNumeric) {
      if (
        this.#specialKeys.indexOf(e.key) !== -1 ||
        (e.key === 'a' && e.ctrlKey === true) || // Allow: Ctrl+A
        (e.key === 'c' && e.ctrlKey === true) || // Allow: Ctrl+C
        (e.key === 'v' && e.ctrlKey === true) || // Allow: Ctrl+V
        (e.key === 'x' && e.ctrlKey === true) || // Allow: Ctrl+X
        (e.key === 'a' && e.metaKey === true) || // Cmd+A (Mac)
        (e.key === 'c' && e.metaKey === true) || // Cmd+C (Mac)
        (e.key === 'v' && e.metaKey === true) || // Cmd+V (Mac)
        (e.key === 'x' && e.metaKey === true) || // Cmd+X (Mac)
        e.key === 'Enter' ||
        e.key === 'Escape'
      ) {
        return;
      }

      const current: string = this.el.nativeElement.value;
      const startPosition = this.el.nativeElement.selectionStart;
      const endPosition = this.el.nativeElement.selectionEnd;

      const next: string = [
        current.slice(0, startPosition),
        e.key == 'Decimal' ? '.' : e.key,
        current.slice(endPosition),
      ].join('');

      if (next && !String(next).match(this.#regex)) {
        e.preventDefault();
      }
    }
  }

  @HostListener('paste', ['$event']) onPaste(e: ClipboardEvent) {
    if (this.enableNumeric) {
      const clipboardData = e.clipboardData.getData('text/plain');

      if (clipboardData && !String(clipboardData).match(this.#regex)) {
        e.preventDefault();
      }
    }
  }
}
