import { date, minDate, time12h, time24h } from './date-time';
import { email, supyEmail } from './email';
import { sheetLink } from './google-sheet';
import { name } from './name';
import { phone } from './phone';
import { minMax } from './range';

export class InputValidators {
  static readonly supyEmail = supyEmail;
  static readonly email = email();
  static readonly phone = phone();
  static readonly date = date();
  static readonly names = name();
  static readonly time12h = time12h();
  static readonly time24h = time24h();
  static readonly googleSheetLink = sheetLink();
  static readonly minMax = minMax();
  static readonly minDate = (date: Date) => minDate(date);
}
