import { SpanKind } from '@opentelemetry/api';
import type { Span } from '@opentelemetry/sdk-trace-base';
import { SentrySpanProcessor } from '@sentry/opentelemetry';

export class SentryApiErrorSpanProcessor extends SentrySpanProcessor {
  protected override _shouldSendSpanToSentry(span: Span) {
    const { attributes, kind } = span;
    const statusCode = attributes['http.status_code'] as number;

    if (kind !== SpanKind.CLIENT || statusCode < 400) {
      return false;
    }

    return super._shouldSendSpanToSentry(span);
  }
}
