import { IgxItemLegendComponent, IgxRingSeriesComponent, IgxSliceClickEventArgs } from 'igniteui-angular-charts';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { DONUT_CHART_OPTIONS, DonutChartOptions } from './donut-chart.options';

export interface DonutChartEntry {
  readonly value: number;
  /**
   * Label being displayed on the legend.
   */
  readonly category: string;
  /**
   * Label being displayed on the donut chart.
   */
  readonly summary: string;
}

@Component({
  selector: 'supy-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DonutChartComponent implements AfterViewInit {
  @ViewChild('series', { static: true }) series: IgxRingSeriesComponent;
  @ViewChild('legend', { static: true }) legend: IgxItemLegendComponent;

  @Output() readonly sliceClicked = new EventEmitter<number>();

  @Input() readonly data: DonutChartEntry[] = [];
  @Input() readonly orientation = this.options.legendOrientation;
  @Input() readonly height = this.options.height;
  @Input() readonly width = this.options.width;
  @Input() readonly innerExtent = this.options.innerExtent;
  @Input() readonly brushes = this.options.brushes;
  @Input() readonly labelExtent = this.options.labelExtent;
  @Input() readonly labelsPosition = this.options.labelsPosition;
  @Input() readonly outlines = this.options.outlines;
  @Input() readonly radiusFactor = this.options.radiusFactor;
  @Input() readonly startAngle = this.options.startAngle;
  @Input() readonly allowSliceSelection = this.options.allowSliceSelection;
  @Input() readonly selectedSliceFill = this.options.selectedSliceFill;
  @Input() set withLegend(value: boolean) {
    if (value) {
      this.series.legend = this.legend;
    }
  }

  private originalBrushes: string[] = [];

  constructor(@Inject(DONUT_CHART_OPTIONS) readonly options: DonutChartOptions) {}

  ngAfterViewInit(): void {
    this.series.othersCategoryThreshold = 0;

    this.originalBrushes = this.series.brushes;
  }

  onSliceClick({ args }: { args: IgxSliceClickEventArgs }): void {
    if (args.isSelected) {
      const selectionBrushes = this.originalBrushes.slice();

      selectionBrushes.splice(args.index, 1, 'gray');
      this.series.brushes = selectionBrushes;
      this.sliceClicked.emit(args.index);
    } else {
      this.series.brushes = this.originalBrushes;
      this.sliceClicked.emit(null);
    }
  }
}
