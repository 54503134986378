import { timer } from 'rxjs';
import { ChangeDetectionStrategy, Component, OnInit, signal } from '@angular/core';

@Component({
  selector: 'supy-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplashScreenComponent implements OnInit {
  protected readonly showAction = signal<boolean>(false);

  private readonly timeInMinutes = 1000 * 60;

  ngOnInit(): void {
    timer(this.timeInMinutes).subscribe(() => {
      this.showAction.set(true);
    });
  }

  protected refresh() {
    window.location.replace('/');
  }
}
