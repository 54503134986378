import { Observable, takeUntil } from 'rxjs';
import { Directive, ElementRef, Input, OnInit } from '@angular/core';

import { Destroyable } from '../../lifecycle';

@Directive({
  selector: '[supyScrollTop]',
})
export class ScrollTopDirective<T> extends Destroyable implements OnInit {
  @Input() readonly supyScrollTop: { selector: string; trigger: Observable<T> };

  constructor(private readonly el: ElementRef<HTMLElement>) {
    super();
  }

  ngOnInit() {
    this.supyScrollTop.trigger.pipe(takeUntil(this.destroyed$)).subscribe(() => this.scrollTop());
  }

  private scrollTop(): void {
    const scrollEl = this.el.nativeElement.querySelector(this.supyScrollTop.selector);

    if (scrollEl) {
      scrollEl.scrollTop = 0;
    }
  }
}
