export class Role {
  readonly id: string;
  readonly name: UserRole;
  readonly identifier?: string;
}

export enum UserRole {
  Admin = 'admin',
  Manager = 'manager',
  Staff = 'staff',
  Drafter = 'drafter',
  // TODO: remove this role when BE is ready
  StaffWithNoItemManagement = 'staff with no item management',
  StaffWithNoPortalAccess = 'staff with no portal access',
  Accounting = 'accounting',
  DrafterWithItemManagement = 'drafter with item management',
  DrafterWithTransferManagement = 'drafter with transfer management',
  Superadmin = 'superadmin',
}

export const RESTRICTED_ROLES: Set<UserRole> = new Set([UserRole.Admin, UserRole.Superadmin]);
export const MANAGER_ROLES: Set<UserRole> = new Set([...RESTRICTED_ROLES, UserRole.Manager]);
