<div class="supy-dropdown-tree">
  <div class="supy-dropdown-tree__overlay-wrapper" *ngIf="overlayDisplayed()">
    <div
      #dropdownOverlay
      class="supy-dropdown-tree__overlay"
      @swingInOut
      [ngStyle]="{
        'top.px': textAreaBoundingRect?.top,
        'left.px': textAreaBoundingRect?.left,
        width: !autoWidth ? textAreaBoundingRect?.width + 'px' : 'auto'
      }"
    >
      <supy-input
        class="supy-dropdown-tree__search-input"
        name="dropdown-tree-search"
        autocomplete="off"
        [density]="density"
        prefix="search"
        placeholder="Search..."
        type="text"
        [focusOnInit]="true"
        (valueChange)="onSearch($event)"
      ></supy-input>
      <div class="supy-dropdown-tree__overlay-content">
        <ng-container *ngIf="data?.length; else emptyTmpl">
          <div *ngIf="selection === 'multiple'" class="supy-dropdown-tree__list-item">
            <supy-checkbox
              class="supy-dropdown-tree__list-item-checkbox"
              [style.padding-inline-start.rem]="0.5"
              [checked]="allSelected"
              name="check-tree-item"
              (checkedChange)="onToggleAll($event)"
            >
              <div class="supy-dropdown-tree__value-title" i18n="@@common.actions.selectAll">Select All</div>
            </supy-checkbox>
          </div>

          <ng-container *ngTemplateOutlet="recursiveListTmpl; context: { list: data, level: 0 }"></ng-container>

          <ng-template #recursiveListTmpl let-list="list" let-level="level">
            <ul class="supy-dropdown-tree__list">
              <li class="supy-dropdown-tree__list-item-wrapper" *ngFor="let item of list">
                <div
                  [id]="nodeIdPrefix + ':' + item.id"
                  class="supy-dropdown-tree__list-item"
                  [ngClass]="{
                    selected: item.id === treeNodeValue?.id && selection === 'single',
                    suggested: item.id === suggestedNode?.id
                  }"
                  [style.padding-inline-start.rem]="level + 0.5"
                  (click)="selection === 'single' && (item.unselectable ? onExpand($event, item) : onSelect(item))"
                >
                  <supy-checkbox
                    *ngIf="selection === 'multiple'"
                    class="supy-dropdown-tree__list-item-checkbox"
                    [checked]="multipleSelectionStrategy === 'node' ? item.selected : item.childrenSelected === 'all'"
                    [indeterminate]="multipleSelectionStrategy === 'children' && item.childrenSelected === 'some'"
                    name="check-tree-item"
                    (checkedChange)="onSelectMultiple($event, item)"
                  >
                    <div class="supy-dropdown-tree__value-title">{{ item.name }}</div>
                  </supy-checkbox>
                  <div *ngIf="selection !== 'multiple'" class="supy-dropdown-tree__value-title">{{ item.name }}</div>
                  <supy-button
                    color="default"
                    density="medium"
                    variant="icon"
                    class="supy-dropdown-tree__expand-btn"
                    *ngIf="item.children?.length > 0"
                    (buttonClick)="onExpand($event, item)"
                  >
                    <supy-icon [name]="item.expanded ? 'arrow-up-2' : 'arrow-down-1'" size="small"></supy-icon>
                  </supy-button>
                </div>
                <div class="supy-dropdown-tree__list-children" *ngIf="item.children?.length > 0 && item.expanded">
                  <ng-container
                    *ngTemplateOutlet="recursiveListTmpl; context: { list: item.children, level: level + 1 }"
                  ></ng-container>
                </div>
              </li>
            </ul>
          </ng-template>
        </ng-container>

        <ng-template #emptyTmpl>
          <div class="supy-dropdown-tree__empty">
            <supy-empty iconSize="huge"></supy-empty>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <supy-textarea
    [name]="name"
    density="small"
    [placeholder]="placeholderValue"
    [ngClass]="{
      'strategy-value': displayStrategy === 'value',
      'selection-multiple': selection === 'multiple',
      selected: !!textAreaValue
    }"
    [class]="variant"
    [value]="textAreaValue"
    [title]="textAreaValue"
    [rows]="1"
    [disabled]="disabled"
    [readOnly]="readOnly"
    [suffix]="disabled ? null : 'arrow-circle-down'"
    autocomplete="off"
    [clearable]="!disabled && !readOnly && clearable"
    (click)="!disabled && !readOnly && open()"
    (cleared)="onClear($event)"
  >
  </supy-textarea>
  <div [style.visiblity]="treeNodeValue ? 'hidden' : 'visible'" class="supy-dropdown-tree__input-error">
    <ng-content select="supy-input-error"></ng-content>
  </div>
</div>
